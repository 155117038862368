import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

// profile
async function getProfile() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS_PROFILE}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateProfile(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS_PROFILE}`

      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getProfile, updateProfile }

import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'

import { FormInput } from '../../components'
import { Hotelier } from '../../types/Hotelier'
import { hotelierSchemaResolver } from './AddHoteliers'

const departmentList = [
  'Restaurant',
  'Housekeeping',
  'Laundary',
  'Extra supplies',
]

type propsType = {
  currentHotelier: Hotelier
  onSubmit: any
}

const EditHotelirs = (props: propsType) => {
  const { currentHotelier, onSubmit } = props

  const { name, email, password, contactNo, department } = currentHotelier

  const methods = useForm<Hotelier>({
    defaultValues: {
      name,
      email,
      password: '',
      contactNo: contactNo.slice(-10),
      department,
    },
    resolver: hotelierSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = methods

  console.log('add contact values -----', getValues())

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <FormInput
            label={'Select Department'}
            type="select"
            name="department"
            placeholder=""
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          >
            {departmentList?.map((dept) => (
              <option value={dept} key={dept}>
                {dept}
              </option>
            ))}
          </FormInput> */}
          <FormInput
            label={'Department'}
            type="string"
            name="department"
            containerClass={'mb-3'}
            key="department"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Name'}
            type="string"
            name="name"
            containerClass={'mb-3'}
            key="name"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Email'}
            type="email"
            name="email"
            containerClass={'mb-3'}
            key="email"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Password'}
            type="password"
            name="password"
            containerClass={'mb-3'}
            key="password"
            register={register}
            errors={errors}
            control={control}
          />

          <Form.Group className="mb-3">
            <Form.Label>Contact number</Form.Label>
            <Controller
              name="contactNo"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ background: '#dedede' }}
                  >
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Enter contact number"
                    aria-label="mobile"
                    aria-describedby="basic-addon1"
                    value={value}
                    onChange={onChange}
                    type="string"
                  />
                </InputGroup>
              )}
            />
            {errors && errors['contactNo'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors['contactNo']['message']}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Edit Staff
            </Button>
          </div>
        </form>
      </Col>
    </Row>
  )
}

export default EditHotelirs

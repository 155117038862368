import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import DateRange from '../../../components/DateRange'
import Loader from '../../../components/Loader'
import NoDataFound from '../../../components/NoDataFound'
import Table from '../../../components/Table'
import { Response } from '../../../constants/types'
import { ProfileContext } from '../../../Context'
import { getAllFeebacks } from '../../../helpers/api/feedback'
import ReadMore from '../../../utils/components/ReadMore'
import { capitalize, getDateTime } from '../../../utils/functions'
import Expression from './Expression'

const Feedback = () => {
  const { user, timeZone } = useContext(ProfileContext)
  const [feedbackList, setFeedbackList] = useState([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)

  const getFeedbackList = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllFeebacks(user.id, params)) as Response
        if (res?.data?.data) {
          setFeedbackList(res?.data?.data)
        }
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )
  const columns = [
    {
      Header: 'Room number',
      accessor: 'room.roomNo',
    },
    {
      Header: 'Feedback',
      accessor: 'feedback',
      Cell: ({ value }: any) => {
        return <ReadMore>{value}</ReadMore>
      },
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }: any) => {
        const { createdAt } = row.original
        // return <span>{moment(createdAt).local().format('MMM DD, yyyy')}</span>
        return <span>{getDateTime(createdAt, timeZone, 'MMM DD, yyyy')}</span>
      },
    },
    {
      Header: 'Time',
      accessor: '',
      Cell: ({ row }: any) => {
        const { createdAt } = row.original
        return <span>{getDateTime(createdAt, timeZone, 'hh:mm A')}</span>
      },
    },
    {
      Header: 'Expression',
      accessor: 'rating',
      Cell: Expression,
    },
  ]

  useEffect(() => {
    if (user.id) {
      getFeedbackList()
    }
  }, [getFeedbackList, user.id])

  return (
    <div className="py-3">
      {pageLoad && <Loader />}
      <div className="d-flex gap-3 align-items-center">
        {/* <i className="bi bi-arrow-left" style={{ fontSize: '20px' }}></i> */}
        <span>{`Dashboard > Feedback`}</span>
      </div>
      <DateRange onApply={getFeedbackList} />
      <div className="pt-2">
        <Card>
          <Card.Body>
            {feedbackList?.length > 0 ? (
              <Table columns={columns} data={feedbackList} />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Feedback

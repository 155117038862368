import React, { useRef } from 'react'
import JoditEditor from 'jodit-react'
// import 'jodit/build/jodit.min.css'

interface TextEditorProps {
  value: any
  onChange: any
}

const TextEditor: React.FC<TextEditorProps> = ({ value, onChange }) => {
  const editor = useRef(null)
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    height: '450px',
  }
  return (
    <div>
      <JoditEditor
        ref={editor}
        config={config}
        value={value}
        onChange={onChange}

        // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      />
    </div>
  )
}

export default TextEditor

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllOrders(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/orders/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getAllOrders }

import { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { FormInput } from '../../components'
import Spinners from '../../components/Spinner'
import { Category } from '../../types/Category'
import { Item } from '../../types/Item'

type propsType = {
  categoryList: Category[]
  editItem: Item
  onEditItemSuccess: any
  updateItem: any
  setRefresh: any
  refresh: any
}

const itemSchemaResolver = yupResolver(
  yup.object().shape({
    categoryId: yup.number().required('Please select category'),
    name: yup.string().required('Please enter name'),

    startTime: yup.string().required('Please enter start time'),
    endTime: yup.string().required('Please enter end time'),
    // actualPrice: yup
    //   .number()
    //   .required('Please enter price')
    //   .typeError('Price must be a number')
    //   .positive('Price can not be negative'),
    // SGST: yup
    //   .number()
    //   .required('Please enter SGST')
    //   .typeError('SGST must be a number')
    //   .positive('SGST can not be negative'),
    // CGST: yup
    //   .number()
    //   .required('Please enter CGST')
    //   .typeError('CGST must be a number')
    //   .positive('CGST can not be negative'),
    time: yup.string().required('Please enter time'),
    image: yup.mixed(),
  }),
)

const EditItem = (props: propsType) => {
  const currency: string | null = localStorage.getItem('CURRENCY')
  const {
    categoryList,
    editItem,
    updateItem,
    onEditItemSuccess,
    setRefresh,
    refresh,
  } = props
  const {
    id,
    categoryId,
    name,
    time,
    startTime,
    endTime,
    actualPrice,
    description,
    isVeg,
    tax,
  } = editItem
  const [loading, setLoading] = useState<boolean>(false)

  const allTaxes = tax?.map((ele) => {
    return {
      name: ele?.tax,
      percentage: ele?.percentage,
      id: ele?.id,
    }
  })

  // console.log(allTaxes, 'all')

  const onSubmit = async (formData: Item, e: any) => {
    formData = {
      ...formData,
      taxes: [],
      newTaxes: [],
    }

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= 10000; i += 1) {
      if (
        e.target[`newTax_${i}`]?.value &&
        e.target[`newPercentage_${i}`]?.value
      ) {
        formData.newTaxes.push({
          tax: e.target[`newTax_${i}`]?.value,
          percentage: e.target[`newPercentage_${i}`]?.value,
        })
        // console.log(e.target[`percentage_${i}`]?.value, 'per')
        // console.log(e.target[`tax_${i}`]?.value, 'tax_')
      } else if (
        e.target[`tax_${i}`]?.value &&
        e.target[`percentage_${i}`]?.value
      ) {
        formData.taxes.push({
          id: e.target[`taxValue_${i}`]?.value,
          percentage: e.target[`percentage_${i}`]?.value,
        })
        // console.log(e.target[`taxValue_${i}`].value, 'idvale')
      } else break
    }
    try {
      if (editItem && editItem.id) {
        setLoading(true)
        const data = {
          ...formData,
          price: +formData.actualPrice,
          isVeg: formData?.isVeg ? 1 : 0,
          taxes: JSON.stringify(formData.taxes),
          newTaxes: JSON.stringify(formData.newTaxes),
        }
        const image = formData.image?.[0]
        if (image) {
          data.image = image
        } else {
          delete data.image
        }
        // console.log(data, 'payload')
        let res: Response = (await updateItem(id, data)) as Response
        if (res.status === 200) {
          toast.success('Edit item successfully')
          onEditItemSuccess({
            cat: formData?.categoryId,
            data: { id, ...formData },
          })
        } else {
          console.log(res)
        }
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }

  const methods = useForm<Item>({
    defaultValues: {
      categoryId,
      name,
      description,
      actualPrice,
      time,
      startTime,
      endTime,
      isVeg,
    },
    resolver: itemSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = methods

  // console.log('values----', watch())

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <FormInput
          label={'Select Category'}
          type="select"
          name="categoryId"
          placeholder=""
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        >
          {categoryList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </FormInput>
        <FormInput
          label={'Item name'}
          type="string"
          name="name"
          placeholder="Enter Item Name"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />
        <FormInput
          label={'Description'}
          type="text"
          name="description"
          placeholder="Enter Description"
          key="description"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />
        <FormInput
          label={'Start Time'}
          type="time"
          name="startTime"
          placeholder="AM 09:00"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />
        <FormInput
          label={'End Time'}
          type="time"
          name="endTime"
          placeholder="PM 08:30"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />
        <Row>
          <Col lg={4}>
            <FormInput
              label={`Veg`}
              type="switch"
              name="isVeg"
              placeholder="isVeg"
              key="isVeg"
              containerClass={'mb-3'}
              register={register}
              errors={errors}
              control={control}
            />
          </Col>
          {/* <Col lg={4}>
            <FormInput
              label="Available"
              type="switch"
              name="isAvailable"
              placeholder="isAvailable"
              key="isAvailable"
              containerClass={'mb-3'}
              register={register}
              errors={errors}
              control={control}
            />
          </Col> */}
        </Row>
        <FormInput
          label={`Price (${currency || 'no data found '})`}
          type="number"
          min={0}
          // step={0.01}
          name="actualPrice"
          placeholder="price"
          containerClass={'mb-3'}
          key="price"
          register={register}
          errors={errors}
          control={control}
        />

        {/* <FormInput
          label={'Stock'}
          type="number"
          min={1}
          name="stock"
          placeholder="stock"
          containerClass={'mb-3'}
        /> */}
        <FormInput
          label={'Expected Delivery Time'}
          type="text"
          // min={1}
          name="time"
          placeholder="time"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />

        <FormInput
          label={'Image'}
          type="file"
          name="image"
          containerClass={'mb-3'}
          accept="image/*"
          {...{ register, errors, control }}
        />
        <div
          style={{
            marginTop: '-20px',
            marginLeft: '4px',
            fontWeight: '700',
            fontSize: '12px',
            marginBottom: '8px',
          }}
        >
          (Image ratio must be 3:1)
        </div>
        <FormInput
          label={'Tax'}
          type="taxes"
          name="taxes"
          editTime={true}
          containerClass={'mb-3'}
          key="taxes"
          allTaxes={allTaxes}
          setRefresh={setRefresh}
          {...{ register, errors, control }}
        />

        <div className="text-md-end mb-0">
          {loading ? (
            <Spinners classname="px-3" />
          ) : (
            <Button variant="primary" className="me-1" type="submit">
              Save
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default EditItem

import { Button, Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'

// images
// import SmallOffice from "../../../assets/images/smallOfficeDesc.png";
import { FormInput } from '../../../components'
import { yupResolver } from '@hookform/resolvers/yup'

// types
import { Item } from '../../../types/Item'
import { Category } from '../../../types/Category'
import { Response } from '../../../constants/types'
import { toast } from 'react-toastify'
import Spinners from '../../../components/Spinner'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

const currency: string | null = localStorage.getItem('CURRENCY')

type propsType = {
  categoryList: Category[]
  restaurantList: Category[]
  onAddItemSuccess: any
  addItem: any
  setSelectedRestaurantId: any
  selectedCategoryId?: number
  selectedRestaurantId?: number
  description?: any
}

export const itemSchemaResolver = yupResolver(
  yup.object().shape({
    categoryId: yup.number().required('Please select category'),
    name: yup.string().required('Please enter name'),
    description: yup.string().required('Please enter description'),
    startTime: yup.string().required('Please enter start time'),
    endTime: yup.string().required('Please enter end time'),
    // actualPrice: yup
    //   .number()
    //   .required('Please enter price')
    //   .typeError('Price must be a number')
    //   .positive('Price can not be negative'),
    // SGST: yup
    //   .number()
    //   .required('Please enter SGST')
    //   .typeError('SGST must be a number')
    //   .positive('SGST can not be negative'),
    // CGST: yup
    //   .number()
    //   .required('Please enter CGST')
    //   .typeError('CGST must be a number')
    //   .positive('CGST can not be negative'),
    time: yup.string().required('Please enter time'),
    image: yup.mixed(),
  }),
)

const AddItem = (props: propsType) => {
  const {
    categoryList,
    onAddItemSuccess,
    addItem,
    selectedCategoryId,
    restaurantList,
    selectedRestaurantId,
    setSelectedRestaurantId,
  } = props
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (formData: Item, e: any) => {
    // console.log(e)

    formData = {
      ...formData,
      taxes: [],
    }

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= 10000; i += 1) {
      if (
        e.target[`newTax_${i}`]?.value &&
        e.target[`newPercentage_${i}`]?.value
      ) {
        formData.taxes.push({
          tax: e.target[`newTax_${i}`]?.value,
          percentage: e.target[`newPercentage_${i}`]?.value,
        })
        // console.log(e.target[`percentage_${i}`]?.value, 'per')
        // console.log(e.target[`tax_${i}`]?.value, 'tax_')
      } else break
    }
    try {
      setLoading(true)
      const data = {
        ...formData,
        price: +formData?.actualPrice,
        isVeg: formData?.isVeg ? 1 : 0,
        // isAvailable: formData?.isAvailable ? 1 : 0,
        taxes: JSON.stringify(formData.taxes),
      }
      const image = formData.image?.[0]
      if (image) {
        data.image = image
      } else {
        delete data.image
      }
      console.log(data)

      let res: Response = (await addItem(data)) as Response
      if (res.status === 200) {
        toast.success('Add item successfully')
        onAddItemSuccess({ cat: formData?.categoryId, data: res?.data?.data })
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  const methods = useForm<Item>({
    resolver: itemSchemaResolver,
    defaultValues: {
      categoryId: selectedCategoryId,
      restaurentId: selectedRestaurantId,
    },
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = methods

  // console.log('values----', watch())
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRestaurantId(event.target.value) // Update selected value on change
  }

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>Select Restaurant</Form.Label>
            <Form.Select
              {...register('restaurentId')}
              name="restaurentId"
              id="restaurentId"
              isInvalid={errors && errors['restaurentId'] ? true : false}
              onChange={handleSelectChange}
            >
              {restaurantList?.map((cat) => (
                <option value={cat?.id} key={cat?.id}>
                  {cat?.name}
                </option>
              ))}
            </Form.Select>

            {errors && errors['restaurentId'] ? (
              <Form.Control.Feedback type="invalid">
                {errors['restaurentId']['message']}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              {...register('categoryId')}
              name="categoryId"
              id="categoryId"
              isInvalid={errors && errors['categoryId'] ? true : false}
            >
              {categoryList?.map((cat) => (
                <option value={cat?.id} key={cat?.id}>
                  {cat?.name}
                </option>
              ))}
            </Form.Select>

            {errors && errors['categoryId'] ? (
              <Form.Control.Feedback type="invalid">
                {errors['categoryId']['message']}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <FormInput
            label={'Name'}
            type="text"
            name="name"
            placeholder="Enter Name"
            key="name"
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          />
          <FormInput
            label={'Description'}
            type="text"
            name="description"
            placeholder="Enter Description"
            key="description"
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          />
          <Row>
            <Col lg={4}>
              <FormInput
                label={`Veg`}
                type="switch"
                name="isVeg"
                placeholder="isVeg"
                key="isVeg"
                containerClass={'mb-3'}
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
            {/* <Col lg={4}>
              <FormInput
                label="Available"
                type="switch"
                name="isAvailable"
                placeholder="isAvailable"
                key="isAvailable"
                containerClass={'mb-3'}
                register={register}
                errors={errors}
                control={control}
              />
            </Col> */}
          </Row>
          <FormInput
            label={`Price (${currency || 'no data found '})`}
            type="number"
            min={0}
            // step={0.01}
            defaultValue={0}
            name="actualPrice"
            placeholder="price"
            key="price"
            containerClass={'mb-3'}
            register={register}
            errors={errors}
            control={control}
          />

          <FormInput
            label={'Start Time'}
            type="time"
            name="startTime"
            placeholder="AM 09:00"
            containerClass={'mb-3'}
            key="startTime"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'End Time'}
            type="time"
            name="endTime"
            placeholder="PM 08:30"
            containerClass={'mb-3'}
            key="endTime"
            register={register}
            errors={errors}
            control={control}
          />

          {/* <FormInput
            type="number"
            name="compensationCess"
            key="compensationCess"
            label={'Compensation Cess'}
            containerClass={'mb-3'}
            {...{ register, errors, control }}
            min={0}
            step={0.01}
          /> */}
          {/* <FormInput
            label={'Stock'}
            type="number"
            min={1}
            name="stock"
            placeholder="stock"
            containerClass={'mb-3'}
          /> */}
          <FormInput
            label={'Expected Delivery Time'}
            type="text"
            // min={1}
            name="time"
            placeholder="time"
            key="time"
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          />
          <FormInput
            label={'Image'}
            type="file"
            name="image"
            containerClass={'mb-3'}
            key="image"
            accept="image/*"
            {...{ register, errors, control }}
          />
          <div
            style={{
              marginTop: '-20px',
              marginLeft: '4px',
              fontWeight: '700',
              fontSize: '12px',
              marginBottom: '8px',
            }}
          >
            (Image ratio must be 1:1)
          </div>
          <FormInput
            label={'Tax'}
            type="taxes"
            name="taxes"
            containerClass={'mb-3'}
            key="taxes"
            {...{ register, errors, control }}
          />

          <div className="text-md-end mb-0">
            {loading ? (
              <Spinners classname="px-3" />
            ) : (
              <Button variant="primary" className="me-1" type="submit">
                Add Item
              </Button>
            )}
          </div>
        </form>
      </Col>
      {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
    </Row>
  )
}

export default AddItem

import { useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { HotelDetailsType, RestaurantTimeType } from '../../types/HotelDetails'
import ServiceItem from './ServiceItem'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import {
  createResTime,
  deleteResTime,
  updateResTime,
} from '../../helpers/api/hotelDetais'
import { log } from 'console'

const name = 'restaurantTime'

export const resTimeSchemaResolver = yupResolver(
  yup.object().shape({
    restaurantTime: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('name is required'),
          startTime: yup.string().required('start time is required'),
          endTime: yup.string().required('endTime is required'),
        }),
      )
      .required('restaurant Time is required'),
  }),
)

type PropsType = {
  hotelDetails: HotelDetailsType
}
const RestaurantItems = (props: PropsType) => {
  let { hotelDetails } = props

  let x = hotelDetails?.restaurantTime

  x = x?.map((time) => {
    time.timeId = time.id
    return time
  })

  console.log('x', x)
  console.log('hotelDetails', hotelDetails)

  hotelDetails.restaurantTime = x

  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm<any>({
    defaultValues: useMemo(() => {
      return hotelDetails
    }, [hotelDetails]),
    resolver: resTimeSchemaResolver,
  })

  const { control } = methods

  const { fields, append, update, remove } = useFieldArray({
    control,
    name,
  })

  const handleUpdateResTimeSubmit = async (
    body: { name: string; startTime: string; endTime: string, idCopy: any, timeId: any },
    id?: number,
  ) => {

    let updatedBody = { ...body }

    delete updatedBody.idCopy;
    delete updatedBody.timeId;


    try {
      setIsLoading(true)
      if (id) {
        let res: Response = (await updateResTime(id, updatedBody)) as Response
        if (res.status === 200) {
          toast.success('Restaurant time updated successfully')
          window.location.reload();
        } else {
          console.log(res)
        }
      } else {
        let res: Response = (await createResTime(updatedBody)) as Response
        if (res.status === 200) {
          toast.success('Restaurant time created successfully')
          window.location.reload();
        } else {
          console.log(res)
        }
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteResTimeSubmit = async (id: number) => {
    let res: Response = (await deleteResTime(id)) as Response
    if (res.status === 200) {
      toast.success('Restaurant time deleted successfully')
    } else {
      console.log(res)
    }
  }

  console.log(isLoading)
  console.log('1', fields)
  return (
    <>
      <h4 className="header-title mt-0 mb-1">Restaurant Timing</h4>
      {fields.map((field, index) => (
        <ServiceItem
          key={field.id}
          control={control}
          update={update}
          remove={remove}
          index={index}
          value={field}
          name={name}
          onUpdate={handleUpdateResTimeSubmit}
          onDelete={handleDeleteResTimeSubmit}
        />
      ))}
      <div className="mt-4">
        <span
          onClick={() =>
            append({
              name: '',
              startTime: '00:00:00',
              endTime: '00:00:00',
            })
          }
        >
          Add more <i className="uil uil-plus-circle"></i>
        </span>
      </div>
    </>
  )
}

export default RestaurantItems

import { useEffect, useState } from 'react'
import { Button, Card, Offcanvas } from 'react-bootstrap'

import Table from '../../../components/Table'
import AddContact from './AddContact'

import Loader from '../../../components/Loader'
import { Response } from '../../../constants/types'
import {
  addContact,
  deleteContact,
  getMyAllContacts,
  updateContact,
} from '../../../helpers/api/contacts'
import DeleteConfirmation from '../../../components/DeleteConfirmation'
import { toast } from 'react-toastify'
import { Contact } from '../../../types/Item'
import EditContact from './EditContact'
import NoDataFound from '../../../components/NoDataFound'
// import { capitalize } from '../../../utils/functions'

interface FormType {
  [key: string]: string
}

const FORM_TYPE: FormType = {
  ADD_CONTACT: 'Add Contact',
  EDIT_CONTACT: 'Edit Contact',
}

const HelpDesk = () => {
  const [visible, setVisible] = useState(false)
  const [contactList, setContactList] = useState<Contact[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false)
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [formType, setFormType] = useState(FORM_TYPE.ADD_CONTACT)
  const [currentContact, setCurrentContact] = useState<Contact>()

  const getContactList = async (categoryId?: number) => {
    try {
      setPageLoad(true)
      let res: Response = (await getMyAllContacts({ categoryId })) as Response
      setContactList(res?.data?.data)
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      setPageLoad(false)
    }
  }

  const handleDeleteContact = async (id: any) => {
    try {
      let res: Response = (await deleteContact(id)) as Response
      if (res.status === 200) {
        toast.success('Contact deleted successfully')
        setContactList((prev) => prev.filter((item) => item.id !== id))
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const toggle = () => {
    setVisible((prev) => !prev)
  }

  const handleEditContactClick = (curCont: Contact) => {
    setCurrentContact(curCont)
    toggle()
    setFormType(FORM_TYPE.EDIT_CONTACT)
  }

  const handleEditDeviceSubmit = async (formData: Contact) => {
    formData.mobile = `${formData.mobile}`
    if (currentContact) {
      try {
        let res: Response = (await updateContact(
          currentContact.id,
          formData,
        )) as Response
        if (res.status === 200) {
          toast.success('Contact edited successfully')
        }
        toggle()
        setCurrentContact(undefined)
        getContactList()
      } catch (error) {
        toast.error('Something went wrong')
      }
    }
  }

  const handleAddDeviceSubmit = async (formData: Contact) => {
    console.log('Before', formData.mobile)
    formData.mobile = `${formData.mobile}`
    console.log('After', formData.mobile)
    console.log('formData', formData)

    try {
      let res: Response = (await addContact(formData)) as Response
      if (res.status === 200) {
        toast.success('Contact added successfully')
        getContactList()
        toggle()
      }
    } catch (error: any) {
      // toast.error(capitalize(error?.message) || "something went wrong")
    } finally {
    }
  }

  const columns = [
    // {
    //   Header: 'Contact ID',
    //   accessor: 'id',
    // },
    {
      Header: 'Department',
      accessor: 'department',
    },
    {
      Header: 'Contact number',
      accessor: 'mobile',
    },
    {
      Header: 'User name',
      accessor: 'name',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button onClick={() => handleEditContactClick(row.original)}>
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const handleAddContactClick = () => {
    toggle()
    setFormType('Add Contact')
  }

  useEffect(() => {
    getContactList()
  }, [])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>{`Web App Setup > Help Desk`}</h3>
          <div className="d-inline-flex gap-2">
            <Button onClick={handleAddContactClick}>+ Add Contact</Button>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body>
              {contactList?.length > 0 ? (
                <Table columns={columns} data={contactList} />
              ) : (
                <NoDataFound />
              )}
            </Card.Body>
          </Card>
        </div>
        {/* <div className="d-flex justify-content-end gap-2">
          <Button>Back</Button>
          <Button>{`Save & next`}</Button>
        </div> */}

        <Offcanvas
          show={visible}
          onHide={toggle}
          placement={'end'}
          // style={{ width: "75%" }}
          style={{ width: '33%' }}
          backdrop="static"
        >
          <Offcanvas.Header closeButton>
            <h5 id="offcanvasTopLabel">
              {formType === 'Add Contact' ? 'Add Contact' : 'Edit Contact'}
            </h5>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {formType === FORM_TYPE.ADD_CONTACT && (
              <AddContact onSubmit={handleAddDeviceSubmit} />
            )}
            {formType === FORM_TYPE.EDIT_CONTACT && currentContact && (
              <EditContact
                currentContact={currentContact}
                onSubmit={handleEditDeviceSubmit}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            handleDeleteContact(deleteItemId)
          }
        }}
      />
    </>
  )
}

export default HelpDesk

import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { FormInput } from '../../../components'
import { Contact } from '../../../types/Item'
import { contactSchemaResolver } from './AddContact'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

type propsType = {
  currentContact: Contact
  onSubmit: any
}

const EditContact = (props: propsType) => {
  const { currentContact, onSubmit } = props

  let { name, department, mobile } = currentContact

  // mobile = mobile.slice(-10)

  console.log(currentContact)
  console.log(parsePhoneNumber(`+${mobile}`), 'parase')

  const countryCode = `+${mobile}`

  const country: any = parsePhoneNumber(countryCode)

  console.log(country)

  const methods = useForm<Contact>({
    defaultValues: { name, department, mobile },
    resolver: contactSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = methods

  console.log('add contact values -----', getValues())
  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label={'Department Name'}
            type="string"
            name="department"
            placeholder="Enter Department Name"
            containerClass={'mb-3'}
            key="department"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'User Name'}
            type="string"
            name="name"
            placeholder="Enter User Name"
            containerClass={'mb-3'}
            key="name"
            register={register}
            errors={errors}
            control={control}
          />
          {/* <Form.Group className="mb-3">
            <Form.Label>Contact number</Form.Label>
            <Controller
              name="mobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ background: '#dedede' }}
                  >
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Enter contact number"
                    aria-label="mobile"
                    aria-describedby="basic-addon1"
                    value={value}
                    onChange={onChange}
                    type="string"
                  />
                </InputGroup>
              )}
            />
            {errors && errors['mobile'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors['mobile']['message']}
              </Form.Control.Feedback>
            )}
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Controller
              name="mobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore

                <PhoneInput
                  international
                  placeholder=""
                  defaultCountry={country?.country}
                  onChange={onChange}
                  value={country?.number}
                  id="mobile"
                  inputComponent={Form.Control}
                />
              )}
            />
            {errors && errors['mobile'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                Please enter valid Contact number
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Update Contact
            </Button>
          </div>
        </form>
      </Col>
    </Row>
  )
}

export default EditContact

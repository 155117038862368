import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// import logo from '../../assets/images/logo.png'
import Loader from '../../components/Loader'
import Table from '../../components/Table'
import { ProfileContext } from '../../Context'

type GuestInvoiceItem = {
  id?: number
  item?: string
  quantity?: number | string
  price: number | string
  SGSTValue: number
  CGSTValue: number
  subTotal: number
}

type Transaction = {
  id?: number
  guestInvoiceItem: GuestInvoiceItem
}

type Settlement = {
  id: number
  createdAt: string
  itemTotal: number
  CGSTTotal: number
  SGSTTotal: number
  roomNo: string
  transaction: Transaction[]
}

const columns = [
  { Header: 'Order Id', accessor: 'orderId' },
  { Header: 'Items', accessor: 'guestInvoiceItem.item' },
  { Header: 'Quantity', accessor: 'guestInvoiceItem.quantity' },
  { Header: 'Price', accessor: 'guestInvoiceItem.price' },
  {
    Header: 'SGST',
    accessor: 'guestInvoiceItem.SGSTValue',
    Cell: ({ row }: { row: any }) => {
      const { SGST = 0, SGSTValue = 0 } = row.original?.guestInvoiceItem
      return SGST !== 0 ? `${SGSTValue}(${SGST}%)` : SGSTValue
    },
  },
  {
    Header: 'CGST',
    accessor: 'guestInvoiceItem.CGSTValue',
    Cell: ({ row }: { row: any }) => {
      const { CGST = 0, CGSTValue = 0 } = row.original?.guestInvoiceItem
      return CGST !== 0 ? `${CGSTValue}(${CGST}%)` : CGSTValue
    },
  },
  {
    Header: 'IGST',
    accessor: 'guestInvoiceItem.IGSTValue',
    Cell: ({ row }: { row: any }) => {
      const { IGST = 0, IGSTValue = 0 } = row.original?.guestInvoiceItem
      return IGST !== 0 ? `${IGSTValue}(${IGST}%)` : IGSTValue
    },
  },
  {
    Header: 'GST Without ITC',
    accessor: 'guestInvoiceItem.GSTWithoutITCValue',
    Cell: ({ row }: { row: any }) => {
      const { GSTWithoutITC = 0, GSTWithoutITCValue = 0 } =
        row.original?.guestInvoiceItem
      return GSTWithoutITC !== 0
        ? `${GSTWithoutITCValue}(${GSTWithoutITC}%)`
        : GSTWithoutITCValue
    },
  },
  {
    Header: 'Vat',
    accessor: 'guestInvoiceItem.vatValue',
    Cell: ({ row }: { row: any }) => {
      const { Vat = 0, vatValue = 0 } = row.original?.guestInvoiceItem
      return Vat !== 0 ? `${vatValue}(${Vat}%)` : vatValue
    },
  },
  {
    Header: 'Compensation Cess',
    accessor: 'guestInvoiceItem.compensationCessValue',
    Cell: ({ row }: { row: any }) => {
      const { compensationCess = 0, compensationCessValue = 0 } =
        row.original?.guestInvoiceItem
      return compensationCess !== 0
        ? `${compensationCessValue}(${compensationCess}%)`
        : compensationCessValue
    },
  },
  {
    Header: 'Service Tax',
    accessor: 'guestInvoiceItem.serviceTaxValue',
    Cell: ({ row }: { row: any }) => {
      const { serviceTax = 0, serviceTaxValue = 0 } =
        row.original?.guestInvoiceItem
      return serviceTax !== 0
        ? `${serviceTaxValue}(${serviceTax}%)`
        : serviceTaxValue
    },
  },
  { Header: 'Total', accessor: 'guestInvoiceItem.subTotal' },
]

type LocationState = {
  state: {
    settlement?: Settlement
  }
}

const getTotals = (data: any[], key: string) => {
  let total = 0
  data.forEach(({ guestInvoiceItem }) => {
    total += guestInvoiceItem[key] || 0
  })
  return total
}

const ViewSettlement = () => {
  const { user } = useContext(ProfileContext)
  console.log(user)
  const { name, address, GST } = user
  const history = useHistory()
  const { state } = useLocation() as LocationState
  const settlement = state?.settlement
  if (!settlement) {
    history.goBack()
  }
  const iniTransList = settlement?.transaction as Transaction[]
  const [transList, setTransList] = useState(iniTransList)

  useEffect(() => {
    setTransList((prev) => {
      return [
        ...prev,
        {
          guestInvoiceItem: {
            quantity: 'Total',
            price: getTotals(prev, 'price'),
            CGSTValue: getTotals(prev, 'CGSTValue'),
            GSTWithoutITCValue: getTotals(prev, 'GSTWithoutITCValue'),
            IGSTValue: getTotals(prev, 'IGSTValue'),
            SGSTValue: getTotals(prev, 'SGSTValue'),
            vatValue: getTotals(prev, 'vatValue'),
            compensationCessValue: getTotals(prev, 'compensationCessValue'),
            serviceTaxValue: getTotals(prev, 'serviceTaxValue'),
            subTotal: getTotals(prev, 'subTotal'),
          },
        },
      ]
    })
  }, [])

  const printDocument = () => {
    const input = document.getElementById('settlementCard')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(canvas)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        // pdf.output('dataurlnewwindow');
        pdf.save('download.pdf')
      })
    }
  }

  console.log('transList-----', transList)
  return (
    <>
      {settlement ? (
        <div>
          {/* <div className="page-title-box mt-2 pb-10 border-bottom-2">
            <div className="col">
              <h2 className="page-title">Settlement</h2>
            </div>
          </div> */}

          <div className="card bg-white mt-4" id="settlementCard">
            <div className="card-body p-5 ">
              <p className="text-center fs-3 fw-bold">Settlement</p>

              <div className="d-flex justify-content-between mt-4">
                <div>
                  <p className="fs-4 fw-bold">{name}</p>
                  <p>
                    Address: <span>{address}</span>
                  </p>
                  <p>
                    GST No.: <span>{GST}</span>
                  </p>
                  <p>
                    Settlement No.: <span>{settlement?.id}</span>
                  </p>
                  <p>
                    Settlement Date:{' '}
                    <span>
                      {moment(settlement?.createdAt).local().format('DD-MMM-YYYY')}
                    </span>
                  </p>
                  {/* <p>
                    Room No.: <span>{settlement?.roomNo}</span>
                  </p> */}
                </div>

                {/* <div>
                  <img src={logo} alt="" height={49} />
                </div> */}
              </div>
              <hr />
              <div>
                <div className="fs-4 fw-bold">Order summary</div>
              </div>
              <div>
                <Table columns={columns} data={transList} />
              </div>

              <div className="col d-flex justify-content-end mt-5">
                <button
                  className="waves-effect waves-light btn btn-primary"
                  type="button"
                  onClick={printDocument}
                >
                  <i className="bi bi-printer-fill"></i> Print
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ViewSettlement

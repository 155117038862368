import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Loader from '../../../components/Loader'
import { Response } from '../../../constants/types'
import { ProfileContext } from '../../../Context'

import { getAllPlans, subscribePlanById } from '../../../helpers/api/payment'
import { capitalize } from '../../../utils/functions'
import classes from './../../../pages/NewPlans/plans.module.css'

declare global {
  interface Window {
    Razorpay: any
  }
}

const SelectPlan = () => {
  const { user } = useContext<any>(ProfileContext)

  console.log('12', user)

  const [plans, setPlans] = useState<any[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<any>({})

  const getPlans = useCallback(async (params?: any) => {
    try {
      setPageLoad(true)
      let res: Response = (await getAllPlans('', params)) as Response
      setPlans(res?.data?.plans?.items)
    } catch (error: any) {
      toast.error(capitalize(error.errorMessage))
    } finally {
      setPageLoad(false)
    }
  }, [])

  const subscribePlan = useCallback(async (id?: any, params?: any) => {
    try {
      setPageLoad(true)
      let res: Response = (await subscribePlanById({
        plan_id: id,
      })) as Response
      if (res?.data?.status == 200) {
        toast.success(capitalize(res?.data?.message))
        console.log(12312312313)

        window.location.replace('/')
      }
    } catch (error: any) {
      toast.error(capitalize(error.errorMessage))
    } finally {
      setPageLoad(false)
      getPlans()
    }
  }, [])

  function loadScript(src: any) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    getPlans()
  }, [getPlans])

  let activePlans = plans?.filter((plan) => plan?.item?.active)

  return (
    <>
      {pageLoad && <Loader />}
      <div className="p-4">
        <Card.Title>
          <div className="d-flex justify-content-between">
            <h3>Plans</h3>
            {/* <Button variant="dark">Export CSV</Button> */}
          </div>
        </Card.Title>
        <Card>
          <Card.Body>
            <div
              className="d-flex flex-row gap-3"
              style={{ overflowX: 'scroll' }}
            >
              {activePlans?.map((plan) => (
                <div
                  key={plan?.id}
                  style={{
                    border:
                      plan?.id == selectedPlan?.id
                        ? '1.5px solid black'
                        : 'none',
                  }}
                  className={classes.planCard}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <p>Get a free trial for 30 days then just!</p>
                  <h1 className="">₹{plan?.item?.unit_amount}</h1>
                  <p className=" ">
                    <span style={{ textTransform: 'capitalize' }}>
                      {plan?.period}
                    </span>
                    /Room
                  </p>
                  {/* <Button onClick={() => subscribePlan(plan?.id)} variant="dark">
                    Subscribe
                  </Button> */}
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
        <Card.Title className="">
          <div className="">
            <h6 className="">
              Number of rooms in {user?.name || 'Your hotel.'}
            </h6>
          </div>
        </Card.Title>
        <Card.Body
          className="border border-secondary rounded-3 p-1"
          style={{
            width: 'fit-content',
            // marginLeft: 'auto'
          }}
        >
          {user?.rooms}
        </Card.Body>
        {selectedPlan && (
          <>
            <Card.Title className="mt-4">
              <div className="">
                <h6 className="">Total Amount</h6>
              </div>
            </Card.Title>
            <div
              className="border border-secondary rounded-3 p-1 d-inline"
              style={{
                width: 'fit-content',
                marginRight: '8px',
              }}
            >
              {user?.rooms * selectedPlan?.item?.unit_amount}
            </div>
            <span>{selectedPlan?.item?.currency}</span>
            <div className=" mt-4">
              <Button
                onClick={() => subscribePlan(selectedPlan?.id)}
                variant="dark"
              >
                Subscribe
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SelectPlan

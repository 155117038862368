import axios from 'axios'
import { toast } from 'react-toastify'
import { capitalize } from '../../utils/functions'
export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'head'
  | 'HEAD'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'purge'
  | 'PURGE'
  | 'link'
  | 'LINK'
  | 'unlink'
  | 'UNLINK'

interface Config {
  method: Method
  url: string
  data?: any
  headers?: any
}

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json'
//axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log(error)
    let data = { ...error?.response?.data, errorMessage: '' }
    switch (error?.response?.status) {
      case 400:
        data.errorMessage = 'Bad Request'
        if (data?.message) toast.error(capitalize(data?.message))
        break
      case 401:
        data.errorMessage = 'Invalid credentials'
        // localStorage.removeItem('AUTH_TOKEN')
        // window.location.href = '/auth/login'
        return error
      case 403:
        localStorage.removeItem('AUTH_TOKEN')
        window.location.href = '/auth/login'
        data.errorMessage = 'Access Forbidden'
        break
      case 404:
        data.errorMessage =
          'Sorry! the data you are looking for could not be found'
        break
      case 500:
        const emsg = error?.response?.data?.message?.toLowerCase()
        if (emsg === 'jwt malformed' || emsg === 'invalid signature') {
          localStorage.removeItem('AUTH_TOKEN')
          window.location.href = '/auth/login'
        }
        break
      default: {
        data.errorMessage = 'something went wrong'
      }
    }
    if (data) {
      return Promise.reject(data)
    } else {
      localStorage.removeItem('AUTH_TOKEN')
      window.location.href = '/error-500'
    }
  },
)

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  // console.log('token =>', token)
  if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const getToken = () => {
  const token = localStorage.getItem('AUTH_TOKEN')
  return token
}
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response

    if (url === 'https://api.kisi.io/locks') {
      const options: Config = {
        method: 'GET',
        url: 'https://api.kisi.io/locks',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'KISI-LOGIN 1a91144f7a387c1009de22561372b8d6',
          Accept: 'application/json; charset=utf-8',
        },
        data: {},
      }
      response = axios.request(options)
    } else {
      setAuthorization(getToken())
      if (params) {
        var queryString = params
          ? Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&')
          : ''
        response = axios.get(`${url}?${queryString}`, params)
      } else {
        response = axios.get(`${url}`, params)
      }
    }

    return response
  }

  getFile = (url: string, params: any) => {
    let response
    setAuthorization(getToken())
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : ''
      response = axios.get(`${url}?${queryString}`, { responseType: 'blob' })
    } else {
      response = axios.get(`${url}`, { responseType: 'blob' })
    }
    return response
  }

  getMultiple = (urls: string, params: any) => {
    setAuthorization(getToken())
    const reqs = []
    let queryString = ''
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : ''
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`))
    }
    return axios.all(reqs)
  }

  /**
   * post given data to url
   */
  create = async (url: string, data: any) => {
    console.log({ url, data })

    //setAuthorization(getToken())
    let config: Config = {
      method: 'POST',
      url: url,
      data,
    }
    console.log(config)

    let result = await axios(config)

    return result
    // console.log({ url, data });
    // console.log(axios);

    // return await axios.post(url, data);
  }

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    setAuthorization(getToken())
    return axios.patch(url, data)
  }

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    setAuthorization(getToken())
    return axios.put(url, data)
  }

  /**
   * Deletes data
   */
  delete = (url: string) => {
    setAuthorization(getToken())
    return axios.delete(url)
  }

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    }
    return axios.post(url, formData, config)
  }

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    }
    return axios.patch(url, formData, config)
  }

  isUserAuthenticated = () => {
    const user = getToken()
    // console.log(user)

    if (!user) {
      return false
    } else {
      return true
    }
  }
}

/*
Check if token available in session
*/

export { APICore, setAuthorization }

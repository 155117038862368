import { useEffect, useState } from 'react'
import { Button, Card, Form, Offcanvas } from 'react-bootstrap'
import FeatherIcon from 'feather-icons-react'

import Table from '../../../components/Table'

import AddItem from './AddItem'
import AddCategory from '../AddCategory'
import { Response } from '../../../constants/types'
import { deleteCategory, getCategoryList } from '../../../helpers/api/category'
import {
  getAllItems,
  deleteItem,
  addItem,
  updateItem,
} from '../../../helpers/api/restautantItems'
import { Category } from '../../../types/Category'
import { Item } from '../../../types/Item'
import EditItem from '../EditItem'
import DeleteConfirmation from '../../../components/DeleteConfirmation'
import { toast } from 'react-toastify'
import Loader from '../../../components/Loader'
import NoDataFound from '../../../components/NoDataFound'

import placeholderImage from '../../../assets/images/placeholderImage.png'
import EditCategory from '../EditCategory'
import EditCategoryNew from '../EditCategory'
import AddRestaurant from '../AddRestaurant'
import { Restarant } from '../../../types/Restaurant'
import {
  deleteRestarant,
  getRestarantList,
} from '../../../helpers/api/restaurant'
import EditRestaurant from '../EditRestaurant'

const Restaurant = () => {
  const [visible, setVisible] = useState(false)
  const [formType, setFormType] = useState('Add Item')
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [restaurantList, setRestaurantList] = useState<Restarant[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number>(0)
  const [itemList, setItemList] = useState<Item[]>([])
  const [editItem, setEditItem] = useState<Item>()
  const [editCategory, setEditCategory] = useState<Category>()
  const [editRestaurant, setEditRestaurant] = useState<Restarant>()
  const [showDeleteConf, setShowDeleteConf] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [deleteCatId, setDeleteCatId] = useState<number>()
  const [deleteRestuId, setDeleteRestuId] = useState<number>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [deleteMessage, setDeleteMessage] = useState<string>()

  const section = `restaurant`

  // console.log(refresh, 'res')

  const toggle = () => {
    setVisible((prev) => !prev)
  }

  const handleAddItemClick = () => {
    toggle()
    setFormType('Add Item')
  }

  const handleEditItemClick = (values: any) => {
    setEditItem(values)
    toggle()
    setFormType('Edit Item')
  }

  const handleAddCategoryClick = () => {
    toggle()
    setFormType('Add Category')
  }
  const handleAddRestaurantClick = () => {
    toggle()
    setFormType('Add Restaurant')
  }

  const handleEditRestaurantClick = () => {
    const editRest = restaurantList.find((c) => c.id === selectedRestaurantId)
    if (editRest) {
      setEditRestaurant(editRest)
      toggle()
      setFormType('Edit Restaurant')
    }
  }
  const handleEditCategoryClick = () => {
    const editCat = categoryList.find((c) => c.id === selectedCategoryId)
    if (editCat) {
      setEditCategory(editCat)
      toggle()
      setFormType('Edit Category')
    }
  }

  const getRestarantData = async () => {
    let res: Response = (await getRestarantList()) as Response
    // console.log('response of get restaurant list ---->', res)

    setRestaurantList(res?.data?.data?.restaurents)
  }
  const getCategoryData = async () => {
    let res: Response = (await getCategoryList({
      section: `${section}&restaurentId=${selectedRestaurantId}`,
    })) as Response
    // console.log('response of get category list ---->', res.data.data)
    setCategoryList(res.data.data)
  }

  const hadleRestaurantSelect = (e: any) => {
    setSelectedRestaurantId(parseInt(e.target.value))
  }
  const hadleCategorySelect = (e: any) => {
    setSelectedCategoryId(parseInt(e.target.value))
  }

  const getRestaurantItemList = async (
    categoryId?: number,
    restaurentId?: number,
  ) => {
    let parameter: any = {}

    if (categoryId !== 0) {
      parameter.categoryId = categoryId
    }
    if (restaurentId !== 0) {
      parameter.restaurentId = restaurentId
    }
    setPageLoad(true)
    let res: Response = (await getAllItems(parameter)) as Response
    setItemList(res?.data?.data)
    setPageLoad(false)
  }

  const handleDeleteItem = async (id: any) => {
    let res: Response = (await deleteItem(id)) as Response
    if (res.status === 200) {
      toast.success('Delete item successfully')
      setItemList((prev) => prev.filter((item) => item.id !== id))
    }
  }
  const handleDeleteCategory = async (id: number) => {
    let res: Response = (await deleteCategory(id)) as Response
    if (res.status === 200) {
      toast.success('Delete category successfully')
      setSelectedCategoryId(0)
      setCategoryList((prev) => prev.filter((cat) => cat.id !== id))
    }
  }
  const handleDeleteRestaurant = async (id: number) => {
    let res: Response = (await deleteRestarant(id)) as Response
    if (res.status === 200) {
      toast.success('Delete restaurant successfully')
      setSelectedRestaurantId(0)
      setRestaurantList((prev) => prev.filter((cat) => cat.id !== id))
    }
  }

  const isItemAvailabel = async (checked: any, id: any) => {
    // console.log(e)
    // console.log(id)
    const value: any = checked ? 1 : 0

    console.log(value)
    // payload.append('isAvailable', value)
    const payload = {
      isAvailable: value,
    }

    let res: Response = (await updateItem(id, payload)) as Response
    if (res.status === 200) {
      // toast.success('Edit item successfully')
      setRefresh((prev) => !prev)
    } else {
      console.log(res)
    }
  }

  const columns = [
    // {
    //   Header: 'Item ID',
    //   accessor: 'id',
    // },
    // {
    //   Header: '',
    //   accessor: 'categoryId',
    //   Cell: <></>,
    // },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ({ row }: any) => {
        return (
          <div>
            <img
              src={row.original?.image || placeholderImage}
              alt={row.original.name}
              height={70}
              width={70}
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
        )
      },
    },
    {
      Header: 'Item name',
      accessor: 'name',
    },
    {
      Header: 'Item price',
      accessor: 'actualPrice',
    },
    // {
    //   Header: 'Hotelcom Charge',
    //   accessor: 'hotelcomCharge',
    //   Cell: ({ row }: any) => {
    //     const { price, actualPrice } = row?.original
    //     return price - actualPrice
    //   },
    // },
    {
      Header: 'Time',
      accessor: 'time',
    },
    // {
    //   Header: 'Stock',
    //   accessor: 'stock',
    // },
    {
      Header: 'Available',
      accessor: 'isAvailable',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={row?.original?.isAvailable}
              onChange={(e) => {
                isItemAvailabel(e.target.checked, row.original.id)
                // console.log(row)
              }}
            />
            {/* <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
                setDeleteMessage(
                  `Are you sure to delete ${row.original?.name} ?`,
                )
              }}
            >
              Delete
            </Button> */}
          </div>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button onClick={() => handleEditItemClick(row.original)}>
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
                setDeleteMessage(
                  `Are you sure to delete ${row.original?.name} ?`,
                )
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    getRestarantData()
  }, [])

  useEffect(() => {
    if (selectedRestaurantId !== undefined) {
      if (selectedCategoryId !== undefined) {
        getRestaurantItemList(selectedCategoryId, selectedRestaurantId)
      } else {
        getRestaurantItemList(0, selectedRestaurantId)
      }
    }
  }, [selectedCategoryId, refresh])

  useEffect(() => {
    setSelectedCategoryId(0)
    getCategoryData()
    if (selectedRestaurantId !== undefined) {
      getRestaurantItemList(0, selectedRestaurantId)
    }
  }, [selectedRestaurantId, refresh])

  return (
    <div className="d-flex flex-column gap-2">
      {pageLoad && <Loader />}
      <div className="d-flex gap-2 py-2 justify-content-between">
        <h3>{`Web App Setup > Restaurant`}</h3>
        <div className="d-inline-flex gap-2">
          <Button onClick={handleAddRestaurantClick}>+ Add Restaurant</Button>
          <Button onClick={handleAddCategoryClick}>+ Add Category</Button>
          <Button onClick={handleAddItemClick}>+ Add Item</Button>
        </div>
      </div>
      {/* <div>
        <h5>
          Hotelcom's commision will be added with all saleable products -
          <a
            href="https://hotelcom.live/pricing.html"
            target="_blank"
            rel="noreferrer"
          >
            For more details click
          </a>
        </h5>
      </div> */}
      <div className="d-flex align-items-center mb-2 gap-2">
        <Form.Select
          className="w-25"
          size="lg"
          value={selectedRestaurantId}
          onChange={hadleRestaurantSelect}
          style={{ minWidth: '185px' }}
        >
          <option value={0}>Select Restaurant</option>
          {restaurantList?.map((res) => (
            <option value={res?.id} key={res?.id}>
              {res?.name}
            </option>
          ))}
        </Form.Select>
        {selectedRestaurantId ? (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteRestuId(selectedRestaurantId)
                const deleteRestaurant = restaurantList.find(
                  (c) => c.id === selectedRestaurantId,
                )
                if (deleteRestaurant) {
                  setDeleteMessage(
                    `Are you sure to delete the category ${deleteRestaurant?.name} ?`,
                  )
                }
              }}
            >
              <FeatherIcon icon="trash-2" />
            </Button>
            <Button
              onClick={() => {
                handleEditRestaurantClick()
              }}
            >
              <FeatherIcon icon="edit" />
            </Button>
          </>
        ) : null}
        <Form.Select
          className="w-25"
          size="lg"
          value={selectedCategoryId}
          onChange={hadleCategorySelect}
          style={{ minWidth: '185px' }}
        >
          <option value={0}>Select Category</option>
          {categoryList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </Form.Select>
        {selectedCategoryId ? (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteCatId(selectedCategoryId)
                const deleteCategory = categoryList.find(
                  (c) => c.id === selectedCategoryId,
                )
                if (deleteCategory) {
                  setDeleteMessage(
                    `Are you sure to delete the category ${deleteCategory?.name} ?`,
                  )
                }
              }}
            >
              <FeatherIcon icon="trash-2" />
            </Button>
            <Button
              onClick={() => {
                handleEditCategoryClick()
              }}
            >
              <FeatherIcon icon="edit" />
            </Button>
          </>
        ) : null}
      </div>
      <div>
        <Card>
          <Card.Body>
            {itemList?.length > 0 ? (
              <Table columns={columns} data={itemList} />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
      {/* <div className="d-flex justify-content-end gap-2">
        <Button>Back</Button>
        <Button>{`Save & next`}</Button>
      </div> */}

      <Offcanvas
        show={visible}
        onHide={toggle}
        placement={'end'}
        // style={{ width: '50%' }}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">{formType}</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {formType && formType === 'Add Item' && (
            <AddItem
              selectedCategoryId={selectedCategoryId}
              selectedRestaurantId={selectedRestaurantId}
              setSelectedRestaurantId={setSelectedRestaurantId}
              categoryList={categoryList}
              restaurantList={restaurantList}
              addItem={addItem}
              onAddItemSuccess={({
                cat,
                data,
              }: {
                cat?: number
                data: any
              }) => {
                toggle()
                getRestaurantItemList(selectedCategoryId)
                // setSelectedCategoryId(cat || 0)
              }}
            />
          )}
          {formType && formType === 'Edit Item' && editItem && (
            <EditItem
              categoryList={categoryList}
              updateItem={updateItem}
              editItem={editItem}
              setRefresh={setRefresh}
              refresh={refresh}
              onEditItemSuccess={({
                cat,
                data,
              }: {
                cat?: number
                data: any
              }) => {
                toggle()
                getRestaurantItemList(selectedCategoryId)
                // setSelectedCategoryId(cat || 0)
              }}
            />
          )}

          {formType && formType === 'Add Restaurant' && (
            <AddRestaurant
              section={section}
              onAddRestaurntSuccess={(newRestaurnat: Restarant) => {
                toggle()
                getRestarantData()
              }}
            />
          )}
          {formType && formType === 'Edit Restaurant' && editRestaurant && (
            <EditRestaurant
              editRestaurant={editRestaurant}
              onEditRestaurantSuccess={() => {
                toggle()
                // getRestaurantItemList(selectedRestaurantId)
                getRestarantData()
                // setSelectedCategoryId(0)
              }}
            />
          )}
          {formType && formType === 'Add Category' && (
            <AddCategory
              restaurantList={restaurantList}
              selectedRestaurantId={selectedRestaurantId}
              section={section}
              onAddCategorySuccess={(newCategory: Category) => {
                toggle()
                getCategoryData()
              }}
            />
          )}

          {formType && formType === 'Edit Category' && editCategory && (
            <EditCategoryNew
              editCategory={editCategory}
              onEditCategorySuccess={() => {
                toggle()
                getRestaurantItemList(selectedCategoryId)
                getCategoryData()
                // setSelectedCategoryId(0)
              }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        bodyText={deleteMessage}
        onClose={(success) => {
          if (success) {
            if (deleteItemId) {
              handleDeleteItem(deleteItemId)
            } else if (deleteCatId) {
              handleDeleteCategory(selectedCategoryId)
            } else if (deleteRestuId) {
              handleDeleteRestaurant(deleteRestuId)
            }
          }
          setDeleteCatId(undefined)
          setDeleteItemId(undefined)
          setDeleteRestuId(undefined)
          setDeleteMessage(undefined)
        }}
      />
    </div>
  )
}

export default Restaurant

// import { Button, Card } from 'react-bootstrap'

// import FeatherIcon from 'feather-icons-react'

import qrCode from '../../assets/images/qrCodeNew.png'
import HotelcomLogoLong from '../../assets/images/HotelcomLogoLong.png'
import HotelcomWhiteLogo from '../../assets/images/HotelcomWhiteLogo.png'
import testQRCode from '../../assets/images/hotelcom-qr-code.png'
import { Button } from 'react-bootstrap'

const QRCode = () => {
  return (
    <div
      style={{
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        marginTop: '1rem',
        marginRight: '0.5rem',
        fontFamily: 'ArialRoundedMTBold',
        gap: '1rem',
        position: 'relative',
      }}
    >
      <a
        href={testQRCode}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        download
      >
        <Button>Print</Button>
      </a>
      {/* <div
        style={{
          background: 'black',
          minHeight: '800px',
          width: '300px',
          borderRadius: '200px',
          display: 'flex',
          gap: '100px',
          flexDirection: 'column',
          margin: 'auto',
        }}
        id="printableQR"
      >
        <div className='d-flex align-items-center justify-content-center'>
          <img
            src={HotelcomWhiteLogo}
            alt="HotelcomWhiteLogo"
            width={220}
            style={{
              marginTop: '40px',
            }}
          />
        </div>
        <div>
          <div style={{ textAlign: 'center', fontSize: '50px' }}>Hello</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={qrCode} alt="qrCode" width={230} />
          </div>
          <div style={{ textAlign: 'center', fontSize: '20px' }}>
            Room service
          </div>
        </div>
        <div
          style={{
            marginTop: '100px',
            marginBottom: '100px',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <img
            src={HotelcomLogoLong}
            alt="LogoSVG"
            width={150}
            style={{ background: 'white', marginRight: '35px' }}
          />
        </div>
      </div> */}

      <img
        src={testQRCode}
        style={{
          height: '85vh',
        }}
        alt=""
      />
    </div>
  )
}

export default QRCode

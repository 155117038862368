import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// layout constants
import { LayoutTypes } from '../constants/layout'
import * as layoutConstants from '../constants/layout'

// strore
//import { RootState } from '../redux/store';

// All layouts containers
import DefaultLayout from '../layouts/Default'
import VerticalLayout from '../layouts/Vertical'
// import DetachedLayout from "../layouts/Detached";
// import HorizontalLayout from "../layouts/Horizontal/";
//import TwoColumnLayout from '../layouts/TwoColumn/';

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from './index'
import SelectPlan from '../pages/auth/Register/SelectPlan'

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
  const layout = {
    layoutType: layoutConstants.LayoutTypes.LAYOUT_VERTICAL,
    layoutWidth: layoutConstants.LayoutWidth.LAYOUT_WIDTH_FLUID,
    menuPosition: layoutConstants.MenuPositions.MENU_POSITION_FIXED,
    leftSideBarType: layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarTheme: layoutConstants.SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    showSidebarUserInfo: false,
    topbarTheme: layoutConstants.TopbarTheme.TOPBAR_THEME_LIGHT,
    isOpenRightSideBar: false,
    showRightSidebar: false,
  }

  const getLayout = () => {
    let layoutCls = VerticalLayout

    switch (layout.layoutType) {
      //   case LayoutTypes.LAYOUT_HORIZONTAL:
      //     layoutCls = HorizontalLayout;
      //     break;
      //   case LayoutTypes.LAYOUT_DETACHED:
      //     layoutCls = DetachedLayout;
      //     break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout
        break
      // default:
      //     layoutCls = TwoColumnLayout;
      //     break;
    }
    return layoutCls
  }

  let Layout = getLayout()

  return (
    <BrowserRouter>
      <Switch>
        <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
          <DefaultLayout {...props} layout={layout}>
            <Switch>
              {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                )
              })}
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path="/select-plan">
          <SelectPlan />
        </Route>
        <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
          <Layout {...props}>
            <Switch>
              <>
                {authProtectedFlattenRoutes.map((route: any, index: number) => {
                  return (
                    !route.children && (
                      <route.route
                        key={index}
                        path={route.path}
                        roles={route.roles}
                        exact={route.exact}
                        component={route.component}
                      />
                    )
                  )
                })}
              </>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes

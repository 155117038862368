import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'
import { RegisterData } from '../../pages/auth/Register'
import { PaymentDetailsType } from '../../pages/auth/Register/PaymentDetails'

const api = new APICore()

let authUrl = config.AUTH_URL
let baseUrl = config.API_URL

// account
async function login(params: { email: string; password: string }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${authUrl}${config.LOGIN_URL}`
      let result: Promise<Response> = api.create(url, params)
      console.log(result)

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function signup(params: RegisterData) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${authUrl}/hotels`
      let result: Promise<Response> = api.create(url, params)
      console.log(result)

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

function logout() {
  const baseUrl = '/logout/'
  return api.create(`${baseUrl}`, {})
}

function forgotPassword(params: { email: string }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels/forgot/password`
      let result: Promise<Response> = api.create(url, params)
      console.log(result)

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateBankDetails(params: PaymentDetailsType) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${authUrl}/hotels/banc-details`
      let result: Promise<Response> = api.updatePatch(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { login, logout, signup, forgotPassword, updateBankDetails }

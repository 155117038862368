import {APICore} from './apiCore'
import config from '../../config'
import {Response} from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function updateWifiOrPolicy(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/wifi/or/policy`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function createResTime(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurants/times`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateResTime(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurants/times/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteResTime(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurants/times/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function createSpeTime(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/special/services`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateSpeTime(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/special/services/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteSpeTime(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/special/services/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  updateWifiOrPolicy,
  updateResTime,
  updateSpeTime,
  createResTime,
  createSpeTime,
  deleteResTime,
  deleteSpeTime
}

import { useMemo, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '..'
import { HotelDetailsType, WifiAndPolicyType } from '../../types/HotelDetails'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { updateWifiOrPolicy } from '../../helpers/api/hotelDetais'
import { toast } from 'react-toastify'
import TextEditor from './TextEditor'

export const wifiSchemaResolver = yupResolver(
  yup.object().shape({
    wifiName: yup.string().required('wifi name is required'),
    wifiPassword: yup.string().required('wifi password is required'),
    policy: yup.string().required('policy is required'),
  }),
)

type PropsType = {
  hotelDetails: HotelDetailsType
}
const WifiAndPolicy = (props: PropsType) => {
  const { hotelDetails } = props
  const [policy, setPolicy] = useState<string>(hotelDetails?.policy || '')

  const DEBOUNCE_DELAY = 500
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const methods = useForm<WifiAndPolicyType>({
    defaultValues: useMemo(() => {
      return hotelDetails
    }, [hotelDetails]),
    resolver: wifiSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  const handlePolicyChange = (newPolicy: string) => {
    if (newPolicy !== '') {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current) // Clear the previous timeout
      }

      debounceTimeoutRef.current = setTimeout(() => {
        // Execute the function after debounce delay
        setPolicy(newPolicy) // You may want to update the state here if needed
      }, DEBOUNCE_DELAY)
    }
  }

  const handleUpdateWifiSubmit = async (formData: WifiAndPolicyType) => {
    const { wifiName, wifiPassword } = formData
    const data = { wifiName, wifiPassword, policy }
    let res: Response = (await updateWifiOrPolicy(data)) as Response
    if (res.status === 200) {
      toast.success('Wifi details updated successfully')
    } else {
      console.log(res)
    }
  }

  return (
    <form
      name="hotel-details"
      id="hotel-details"
      onSubmit={handleSubmit(handleUpdateWifiSubmit)}
    >
      <div>
        <h4 className="header-title mb-1">Wi-Fi</h4>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={3}>
            Wi-Fi name
          </Form.Label>
          <Col>
            <FormInput
              type="string"
              name="wifiName"
              // placeholder=""
              register={register}
              key="wifiName"
              errors={errors}
              control={control}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={3}>
            Wi-Fi password
          </Form.Label>
          <Col>
            <FormInput
              type="password"
              name="wifiPassword"
              // placeholder=""
              register={register}
              key="wifiPassword"
              errors={errors}
              control={control}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={3}>
            Hotel policy
          </Form.Label>
          <Col>
            {/* <FormInput
              type="textarea"
              name="policy"
              placeholder="Enter hotel rules and regulations here..."
              register={register}
              key="policy"
              errors={errors}
              control={control}
              comp="textarea"
              style={{ height: '10rem' }}
            /> */}

            <TextEditor
              onChange={handlePolicyChange}
              value={hotelDetails?.policy}
            />
          </Col>
        </Form.Group>
      </div>
      <Button type="submit">Update</Button>
    </form>
  )
}

export default WifiAndPolicy

import { useState, useEffect } from 'react'

import { toast } from 'react-toastify'
import { Button, Card, Form, Offcanvas } from 'react-bootstrap'
import FeatherIcon from 'feather-icons-react'

import Table from '../../../components/Table'

import AddDevice from './AddDevice'
import EditDevice from './EditDevice'

import { deleteCategory, getCategoryList } from '../../../helpers/api/category'
import { Category } from '../../../types/Category'
import { Response } from '../../../constants/types'

import AddCategory from '../AddCategory'
import { DeviceItem } from '../../../types/Item'
import {
  addDevice,
  deleteItem,
  getAllItems,
  updateDevice,
} from '../../../helpers/api/deviceGuides'
import DeleteConfirmation from '../../../components/DeleteConfirmation'
import Loader from '../../../components/Loader'
import NoDataFound from '../../../components/NoDataFound'
import EditCategory from '../EditCategory'
import AddDeviceNew from '../AddDeviceNew'
import EditDeviceNew from '../EditDevice'

const section = 'device-guide'

const DeviceGuide = () => {
  const [visible, setVisible] = useState(false)
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
  const [formType, setFormType] = useState('Add Device')
  const [deviceItemList, setDeviceItemList] = useState<DeviceItem[]>([])
  const [currentDevice, setCurrentDevice] = useState<DeviceItem>()
  const [showDeleteConf, setShowDeleteConf] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [deleteCatId, setDeleteCatId] = useState<number>()
  const [editCategory, setEditCategory] = useState<Category>()
  const [isLoading, setIsLoading] = useState(false)

  const toggle = () => {
    setVisible((prev) => !prev)
  }

  const handleAddDeviceClick = () => {
    toggle()
    setFormType('Add Device')
  }

  const handleAddCategoryClick = () => {
    toggle()
    setFormType('Add Guide')
  }

  const getCategoryData = async () => {
    let res: Response = (await getCategoryList({
      section,
    })) as Response
    console.log('response of houseKeeping category list ---->', res)
    if (res?.data?.data?.length > 0) setCategoryList(res.data.data)
  }
  const hadleCategorySelect = (e: any) => {
    setSelectedCategoryId(parseInt(e.target.value))
  }

  const handleEditCategoryClick = () => {
    const editCat = categoryList.find((c) => c.id === selectedCategoryId)
    if (editCat) {
      setEditCategory(editCat)
      toggle()
      setFormType('Edit Device')
    }
  }

  const getDeviceList = async (categoryId?: number) => {
    setPageLoad(true)
    let res: Response = (await getAllItems({ categoryId })) as Response
    setDeviceItemList(res?.data?.data)
    setPageLoad(false)
  }

  const handleEditItemClick = (values: any) => {
    setCurrentDevice(values)
    toggle()
    setFormType('Edit Guide')
  }

  const handleDeleteItem = async (id: any) => {
    let res: Response = (await deleteItem(id)) as Response
    if (res.status === 200) {
      toast.success('Guide deleted successfully')
      setDeviceItemList((prev) => prev.filter((item) => item.id !== id))
    }
  }

  const columns = [
    // {
    //   Header: 'Device ID',
    //   accessor: 'id',
    // },
    {
      Header: '',
      accessor: 'categoryId',
      Cell: <></>,
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Video',
      accessor: 'video',
      Cell: ({ row }: any) => {
        return (
          <>
            <video
              controls
              width={400}
              height={200}
              style={
                {
                  // background: "red",
                }
              }
            >
              <source src={row?.values?.video} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content gap-2">
            <Button onClick={() => handleEditItemClick(row.original)}>
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const handleAddDeviceSubmit = async (formData: DeviceItem) => {
    try {
      setIsLoading(true)
      const data = {
        ...formData,
      }
      const video = formData.video?.[0]
      if (video) {
        data.video = video
      } else {
        delete data.video
      }
      let res: Response = (await addDevice(data)) as Response
      if (res.status === 200) {
        toast.success('Guide added successfully')

        // setSelectedCategoryId(formData?.categoryId || 0)
        toggle()
        getDeviceList(selectedCategoryId)
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditDeviceSubmit = async (formData: DeviceItem) => {
    try {
      setIsLoading(true)
      const data = {
        ...formData,
      }
      const video = formData.video?.[0]
      if (video) {
        data.video = video
      } else {
        delete data.video
      }
      if (currentDevice) {
        let res: Response = (await updateDevice(
          currentDevice.id,
          data,
        )) as Response
        if (res.status === 200) {
          toast.success('Guide edited successfully')
        } else {
          console.log(res)
        }
        toggle()
        getDeviceList(selectedCategoryId)
        setCurrentDevice(undefined)
        // setSelectedCategoryId(formData?.categoryId || 0)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteCategory = async (id: number) => {
    let res: Response = (await deleteCategory(id)) as Response
    if (res.status === 200) {
      toast.success('Device deleted successfully')
      setSelectedCategoryId(0)
      setCategoryList((prev) => prev.filter((cat) => cat.id !== id))
    }
  }

  useEffect(() => {
    getCategoryData()
    getDeviceList()
  }, [])

  useEffect(() => {
    getDeviceList(selectedCategoryId)
  }, [selectedCategoryId])

  return (
    <div className="d-flex flex-column gap-2">
      {pageLoad && <Loader />}
      <div className="d-flex gap-2 py-2 justify-content-between">
        <h3>{`Web App Setup > Device Guide`}</h3>
        <div className="d-inline-flex gap-2">
          <Button onClick={handleAddDeviceClick}>+ Add Device</Button>
          <Button onClick={handleAddCategoryClick}>+ Add Guide</Button>
        </div>
      </div>

      <div className="d-flex align-items-center mb-2 gap-2">
        <Form.Select
          className="w-25"
          size="lg"
          value={selectedCategoryId}
          onChange={hadleCategorySelect}
          style={{ minWidth: '185px' }}
        >
          <option value={0}>Select Device</option>
          {categoryList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </Form.Select>
        {selectedCategoryId ? (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteCatId(selectedCategoryId)
              }}
            >
              <FeatherIcon icon="trash-2" />
            </Button>
            <Button
              onClick={() => {
                handleEditCategoryClick()
              }}
            >
              <FeatherIcon icon="edit" />
            </Button>
          </>
        ) : null}
      </div>
      <div>
        <Card>
          <Card.Body>
            {deviceItemList?.length > 0 ? (
              <Table columns={columns} data={deviceItemList} pageSize={5} />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
      {/* <div className="d-flex justify-content-end gap-2">
        <Button>Back</Button>
        <Button>{`Save & next`}</Button>
      </div> */}

      <Offcanvas
        show={visible}
        onHide={toggle}
        placement={'end'}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">{formType}</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {formType && formType === 'Add Device' && (
            <AddDeviceNew
              section={section}
              onAddCategorySuccess={(newCategory: Category) => {
                toggle()
                getDeviceList(selectedCategoryId)
                setCategoryList((prev) => [...prev, newCategory])
              }}
            />
          )}

          {formType && formType === 'Edit Guide' && currentDevice && (
            <EditDevice
              isLoading={isLoading}
              categoryList={categoryList}
              currentDevice={currentDevice}
              onSubmit={handleEditDeviceSubmit}
            />
          )}

          {formType && formType === 'Add Guide' && (
            <AddDevice
              categoryList={categoryList}
              onSubmit={handleAddDeviceSubmit}
              isLoading={isLoading}
            />
          )}

          {formType && formType === 'Edit Device' && editCategory && (
            <EditDeviceNew
              editCategory={editCategory}
              onEditCategorySuccess={() => {
                toggle()
                getDeviceList(selectedCategoryId)
                getCategoryData()
                // setSelectedCategoryId(0)
              }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            if (deleteItemId) {
              handleDeleteItem(deleteItemId)
            } else if (deleteCatId) {
              handleDeleteCategory(selectedCategoryId)
            }
          }
          setDeleteCatId(undefined)
          setDeleteItemId(undefined)
        }}
      />
    </div>
  )
}

export default DeviceGuide

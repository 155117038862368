import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// import logo from '../../assets/images/logo.png'
import Loader from '../../components/Loader'
// import Table from '../../components/Table'
import { Response } from '../../constants/types'
import { User } from '../../App'
import { getInvoiceList } from '../../helpers/api/invoice'
import InvoiceTableDesign from './InvoiceTableDesign'
import { Button } from 'react-bootstrap'
import { getDateTime } from '../../utils/functions'
import { ProfileContext } from '../../Context'
import { useContext } from 'react'

type GuestInvoiceItem = {
  id?: number
  item?: string
  quantity?: number
  price: number | string
  subTotal: number
  SGST: number
  SGSTValue: number
  CGST: number
  CGSTValue: number
  IGST: number
  IGSTValue: number
  GSTWithoutITC: number
  GSTWithoutITCValue: number
  Vat: number
  vatValue: number
  compensationCess: number
  compensationCessValue: number
  serviceTax: number
  serviceTaxValue: number
}

type Invoice = {
  id: number
  createdAt: string
  guestInvoiceItem: GuestInvoiceItem[]
  itemTotal: number
  CGSTTotal: number
  SGSTTotal: number
  roomNo: string
  hotel: User
}

const columns = [
  { Header: 'Item', accessor: 'item' },
  { Header: 'Qty', accessor: 'quantity' },
  { Header: 'Price', accessor: 'price' },
  { Header: 'Amount', accessor: 'subTotal' },
]
const headers = [
  { Header: 'Item', accessor: 'item' },
  { Header: 'Qty', accessor: 'quantity' },
  { Header: 'Price', accessor: 'price' },
]

// const headers = ['Item', 'Qty', 'Price']

const getTotals = (data: any[], key: string) => {
  let total = 0
  // console.log(data, 'adf')
  data?.forEach((item) => {
    total += item[key]
  })
  return total
}

const currency = localStorage.getItem('CURRENCY')

const InvoiceDetail = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const [invoice, setInvoice] = useState<Invoice>()
  // const [itemList, setItemList] = useState<GuestInvoiceItem[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const invoiceData: any = history.location.state

  let taxHeader: any = {}
  let tableValue: any = {}

  // const customerObj: any = invoiceData?.invoice?.items[0]?.room?.guestDetail[0]
  const customerObj: any =
    invoiceData?.invoice?.items[0]?.guestDetails &&
    JSON.parse(invoiceData?.invoice?.items[0]?.guestDetails)

  const roomCheckOutDate = invoiceData?.invoice?.items[0]?.roomCheckOutDate

  // console.log(invoiceData?.invoice?.items[0])

  // console.log(
  //   invoiceData?.invoice?.items[0]?.guestDetails &&
  //     JSON.parse(invoiceData?.invoice?.items[0]?.guestDetails),
  // )'

  const { timeZone } = useContext(ProfileContext)

  const checkIdisExisorNot = (tax: any, orderInfo: any) => {
    const keyInfo = orderInfo?.split('_')
    const key = `${keyInfo[2]}_${keyInfo[3]}`

    if (key in taxHeader) {
      if (!(orderInfo in taxHeader[key])) {
        taxHeader[key] = {
          ...taxHeader[key],
          [orderInfo]: [
            ...headers?.map((ele) => {
              return {
                header: ele?.Header,
                accessor: ele?.accessor,
              }
            }),
          ],
        }
      }
    } else {
      taxHeader = {
        ...taxHeader,

        [key]: {
          [orderInfo]: [
            ...headers?.map((ele) => {
              return {
                header: ele?.Header,
                accessor: ele?.accessor,
              }
            }),
          ],
        },
      }
    }

    // console.log(tax, 'tax')

    // Add Tax
    tax?.map((el: any) => {
      // console.log(key, 'key')
      // console.log(orderInfo, 'order')
      // console.log(
      //   taxHeader[key][orderInfo].findIndex((el: any) => {
      //     // return el?.header === `${el} (%)`
      //     // console.log(el, 'ekelee')
      //   }) === -1,
      //   'condition',
      // )
      if (
        taxHeader[key][orderInfo].findIndex(
          (ele: any) => ele?.header === `${el} (%)`,
        ) === -1
      ) {
        // console.log(taxHeader[key][orderInfo], 'tax')
        taxHeader[key][orderInfo]?.push({
          // header: ele?.Header,
          header: `${el} (%)`,
          accessor: `${el}_value`,
        })
      }
    })

    // if (key in taxHeader) {
    //   if (orderInfo in taxHeader[key]) {
    //     if (
    //       taxHeader[key][orderInfo].findIndex(
    //         (ele: any) => ele.header === header,
    //       ) === -1
    //     ) {
    //       taxHeader[key][orderInfo] = [
    //         ...taxHeader[key][orderInfo],
    //         {
    //           header: header,
    //           accessor: `${tax}_value`,
    //         },
    //       ]
    //     }
    //   } else {
    //     taxHeader[key] = {
    //       ...taxHeader[key],
    //       [orderInfo]: [
    //         ...headers?.map((ele) => {
    //           return {
    //             header: ele?.Header,
    //             accessor: ele?.accessor,
    //           }
    //         }),
    //         ...tax?.map((ele: any) => {
    //           return {
    //             // header: ele?.Header,
    //             header: `${ele} (%)`,
    //             accessor: `${ele}_value`,
    //           }
    //         }),
    //       ],
    //     }
    //   }
    // } else {
    //   taxHeader = {
    //     ...taxHeader,

    //     [key]: {
    //       [orderInfo]: [
    //         ...headers?.map((ele) => {
    //           return {
    //             header: ele?.Header,
    //             accessor: ele?.accessor,
    //           }
    //         }),
    //         ...tax?.map((ele: any) => {
    //           return {
    //             // header: ele?.Header,
    //             header: `${ele} (%)`,
    //             accessor: `${ele}_value`,
    //           }
    //         }),
    //       ],
    //     },
    //   }
    // }

    // console.log(tax, parentId, paymentType, 'der')
    // console.log(taxHeader, 'header')
  }

  const tableItems = (item: any, taxes: any, orderInfo: any) => {
    const keyInfo = orderInfo?.split('_')
    const key = `${keyInfo[2]}_${keyInfo[3]}`
    // console.log(item, 'daf')
    item = {
      ...item,
      item: item?.houseKeepingItem?.name || item?.restaurantItem?.name,
      quantity: `X ${item?.quantity}`,
      price: `${currency} ${item?.price}`,
      ...taxes,
    }

    // console.log(item, 'itmm')
    if (key in tableValue) {
      if (orderInfo in tableValue[key]) {
        tableValue[key][orderInfo].push(item)
      } else {
        tableValue[key] = {
          ...tableValue[key],
          [orderInfo]: [item],
        }
      }
    } else {
      tableValue = {
        ...tableValue,

        [key]: {
          [orderInfo]: [item],
        },
      }
    }
  }

  // Segegate Restaurant & Service paid & unpaid wise
  invoiceData?.invoice.items?.map((ele: any) => {
    const taxes: any = JSON.parse(ele?.taxes || '[]')
    // console.log(taxes, 'tax')
    // console.log(ele, 'ele')
    let itemTaxes: any = {}
    let totalAmount: number = 0

    let key = `${ele?.parentId}_${ele?.isPaid}`

    if (ele?.restaurantItemId) {
      key += `_${ele?.restaurantItem?.category?.restaurent?.id}_${ele?.restaurantItem?.category?.restaurent?.name}`
    } else {
      key += `_${ele?.houseKeepingItem?.category?.serviceProvider?.id}_${ele?.houseKeepingItem?.category?.serviceProvider?.name}`
    }
    // console.log('taxes')
    // tableHeader(key)
    checkIdisExisorNot(
      taxes?.map((tax: any) => {
        itemTaxes[tax?.tax] = +tax?.percentage
        itemTaxes[`${tax?.tax}_amount`] = (
          (+ele?.price * +tax?.percentage) /
          100
        ).toFixed(2)
        itemTaxes[`${tax?.tax}_value`] = `${currency} ${
          itemTaxes[`${tax?.tax}_amount`]
        } (${+tax?.percentage}%)`
        // checkIdisExisorNot(tax?.tax, ele?.parentId, ele?.isPaid, ``)
        return tax?.tax
      }),
      key,
    )

    totalAmount = +ele?.itemAmount + +ele?.taxAmount
    itemTaxes['amount'] = `${currency} ${totalAmount}`
    itemTaxes['totalAmount'] = totalAmount

    tableItems(ele, itemTaxes, key)

    // if (ele?.restaurantItemId && ele?.isPaid) {
    //   paidRestaurantOrders.push(ele)
    // } else if (ele?.restaurantItemId && !ele?.isPaid) {
    //   unPaidRestaurantOrders.push(ele)
    // } else if (ele?.houseKeepingItemId && ele?.isPaid) {
    //   paidServiceOrders.push(ele)
    // } else if (ele?.houseKeepingItemId && !ele?.isPaid) {
    //   unPaidServiceOrders.push(ele)
    // }
  })

  // console.log(invoiceData?.invoice?.items[0]?.room?.guestDetail[0], 'header')
  // console.log(customerObj, 'items')

  Object.keys(taxHeader).map((ele) => {
    Object.keys(taxHeader[ele])?.map((data) =>
      taxHeader[ele][data].push({
        header: 'Amount',
        accessor: 'amount',
      }),
    )
  })
  // console.log(taxHeader, 'header')

  // console.log(tableValue, 'new header')
  // console.log(unpaidAmount, ' header')

  // console.log(paidRestaurantOrdersObj, 'paid res')
  // console.log(paidServiceOrdersObj, 'paid ser')
  // console.log(unPaidRestaurantOrdersObj, 'unpaid res')
  // console.log(unPaidServiceOrdersObj, 'unpaid ser')

  const printDocument = () => {
    const input = document.getElementById('invoice')
    if (input) {
      html2canvas(input).then((canvas: any) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        // pdf.
        const imgProps = pdf.getImageProperties(canvas)
        // const pdfWidth = pdf.internal.pageSize.getWidth()
        // let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        // if (window.screen.width < 700) {
        //   pdfHeight = pdf.internal.pageSize.getHeight()
        // }
        pdf.addImage(imgData, 'PNG', 0, 0, 3508, 2480)
        // pdf.output('dataurlnewwindow');
        pdf.save('download.pdf')
      })
    }
  }

  const getInvoiceData = async () => {
    let res: Response = (await getInvoiceList()) as Response
    // console.log('response of get invoice list ---->', res)

    setInvoice(res?.data?.data?.orders)
  }

  //   useEffect(() => {
  //     getInvoiceData()
  //   }, [getInvoiceData])
  useEffect(() => {
    getInvoiceData()
  }, [])

  return (
    <>
      {pageLoad ? (
        <Loader />
      ) : (
        <>
          <div className="py-2 btn-header bg-white">
            {/* <button
              className="waves-effect waves-light btn btn-primary"
              type="button"
              onClick={printDocument}
            >
              <i className="bi bi-printer-fill"></i> Print
            </button> */}
            {/* <ReactToPrint
              copyStyles={true}
              trigger={() => (
                <Button onClick={() => {}} className=" mx-3">
                  <i className="bi bi-printer-fill"></i> Download Invoice
                </Button>
              )}
              content={() => componentRef.current}
            /> */}
            <Button
              onClick={() => {
                window.print()
              }}
              className=" mx-3"
            >
              <i className="bi bi-printer-fill"></i> Download
            </Button>
          </div>
          <div ref={componentRef} id="invoice">
            {Object.keys(taxHeader).map((ele: any, index) => {
              const shopName = ele?.split('_')[1]
              let paidAmount: number = 0
              let unpaidAmount: number = 0

              // console.log(ele)
              Object.keys(tableValue[ele])?.map((data) => {
                const isPaid = data?.split('_')[1]
                if (isPaid === 'true') {
                  tableValue[ele][data]?.forEach((elee: any) => {
                    paidAmount += +elee?.itemAmount + +elee?.taxAmount
                  })
                } else {
                  tableValue[ele][data]?.forEach((elee: any) => {
                    unpaidAmount += +elee?.itemAmount + +elee?.taxAmount
                  })
                }
              })

              const orderValues: any = Object.values(tableValue[ele])

              const address =
                orderValues[0][0]?.houseKeepingItem?.category?.serviceProvider
                  ?.address ||
                orderValues[0][0]?.restaurantItem?.category?.restaurent?.address

              const invoiceDescription =
                orderValues[0][0]?.houseKeepingItem?.category?.serviceProvider
                  ?.invoiceDetails ||
                orderValues[0][0]?.restaurantItem?.category?.restaurent
                  ?.invoiceDetails

              return (
                <div className="printable-content" key={ele}>
                  <div className="card bg-white" id="invoiceCard">
                    <div className="card-body px-md-5 ">
                      <p className="text-center mt-4 fs-3 fw-bold">
                        {shopName}
                      </p>

                      <div className="d-flex justify-content-center">
                        <p
                          style={{ width: '500px' }}
                          className="text-center fs-5"
                        >
                          {address}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between mt-4">
                        <div style={{ width: '600px' }}>
                          <p className="fs-4 fw-bold">{invoice?.hotel?.name}</p>

                          <p style={{ margin: 0, padding: 0 }}>
                            Invoice description <span>{invoice?.id}</span>
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            {invoiceDescription}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div>Customer name : {customerObj?.name}</div>
                        <div>Customer email: {customerObj?.email}</div>
                        <div>Customer phone: {customerObj?.mobile}</div>
                        <div>
                          Checkout Time:&nbsp;
                          {getDateTime(
                            roomCheckOutDate,
                            timeZone,
                            'DD, MMM YYYY hh:mm A',
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start text-end mt-3">
                        <div
                          style={{ width: '400px' }}
                          className="flex flex-col gap-2"
                        >
                          <div className="d-flex justify-content-between  border-dark  fs-4">
                            <div className="px-1 my-2 fw-bolder dark-font">
                              Balance due amount
                            </div>
                            <div className="px-1 mr-1 my-2 fw-bolder">
                              {currency}&nbsp;{+unpaidAmount?.toFixed(2)}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between border-top  border-dark  fs-4">
                            <div className="px-1 my-2 dark-font fw-bold">
                              Grand Total
                            </div>
                            <div className="px-1 mr-1 my-2 fw-bold">
                              {currency}&nbsp;
                              {(+paidAmount + +unpaidAmount)?.toFixed(2)}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between  border-bottom border-dark  fs-4">
                            <div className="px-1 my-2 ">Paid amount</div>
                            <div className="px-1 mr-1 my-2 ">
                              {' '}
                              {currency}&nbsp;{paidAmount?.toFixed(2)}{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <div className="fs-3 dark-font fw-bold">
                          Order summary
                        </div>
                      </div>
                      <div>
                        {/* <Table columns={columns} data={itemList} /> */}
                        {
                          // @ts-ignore
                        }

                        <div className="my-4">
                          {/* <div className="d-flex justify-content-between">
                        <div className="fs-4 fw-bold">
                          Order #{tableValue[ele][0]?.parentId}
                        </div>
                        <div className="fs-4 fw-bold">
                          <div
                            style={{
                              border: '2px solid black',
                              borderRadius: '10%',
                              background: isPaid === 'true' ? '#000000' : '',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '4px 12px',
                                color: isPaid === 'true' ? '#FFFFFF' : '',
                              }}
                            >
                              {isPaid === 'true' && (
                                <i
                                  className="bi bi-check-circle-fill "
                                  style={{ marginRight: '6px' }}
                                />
                              )}
                              {isPaid === 'true' ? 'Paid' : 'Not Paid'}
                            </p>
                          </div>
                        </div>
                      </div> */}
                          {Object.keys(taxHeader[ele])?.map((data, i) => {
                            // console.log(data, 'data')
                            return (
                              <div
                                className="invoice-wrapper"
                                key={`invoice_${i}`}
                              >
                                <InvoiceTableDesign
                                  taxHeader={taxHeader}
                                  tableValue={tableValue}
                                  ele={ele}
                                  data={data}
                                  // key = {}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

export default InvoiceDetail

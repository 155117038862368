import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllFeebacks(id: number, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.GUEST_FEEDBACK_HOTELS}/${id}`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addFeedback(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELIERS_FEEDBACKS}`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getAllFeebacks, addFeedback }

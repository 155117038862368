import { Button, Col, Row } from 'react-bootstrap'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// images
// import SmallOffice from "../../assets/images/smallOfficeDesc.png";

import { FormInput, VerticalForm } from '../../../components'

// types
import { Category } from '../../../types/Category'
import { DeviceItem } from '../../../types/Item'
import Loader from '../../../components/Loader'

export const deviceItemSchemaResolver = yupResolver(
  yup.object().shape({
    video: yup.mixed().required('File is required'),
    description: yup.string(),
    categoryId: yup.number().required('Please select device'),
  }),
)

type propsType = {
  categoryList: Category[]
  currentDevice: DeviceItem
  onSubmit: any
  isLoading: Boolean
}

const AddDevice = (props: propsType) => {
  const { categoryList, currentDevice, onSubmit, isLoading } = props
  const { description, categoryId } = currentDevice

  return (
    <Row>
      <Col>
        <VerticalForm<DeviceItem>
          onSubmit={(v) => {
            onSubmit(v)
          }}
          resolver={deviceItemSchemaResolver}
          defaultValues={{ description, categoryId }}
        >
          <FormInput
            label={'Select Device'}
            type="select"
            name="categoryId"
            placeholder=""
            containerClass={'mb-3'}
          >
            {categoryList?.map((cat) => (
              <option value={cat?.id} key={cat?.id}>
                {cat?.name}
              </option>
            ))}
          </FormInput>
          <FormInput
            label={'Guide description'}
            type="string"
            name="description"
            placeholder="Enter guide description"
            containerClass={'mb-3'}
          />
          <FormInput
            label={'Video'}
            type="file"
            name="video"
            containerClass={'mb-3'}
            accept="video/*"
          />
          {/* <div>
            <p>
              <b>Upload video</b>
            </p>
            <p>video should be less than 350MB</p>
            <div>
              <div className="">
                <input
                  type="file"
                  className="form-control d-none"
                  id="video"
                  name="video"
                />
                <label htmlFor="video">
                  <div
                    style={{
                      border: '1px solid gray',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    <i className="bi bi-upload" style={{fontSize: '20px'}}></i>
                  </div>
                </label>
              </div>
            </div>
          </div> */}

          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              {isLoading ? <Loader /> : 'Edit Guide'}
            </Button>
          </div>
        </VerticalForm>
      </Col>
      {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
    </Row>
  )
}

export default AddDevice

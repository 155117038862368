import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getCategoryList(params: { section?: string }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.CATEGORIES}`
      // console.log(params)

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function CreateNewCategory(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.CATEGORIES}`
      console.log(body)

      let result: Promise<Response> = api.createWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateCategory(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/categories/id/${id}`
      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteCategory(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/categories/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getCategoryList, CreateNewCategory, updateCategory, deleteCategory }

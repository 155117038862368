import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllItems(params: {
  categoryId?: number
  restaurentId?: number
}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS_ITEMS}`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addItem(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS_ITEMS}`
      // console.log(body)

      let result: Promise<Response> = api.createWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateItem(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS_ITEMS}/id/${id}`

      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteItem(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS_ITEMS}/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getItemById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS_ITEMS}/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getAllItems, addItem, updateItem, deleteItem, getItemById }

import moment from 'moment'

export function capitalize(str: string) {
  return str?.charAt(0).toUpperCase() + str?.slice(1)
}

export const getDateTime = (value: any, offSet: string, format: string) => {
  if (offSet) {
    return moment.utc(value).utcOffset(offSet?.split('UTC')[1]).format(format)
  }

  // return moment(value).format('DD/MM/YYYY hh:mm:ss A')
}

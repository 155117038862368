import { useCallback, useContext, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Select from 'react-select'

import Table from '../../components/Table'
import { Response } from '../../constants/types'
import Loader from '../../components/Loader'
import { ProfileContext } from '../../Context'
import NoDataFound from '../../components/NoDataFound'

import DateRange from '../../components/DateRange'
import { getAllOrders } from '../../helpers/api/orders'
import { OrderType } from '../../types/Order'

import placeholderImage from '../../assets/images/placeholderImage.png'
import { toast } from 'react-toastify'
import { capitalize, getDateTime } from '../../utils/functions'
import moment from 'moment'
import { arch } from 'os'

const statusList = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'Accepted',
    label: 'Accepted',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
  {
    value: 'Confirmed',
    label: 'Confirmed',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
]

const deptList = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'Housekeeping',
    label: 'Housekeeping',
  },
  {
    value: 'Restaurant',
    label: 'Restaurant',
  },
]

const Orders = () => {
  const { user, timeZone } = useContext(ProfileContext)

  const [orderList, setOrderList] = useState<OrderType[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)

  const [status, setStatus] = useState<any>()
  const [dept, setDept] = useState<any>()

  const getOrderList = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllOrders(user.id, params)) as Response
        setOrderList(res?.data?.data)
      } catch (error: any) {
        toast.error(capitalize(error.errMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )

  const orderTime = (value: any) => {
    const { status } = value

    //     console.log(value)

    if (status === 'Completed') {
      return value?.completedAt
    } else if (status === 'Pending') {
      return value?.createdAt
    } else if (status === 'Accepted') {
      return value?.acceptedAt
    } else if (status === 'Rejected') {
      return value?.rejectedAt
    }
    // console.log(status)
  }

  const columns = [
    // {
    //   Header: 'ID',
    //   accessor: 'id',
    // },
    {
      Header: 'Item Name',
      accessor: 'restaurantItem',
      Cell: ({ value, row }: any) => {
        let name, image
        if (value) {
          name = value.name
          image = value.image
        } else {
          const { houseKeepingItem } = row.original
          name = houseKeepingItem.name
          image = houseKeepingItem.image
        }
        return (
          <div className="d-flex justify-content-center align-items-center flex-nowrap">
            <div className="w-50 text-start text-nowrap">
              <img
                src={image || placeholderImage}
                className="rounded-circle bg-secondary"
                alt=""
                style={{
                  height: '40px',
                  width: '40px',
                }}
              />
              <span className="ms-2" style={{ textAlign: 'left' }}>
                {name}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }: any) => {
        const { createdAt } = row.original
        // return <span>{moment(createdAt).local().format('')}</span>
        return <span>{getDateTime(createdAt, timeZone, 'MMM DD, yyyy')}</span>
      },
    },
    {
      Header: 'Time',
      accessor: '',
      Cell: ({ row }: any) => {
        // console.log(row.original, 'dat')
        const time = orderTime(row.original)
        return <span>{getDateTime(time, timeZone, 'hh:mm A')}</span>
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      // Cell: ({ row }: any) => {
      //   const { price, CGSTValue, SGSTValue } = row.original
      //   return <span>{price + CGSTValue + SGSTValue}</span>
      // },
    },
    {
      Header: 'Room No.',
      accessor: 'room.roomNo',
    },
    // {
    //   Header: 'Trasnsaction ID',
    //   accessor: ''
    // }
  ]

  useEffect(() => {
    if (user.id) getOrderList()
  }, [getOrderList, user.id])

  useEffect(() => {
    let params = { status: '', department: '' }
    if (status?.value) params.status = status.value
    if (dept?.value) params.department = dept.value
    getOrderList(params)
  }, [dept, getOrderList, status])

  return (
    <>
      {pageLoad && <Loader />}
      <DateRange onApply={getOrderList} />
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>{`Orders`}</h3>
        </div>
        <div className="d-flex gap-2">
          <Select
            placeholder="Filter by Status"
            className="react-select react-select-container w-25"
            classNamePrefix="react-select"
            value={status}
            onChange={(option) => setStatus(option)}
            options={statusList}
          />
          <Select
            placeholder="Filter by Department"
            className="react-select react-select-container w-25"
            classNamePrefix="react-select"
            value={dept}
            onChange={(option) => setDept(option)}
            options={deptList}
          />
        </div>
        <div>
          <Card>
            <Card.Body>
              {orderList?.length > 0 ? (
                <Table columns={columns} data={orderList} pagination={true} />
              ) : (
                <NoDataFound />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Orders

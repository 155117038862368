import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Response } from '../../constants/types'
import { toast } from 'react-toastify'
import Spinners from '../../components/Spinner'
import { CreateNewRestarant } from '../../helpers/api/restaurant'
import moment from 'moment'

type propsType = {
  section: string
  onAddRestaurntSuccess: any
}

interface FormValues {
  name: string
  desciption: string
  address: string
  openTime: string
  closeTime: string
  image?: string | null
  isVeg?: any
}

const AddRestaurant = (props: propsType) => {
  const { section, onAddRestaurntSuccess } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<object>({})

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter restaurant name'),
      desciption: yup.string().required('Please enter desciption name'),
      address: yup.string().required('Please enter address name'),
      image: yup.mixed(),
    }),
  )

  const methods = useForm<FormValues>({ resolver: schemaResolver })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods

  const handlesubmit = async (formData: FormValues) => {
    try {
      setLoading(true)
      const data = {
        ...formData,
        openTime: moment(formData.openTime, 'HH:mm').utc().format('HH:mm:ss'),
        closeTime: moment(formData.closeTime, 'HH:mm').utc().format('HH:mm:ss'),
        isVeg: formData?.isVeg ? 1 : 0,
      }
      const image = formData.image?.[0]
      if (image) {
        data.image = image
      } else {
        delete data.image
      }
      // console.log(data)
      let res: Response = (await CreateNewRestarant(data)) as Response
      reset()
      if (res.status === 200) {
        onAddRestaurntSuccess(res.data?.data)
        toast.success('Restaurant added successfully')
      }
      // console.log('response of add restaurant ---->', res)
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(handlesubmit)}>
      <Form.Group className="">
        <Form.Label column htmlFor="mobile" className="mt-2">
          Name
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="name"
            className="form-control chat-input"
            placeholder="Enter restaurant name"
            register={register}
            key="name"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Desciption
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="desciption"
            className="form-control chat-input"
            placeholder="Enter restaurant desciption"
            register={register}
            key="desciption"
            errors={errors}
            control={control}
          />
        </Col>
        {/* <Form.Label column htmlFor="mobile" className="mt-2">
          Veg
        </Form.Label> */}
        <Col className="mt-3" lg={10}>
          <Row>
            <Col md={3}>
              <FormInput
                type="switch"
                label="Veg"
                name="isVeg"
                className=""
                placeholder="Enter restaurant isVeg"
                register={register}
                key="isVeg"
                errors={errors}
                control={control}
              />
            </Col>
          </Row>
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Address
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="address"
            className="form-control chat-input"
            placeholder="Enter restaurant address"
            register={register}
            key="address"
            errors={errors}
            control={control}
          />
        </Col>
        <Row>
          <Col>
            <Form.Label column htmlFor="mobile" className="mt-2">
              Open Time
            </Form.Label>
            <FormInput
              type="time"
              name="openTime"
              className="form-control chat-input"
              placeholder="Enter restaurant openTime"
              register={register}
              key="openTime"
              errors={errors}
              control={control}
            />
          </Col>
          <Col>
            <Form.Label column htmlFor="mobile" className="mt-2">
              Close Time
            </Form.Label>
            <FormInput
              type="time"
              name="closeTime"
              className="form-control chat-input"
              placeholder="Enter restaurant closeTime"
              register={register}
              key="closeTime"
              errors={errors}
              control={control}
            />
          </Col>
        </Row>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Invoice Details
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="invoiceDetails"
            className="form-control chat-input"
            placeholder="Enter restaurant invoice details"
            register={register}
            key="invoiceDetails"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Image
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="file"
            name="image"
            className="form-control chat-input"
            placeholder="Enter restaurant image"
            register={register}
            key="image"
            errors={errors}
            control={control}
          />
          <div
            style={{
              marginTop: '4px',
              marginLeft: '4px',
              fontWeight: '700',
              fontSize: '12px',
              // marginBottom: '8px',
            }}
          >
            (Image ratio must be 3:1)
          </div>
        </Col>
      </Form.Group>
      {loading ? (
        <Spinners classname="px-3" />
      ) : (
        <Button variant="primary" type="submit" className="mt-3 px-2">
          Add Restaurant
        </Button>
      )}
    </Form>
  )
}

export default AddRestaurant

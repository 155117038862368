import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button, Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap'
// import { GetSingleSubscriptionById } from '../../helpers/api/superAdmin'
import Loader from '../../components/Loader'
import moment from 'moment'
import {
  cancleSubscriptionById,
  getSubscriptionById,
} from '../../helpers/api/payment'
import { toast } from 'react-toastify'
import { capitalize } from '../../utils/functions'
import {
  getInvoiceList,
  getInvoicesOfSubscription,
} from '../../helpers/api/invoice'
import Table from '../../components/Table'
import Invoices from '../invoices'

const NewPlan = () => {
  const history = useHistory()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [subscription, setSubscription] = useState<any[any]>([])
  const [invoiceDetails, setInvoiceDetails] = useState<any>({})
  const { subId } = useParams<{ subId: string }>()
  const [modalShow, setModalShow] = useState(false)

  const handleClose = () => {
    setModalShow(false)
  }

  const handleDeleteClick = (id: number) => {
    setModalShow(true)
  }

  // const getSubscription = async () => {
  //   setPageLoad(true)
  //   let res: Response = (await GetSingleSubscriptionById(subId, {})) as Response

  //   console.log('Get all subs response', res?.data?.data);
  //   setSubscription(res?.data?.data)
  //   setPageLoad(false)
  // }

  const columnsInvoiceTypes: any = [
    {
      Header: 'Invoice No.',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Date',
      accessor: 'issued_at',
      Cell: ({ row }: any) => {
        const issued_at = row.original?.issued_at
        return <div>{moment(issued_at).local().format('MMM DD, yyyy')}</div>
      },
    },

    {
      Header: 'Action',
      accessor: 'edit',
      Cell: ({ row, value }: any) => {
        console.log('value', row?.original)

        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={() => {
                console.log('qwerty', {
                  ...invoiceDetails,
                  invoice: row?.original,
                })

                history.push(`/print-invoice/subscriptions/${subId}`, {
                  ...invoiceDetails,
                  invoice: row?.original,
                })
              }}
            >
              View
            </Button>
            {/* {(row?.original?.status == 'active' ||
              row?.original?.status == 'authenticated') && (
                <Button
                  className="btn-danger"
                  onClick={() => {
                    const subscription = row?.original
                    cancleSubscription(subscription.subscriptionId)
                  }}
                >
                  Cancle
                </Button>
              )} */}
          </div>
        )
      },
    },
    // {
    //   Header: 'Link',
    //   accessor: 'invoiceLink',
    //   Cell: ({ row }: { row: any }) => {
    //     return (
    //       <a href={row.original.invoiceLink} target="_blank">
    //         {row.original.invoiceLink}
    //       </a>
    //     )
    //   },
    // },
  ]

  const getSubscription = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response | any = (await getSubscriptionById(
          subId,
          params,
        )) as Response
        const subscribeData: any = res?.data?.data
        if (subscribeData) {
          setSubscription(subscribeData)
        }
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [subId],
  )

  const cancleSubscription = useCallback(async (id?: any, params?: any) => {
    try {
      setPageLoad(true)
      let res: Response | any = (await cancleSubscriptionById(
        id,
        {},
      )) as Response
      if (res?.data?.status == 200) {
        toast.success(capitalize(res?.data?.message))
      }
    } catch (error: any) {
      toast.error(capitalize(error.errorMessage))
    } finally {
      setPageLoad(false)
      history.push('/plans')
    }
  }, [])

  const getInvoices = async () => {
    let res: Response | any = (await getInvoicesOfSubscription(
      subId,
      {},
    )) as Response

    console.log('1111', res?.data)

    if (res) {
      setInvoiceDetails(res?.data)
    }
  }

  useEffect(() => {
    getSubscription()
    getInvoices()
  }, [])

  console.log('invoiceData', invoiceDetails)
  // console.log('invoiceData', invoiceDetails?.invoiceData);

  return (
    <>
      {pageLoad && <Loader />}
      <Row className="mt-4">
        <Col>
          <Card className="">
            <Card.Body>
              <div className="">
                <p className="my-1 mt-0" style={{ fontSize: '15px' }}>
                  <span className="fw-medium  my-1">Subscription ID : </span>{' '}
                  {subscription?.id}
                </p>
                <hr />
                <Row style={{ marginLeft: '1rem ' }}>
                  <Col>
                    <p className="my-1 mt-0" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Plan Details : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Hotel Name : </span>{' '}
                      {subscription?.hotel?.name}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Plan ID : </span>{' '}
                      {subscription?.plan_id}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Plan Name : </span>{' '}
                      {subscription?.planName}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Status : </span>{' '}
                      {subscription?.status}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">
                        Charge/Month/Room :{' '}
                      </span>{' '}
                      {subscription?.chargePrRoom}
                    </p>
                    <p className="my-1 mt-3" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Payment Details : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Payment Method : </span>{' '}
                      {subscription?.payment_method}
                    </p>
                    <p className="my-1 mb-0" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Paid Payments : </span>
                      {subscription?.paid_count}
                    </p>
                    {/* <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Total Payments : </span>{' '}
                      {subscription?.total_count}
                    </p> */}
                  </Col>
                  <Col>
                    <p className="my-1 mt-0" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Important Dates : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Join Date : </span>{' '}
                      {moment(subscription?.createdAt).local().format('DD/MM/yyyy')}
                    </p>
                    {/* <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Start Date : </span>{' '}
                      {moment(subscription?.startDate).local().format('DD/MM/yyyy')}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Expiry Date : </span>{' '}
                      {new Date(subscription?.expiryDate).toLocaleDateString()}
                    </p> */}
                    {subscription?.lastPaidOn && (
                      <p className="my-1" style={{ fontSize: '12px' }}>
                        <span className="fw-medium my-1"> Last Paid On : </span>{' '}
                        {moment(subscription?.lastPaidOn).local().format('DD/MM/yyyy')}
                      </p>
                    )}
                    {subscription?.nextPayOn && (
                      <p className="my-1" style={{ fontSize: '12px' }}>
                        <span className="fw-medium my-1"> Next Pay On : </span>{' '}
                        {moment(subscription?.nextPayOn).local().format('DD/MM/yyyy')}
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
              {(subscription.status == 'active' ||
                subscription.status == 'authenticated') && (
                  <Button
                    className="btn-danger mt-3"
                    style={{ marginLeft: '2rem ' }}
                    // onClick={() => cancleSubscription(subscription?.id)}
                    // @ts-ignore
                    onClick={handleDeleteClick}
                  >
                    Cancel
                  </Button>
                )}
              {/* <Button
                className="btn-primary mt-3"
                style={{ marginLeft: '1rem ' }}
                onClick={() =>
                  getInvoices()
                }
              >
                Invoices
              </Button> */}
              {/* <p className="my-1" style={{ fontSize: "12px" }}><span className="fw-medium my-1">Expiry Date : </span> {new Date(subscription?.lastPaidOn).toLocaleDateString()}</p> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <Table
            columns={columnsInvoiceTypes}
            data={invoiceDetails?.invoiceData || []}
            pagination={true}
          />
        </Card.Body>
      </Card>

      {/* {invoiceLinks && invoiceLinks.length > 0 && ( */}
      {/* <Card>
        <Card.Body>
          <Table
            columns={columnsInvoiceTypes}
            data={invoiceDetails?.invoiceData}
            pagination={true}
          />
        </Card.Body>
      </Card> */}
      {/* )} */}

      <Modal
        centered
        show={modalShow}
        onHide={handleClose}
        contentClassName={'p-4'}
      >
        <p>Are you sure you want to cancle this subscription?</p>
        <div className="d-flex">
          <button
            onClick={handleClose}
            className="btn-outline-white p-1 px-3 me-3 border-0 rounded-3"
          >
            No
          </button>
          <button
            onClick={() => cancleSubscription(subId)}
            className="btn-primary p-1 px-3 border-0 rounded-3"
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  )
}

export default NewPlan

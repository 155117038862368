import { useState } from 'react'

import { Button, Form } from 'react-bootstrap'

type propsType = {
  onSubmit: any
  currentFloors: number
}

const EditFloors = (props: propsType) => {
  const { onSubmit, currentFloors } = props
  const [floors, setFloors] = useState(currentFloors)
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(floors)
        }}
        className="d-flex flex-column gap-4"
      >
        <div>
          <p>Number of floors</p>
          <div className="d-flex justify-content-center gap-2">
            <Button
              variant="light"
              onClick={() => {
                if (floors > 0) {
                  setFloors((prev) => prev - 1)
                }
              }}
            >
              -
            </Button>

            <Form.Control
              type="number"
              min={1}
              name="floors"
              value={floors}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0)
                  setFloors(parseInt(e.target.value))
              }}
              style={{
                maxWidth: '80px',
              }}
            />
            <Button
              variant="light"
              onClick={() => setFloors((prev) => prev + 1)}
            >
              +
            </Button>
          </div>
        </div>
        <div>
          <Button type="submit">Update Floors</Button>
        </div>
      </Form>
    </div>
  )
}

export default EditFloors

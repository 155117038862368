import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'

// components
import Loader from '../../../components/Loader'
import NoDataFound from '../../../components/NoDataFound'
import Table from '../../../components/Table'

// types
import { Response } from '../../../constants/types'

// api
import { getAllPayment } from '../../../helpers/api/payment'

const columns = [
  // {
  //   Header: 'Room number',
  //   accessor: 'roomId',
  // },
  {
    Header: 'Payment pending',
    accessor: 'amount',
  },
]

const Payment = () => {
  const [paymentList, setPaymentList] = useState([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)

  const getPaymentList = async () => {
    setPageLoad(true)
    let res: Response = (await getAllPayment()) as Response
    setPaymentList(res?.data?.results)
    setPageLoad(false)
  }

  useEffect(() => {
    getPaymentList()
  }, [])

  return (
    <div className="p-3">
      {pageLoad && <Loader />}
      <div className="d-flex gap-3 align-items-center">
        {/* <i className="bi bi-arrow-left" style={{fontSize: '20px'}}></i> */}
        <span>{`Dashboard > Cash deposits`}</span>
      </div>
      <div className="pt-2">
        <Card>
          <Card.Body>
            {paymentList?.length > 0 ? (
              <Table columns={columns} data={paymentList} />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Payment

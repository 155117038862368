import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateNewCategory } from '../../helpers/api/category'
import { Response } from '../../constants/types'
import { toast } from 'react-toastify'
import Spinners from '../../components/Spinner'
import { Restarant } from '../../types/Restaurant'

type propsType = {
  section: string
  onAddCategorySuccess: any
  dataList: Restarant[]
  selectedId?: number
}

interface FormValues {
  name: string
  section: string
  serviceProviderId?: number
  image?: string | null
}

const AddCleaningCategory = (props: propsType) => {
  const { section, onAddCategorySuccess, dataList, selectedId } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<object>({})

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter category name'),
      serviceProviderId: yup.number().required('Please select restaurant'),
      image: yup.mixed(),
    }),
  )

  const methods = useForm<FormValues>({
    resolver: schemaResolver,
    defaultValues: { serviceProviderId: selectedId },
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods

  const onSubmit = async (formData: FormValues) => {
    try {
      setLoading(true)
      const data = {
        ...formData,
        section,
      }
      const image = formData.image?.[0]
      if (image) {
        data.image = image
      } else {
        delete data.image
      }
      console.log(data)
      // if (image) {
      // } else {
      //   payload.image = null
      // }

      let res: Response = (await CreateNewCategory(data)) as Response
      reset()
      if (res.status === 200) {
        onAddCategorySuccess(res.data?.data)
        toast.success('Category added successfully')
      }
      console.log('response of add category ---->', res)
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-2">
        <Form.Label>Select Service Provider</Form.Label>
        <Form.Select
          {...register('serviceProviderId')}
          name="serviceProviderId"
          id="serviceProviderId"
          isInvalid={errors && errors['serviceProviderId'] ? true : false}
        >
          {dataList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </Form.Select>
        {errors && errors['serviceProviderId'] ? (
          <Form.Control.Feedback type="invalid">
            {errors['serviceProviderId']['message']}
          </Form.Control.Feedback>
        ) : null}
        <Form.Label column htmlFor="mobile" className="mt-2">
          Category Name
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="name"
            className="form-control chat-input"
            placeholder="Enter category name"
            register={register}
            key="name"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Category Image
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="file"
            name="image"
            className="form-control chat-input"
            placeholder=""
            register={register}
            key="image"
            errors={errors}
            control={control}
          />
          <div
            style={{
              marginTop: '4px',
              marginLeft: '4px',
              fontWeight: '700',
              fontSize: '12px',
              // marginBottom: '8px',
            }}
          >
            (Image ratio must be 1:1)
          </div>
        </Col>
      </Form.Group>
      {loading ? (
        <Spinners classname="px-3" />
      ) : (
        <Button variant="primary" type="submit" className="mt-3 px-2">
          Add Category
        </Button>
      )}
    </Form>
  )
}

export default AddCleaningCategory

import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'

import FeatherIcon from 'feather-icons-react'

import { FieldErrors, Control } from 'react-hook-form'
import { deleteTax } from '../helpers/api/taxes'
import { toast } from 'react-toastify'
import DeleteConfirmation from './DeleteConfirmation'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  startIcon?: any
  label?: string
  type?: string
  name: string
  comp?: string
  placeholder?: string
  register?: any
  errors?: any
  control?: Control<any>
  className?: string
  labelClassName?: string
  containerClass?: string
  textClassName?: string
  refCallback?: any
  action?: any
  allTaxes?: any
  setRefresh?: any
  editTime?: any
  idValue?: string
  rows?: string | number
}

// textual form-controls—like inputs, passwords, textareas etc.
const TextualInput = ({
  type,
  name,
  placeholder,
  register,
  errors,
  comp,
  rows,
  className,
  refCallback,
  idValue,
  ...otherProps
}: FormInputProps) => {
  const [typ, setTyp] = useState(type)
  return (
    <InputGroup>
      <Form.Control
        type={typ}
        placeholder={placeholder}
        name={name}
        as={comp}
        id={name}
        idval={idValue}
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r)
        }}
        className={className}
        isInvalid={errors && errors[name] ? true : false}
        {...(register ? register(name) : {})}
        rows={rows}
        {...otherProps}
      ></Form.Control>

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors[name]['message']}
        </Form.Control.Feedback>
      ) : (
        type === 'password' && (
          <InputGroup.Text
            style={{ background: 'transparent', cursor: 'pointer' }}
          >
            <FeatherIcon
              icon={`${typ === 'password' ? 'eye' : 'eye-off'}`}
              onClick={() => {
                setTyp((typ) => (typ === 'password' ? 'text' : 'password'))
              }}
            />
          </InputGroup.Text>
        )
      )}
    </InputGroup>
  )
}

// non-textual checkbox and radio controls
const CheckInput = ({
  type,
  label,
  name,
  placeholder,
  register,
  errors,
  comp,
  rows,
  className,
  refCallback,
  ...otherProps
}: FormInputProps) => {
  return (
    <>
      <Form.Check
        type={type}
        label={label}
        name={name}
        id={name}
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r)
        }}
        className={className}
        isInvalid={errors && errors[name] ? true : false}
        {...(register ? register(name) : {})}
        {...otherProps}
        onChange={(e: any) => {
          // console.log(e.target.value)
        }}
      />

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid">
          {errors[name]['message']}
        </Form.Control.Feedback>
      ) : null}
    </>
  )
}

// handle select controls
const SelectInput = ({
  type,
  label,
  name,
  placeholder,
  register,
  errors,
  comp,
  rows,
  className,
  refCallback,
  ...otherProps
}: FormInputProps) => {
  return (
    <>
      <Form.Select
        type={type}
        label={label}
        name={name}
        id={name}
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r)
        }}
        className={className}
        isInvalid={errors && errors[name] ? true : false}
        {...(register ? register(name) : {})}
        {...otherProps}
      />

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid">
          {errors[name]['message']}
        </Form.Control.Feedback>
      ) : null}
    </>
  )
}

// extend textual form-controls with add-ons
const FormInputGroup = ({
  startIcon,
  type,
  name,
  placeholder,
  comp,
  register,
  errors,
  rows,
  className,
  textClassName,
  refCallback,
  ...otherProps
}: FormInputProps) => {
  const [typ, setTyp] = useState(type)
  return (
    <InputGroup>
      <InputGroup.Text className={textClassName}>{startIcon}</InputGroup.Text>
      {type === 'select' ? (
        <SelectInput
          type={type}
          name={name}
          placeholder={placeholder}
          refCallback={refCallback}
          comp={comp}
          errors={errors}
          register={register}
          className={className}
          rows={rows}
          {...otherProps}
        />
      ) : (
        <>
          <Form.Control
            type={typ}
            placeholder={placeholder}
            name={name}
            as={comp}
            id={name}
            ref={(r: HTMLInputElement) => {
              if (refCallback) refCallback(r)
            }}
            className={className}
            isInvalid={errors && errors[name] ? true : false}
            {...(register ? register(name) : {})}
            rows={rows}
            {...otherProps}
          ></Form.Control>

          {errors && errors[name] && errors?.[name]?.['message'] ? (
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors?.[name]?.['message']}
            </Form.Control.Feedback>
          ) : (
            type === 'password' && (
              <InputGroup.Text
                style={{ background: 'transparent', cursor: 'pointer' }}
              >
                <FeatherIcon
                  icon={`${typ === 'password' ? 'eye' : 'eye-off'}`}
                  onClick={() => {
                    setTyp((typ) => (typ === 'password' ? 'text' : 'password'))
                  }}
                />
              </InputGroup.Text>
            )
          )}
        </>
      )}
    </InputGroup>
  )
}

const FormInput = ({
  startIcon,
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  control,
  className,
  labelClassName,
  containerClass,
  textClassName,
  refCallback,
  action,
  rows,
  allTaxes = [],
  editTime = false,
  setRefresh,
  ...otherProps
}: FormInputProps) => {
  // handle input type
  const comp =
    type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input'

  const [taxFields, setTaxFields] = useState([''])
  const [taxValues, setTaxValues] = useState([
    {
      id: '',
      name: '',
      percentage: '',
    },
  ])
  const [showDeleteConf, setShowDeleteConf] = useState(false)
  const [deleteTaxId, setDeleteTaxId] = useState({
    id: '',
    index: -1,
  })

  // console.log(taxFields, 'tax')
  // console.log(taxValues, 'value')
  // console.log(taxValues, 'taxvalue')
  // console.log(taxValues, 'taxvalue')

  const addTaxFields = () => {
    const newField = [...taxFields, '']
    setTaxFields(newField)
  }

  const handleDeleteTax = async (id: any, index?: any) => {
    let res: Response = (await deleteTax(id)) as Response
    if (res.status === 200) {
      toast.success('Delete Tax successfully')

      taxValues.filter((item: any) => item.id !== id)

      setTaxFields((prev) => {
        prev.splice(index, 1)
        return [...prev]
      })
      setTaxValues((prev) => {
        prev.splice(index, 1)
        return [...prev]
      })
      setRefresh((prev: any) => !prev)
    }
  }

  useEffect(() => {
    if (allTaxes.length !== 0) {
      setTaxValues(allTaxes)
      setTaxFields(allTaxes?.map(() => ''))
    }
  }, [])

  return (
    <>
      {type === 'hidden' ? (
        <input
          type={type}
          name={name}
          {...(register ? register(name) : {})}
          {...otherProps}
        />
      ) : (
        <>
          {type === 'checkbox' || type === 'radio' || type === 'switch' ? (
            <Form.Group className={containerClass}>
              <CheckInput
                type={type}
                label={label}
                name={name}
                placeholder={placeholder}
                refCallback={refCallback}
                errors={errors}
                register={register}
                comp={comp}
                className={className}
                rows={rows}
                {...otherProps}
              />
            </Form.Group>
          ) : type === 'select' ? (
            <Form.Group className={containerClass}>
              {label ? (
                <>
                  <Form.Label className={labelClassName}>{label}</Form.Label>
                  {action && action}
                </>
              ) : null}
              {startIcon ? (
                <FormInputGroup
                  type={type}
                  startIcon={startIcon}
                  name={name}
                  comp={comp}
                  textClassName={textClassName}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors}
                  register={register}
                  className={className}
                  rows={rows}
                  {...otherProps}
                />
              ) : (
                <SelectInput
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors}
                  register={register}
                  comp={comp}
                  className={className}
                  rows={rows}
                  {...otherProps}
                />
              )}
            </Form.Group>
          ) : type === 'taxes' ? (
            <>
              {taxFields.length !== 0 && (
                <Row>
                  <Col lg={5}>
                    <Form.Label className={labelClassName}>Tax</Form.Label>
                  </Col>
                  <Col lg={5}>
                    <Form.Label className={labelClassName}>
                      Value (%)
                    </Form.Label>
                  </Col>
                </Row>
              )}

              {taxFields?.map((ele, index) => {
                return (
                  <div className={containerClass} id={index.toString()}>
                    <Row>
                      <Col lg={5}>
                        <TextualInput
                          type="text"
                          name={`${
                            taxValues[index]?.id ? 'tax_' : 'newTax_'
                          }${index.toString()}`}
                          placeholder="Enter Tax Name"
                          idValue={
                            taxValues[index]?.id ? taxValues[index]?.id : ''
                          }
                          value={taxValues[index]?.name}
                          disabled={editTime && taxValues[index]?.id}
                          style={
                            editTime && taxValues[index]?.id
                              ? { cursor: 'not-allowed' }
                              : {}
                          }
                          comp={comp}
                          onChange={(e: any) => {
                            setTaxValues((prev) => {
                              const arr = prev
                              // console.log(arr[index])

                              if (arr[index]) {
                                arr[index].name = e.target.value
                              } else {
                                arr[index] = {
                                  name: e.target.value,
                                  percentage: '',
                                  id: '',
                                }
                              }

                              return [...arr]
                            })
                          }}
                        />
                        <TextualInput
                          type="hidden"
                          name={`${
                            taxValues[index]?.id ? 'taxValue_' : 'newTaxValue_'
                          }${index.toString()}`}
                          value={
                            taxValues[index]?.id ? taxValues[index]?.id : ''
                          }
                          comp={comp}
                        />
                      </Col>
                      <Col lg={5}>
                        <TextualInput
                          type="text"
                          name={`${
                            taxValues[index]?.id
                              ? 'percentage_'
                              : 'newPercentage_'
                          }${index.toString()}`}
                          placeholder="Enter Tax (Percentage)"
                          comp={comp}
                          idValue={
                            taxValues[index]?.id ? taxValues[index]?.id : ''
                          }
                          value={taxValues[index]?.percentage}
                          onChange={(e: any) => {
                            setTaxValues((prev) => {
                              const arr = prev

                              if (arr[index]) {
                                arr[index].percentage = e.target.value
                                // console.log('already exist')
                              } else {
                                arr[index] = {
                                  name: '',
                                  id: '',
                                  percentage: e.target.value,
                                }
                              }
                              return [...arr]
                            })
                          }}
                        />
                      </Col>
                      <Col lg={1}>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            if (taxValues[index]?.id) {
                              // console.log('if case')
                              setShowDeleteConf((prev) => !prev)
                              setDeleteTaxId({
                                id: taxValues[index]?.id,
                                index: index,
                              })
                            } else {
                              setTaxFields((prev) => {
                                prev.splice(index, 1)
                                return [...prev]
                              })
                              setTaxValues((prev) => {
                                prev.splice(index, 1)
                                return [...prev]
                              })
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                          </svg>
                        </button>
                      </Col>
                    </Row>
                  </div>
                )
              })}

              <Row className={containerClass}>
                <Col>
                  <Button
                    variant="primary"
                    className="me-1"
                    onClick={addTaxFields}
                  >
                    Add Tax
                  </Button>
                </Col>
                {/* <Col>
                  <Button variant="danger" className="me-1">
                    Remove Tax
                  </Button>
                </Col> */}
              </Row>
            </>
          ) : (
            <Form.Group className={containerClass}>
              {label ? (
                <>
                  <Form.Label className={labelClassName}>{label}</Form.Label>
                  {action && action}
                </>
              ) : null}
              {startIcon ? (
                <FormInputGroup
                  type={type}
                  startIcon={startIcon}
                  name={name}
                  comp={comp}
                  textClassName={textClassName}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors}
                  register={register}
                  className={className}
                  rows={rows}
                  {...otherProps}
                />
              ) : (
                <TextualInput
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors}
                  register={register}
                  comp={comp}
                  className={className}
                  rows={rows}
                  {...otherProps}
                />
              )}
            </Form.Group>
          )}
        </>
      )}
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        bodyText={'Are you sure you want to delete tax?'}
        onClose={(success) => {
          if (success) {
            if (deleteTaxId) {
              handleDeleteTax(deleteTaxId?.id, deleteTaxId?.index)
            }
          }

          setDeleteTaxId({
            id: '',
            index: -1,
          })
        }}
      />
    </>
  )
}

export default FormInput

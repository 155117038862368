import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import moment from 'moment'

const currency = localStorage.getItem('CURRENCY')

const InvoiceTableDesign = (props: any) => {
  const invoice: any = {}
  const isPaid = props?.data?.split('_')[1]
  const orderId = props?.data?.split('_')[0]
  const shopName = props?.data?.split('_')[3]

  const time = moment(
    props?.tableValue[props?.ele][props?.data][0]?.completedAt,
  ).format('hh:mm A DD, MMM YYYY')
  // const time = props?.tableValue[props?.ele][props?.data][0]?.createdAt

  // console.log(props?.tableValue[props?.ele][props?.data])

  const getTotals = (data: any[], key: string) => {
    let total = 0
    // console.log(data, 'adf')
    data.forEach((item) => {
      total += item[key]
    })
    return (+total)?.toFixed(2)
  }

  return (
    <>
      <div className="">
        <div className="d-flex justify-content-between">
          <div className="fs-4 fw-bold" dark-font>
            Order #{orderId}
          </div>
          <div className="fs-4 dark-font fw-bold">
            <div
              style={{
                border: '2px solid black',
                borderRadius: '10%',
                background: isPaid === 'true' ? '#000000' : '',
              }}
            >
              <p
                style={{
                  margin: '0',
                  padding: '4px 12px',
                  color: isPaid === 'true' ? '#FFFFFF' : '',
                }}
              >
                {isPaid === 'true' && (
                  <i
                    className="bi bi-check-circle-fill "
                    style={{ marginRight: '6px' }}
                  />
                )}
                {isPaid === 'true' ? 'Paid' : 'Not Paid'}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div>{shopName}</div>
          <div>{time} </div>
        </div>
        <Table className="text-center   border-dark">
          {
            // @ts-ignore
            <Thead>
              {
                // @ts-ignore
                <Tr className="" style={{ borderBottom: '1px dashed #8e8f90' }}>
                  {props.taxHeader[props.ele][props.data]?.map(
                    (col: any, i: number) => {
                      // console.log(col)
                      return (
                        // @ts-ignore
                        <Th key={`header_${i}`} className="p-1">
                          {col?.header}
                        </Th>
                      )
                    },
                  )}
                </Tr>
              }
            </Thead>
          }
          {
            // @ts-ignore
            <Tbody>
              {props.tableValue[props.ele][props.data]?.map((itemData: any) => {
                // const {
                //   id,
                //   item,
                //   quantity,
                //   price,
                //   SGST = 0,
                //   SGSTValue = 0,
                //   CGST = 0,
                //   CGSTValue = 0,
                //   IGST = 0,
                //   IGSTValue = 0,
                //   GSTWithoutITC = 0,
                //   GSTWithoutITCValue = 0,
                //   Vat = 0,
                //   vatValue = 0,
                //   compensationCess = 0,
                //   compensationCessValue = 0,
                //   serviceTax = 0,
                //   serviceTaxValue = 0,
                //   subTotal,
                // } = itemData
                return (
                  // @ts-ignore
                  <Tr className="border-dark">
                    {/* {
                                // @ts-ignore
                                <Td>{id}</Td>
                              } */}
                    {props.taxHeader[props.ele][props.data]?.map((col: any) => {
                      return (
                        // @ts-ignore
                        <Td className="">{itemData[col?.accessor] || '-'}</Td>
                      )
                    })}
                    {/* {
                                // @ts-ignore
                                <Td>{item}</Td>
                              }
                              {
                                // @ts-ignore
                                <Td>{quantity}</Td>
                              }
                              {
                                // @ts-ignore
                                <Td>{price}</Td>
                              }
                              {
                                // @ts-ignore
                                <Td>{subTotal}</Td>
                              } */}
                  </Tr>
                )
              })}
              <Tr style={{ borderBottom: '1px dashed #8e8f90' }}>
                {props.taxHeader[props.ele][props.data]?.map((col: any) => {
                  // console.log(col)
                  return (
                    // @ts-ignore
                    <Th className=""></Th>
                  )
                })}
              </Tr>
            </Tbody>
          }
        </Table>
        <div className="d-flex justify-content-end text-end mt-3">
          <div style={{ width: '260px' }} className="flex flex-col gap-2">
            <div className="d-flex justify-content-between border-top border-bottom border-dark border-2 fs-4">
              <div className="px-1 my-2 dark-font fw-bold">Total</div>
              <div className="px-1 mr-1 my-2 dark-font fw-bold">
                {currency} &nbsp;
                {getTotals(
                  props.tableValue[props.ele][props.data],
                  'totalAmount',
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </>
  )
}

export default InvoiceTableDesign

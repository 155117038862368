import { useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import Loader from '../../../components/Loader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// types
import { Response } from '../../../constants/types'
import { HotelDetailsType } from '../../../types/HotelDetails'

import { getHotelDetails } from '../../../helpers/api/hotels'
import RestaurantItems from '../../../components/HotelDetails/RestaurantItems'
import SpecialServices from '../../../components/HotelDetails/SpecialServices'
import WifiAndPolicy from '../../../components/HotelDetails/WifiAndPolicy'

export const hotelDetailsSchemaResolver = yupResolver(
  yup.object().shape({
    wifiName: yup.string().required('wifi name is required'),
    wifiPassword: yup.string().required('wifi password is required'),
    policy: yup.string().required('policy is required'),
    restaurantTime: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('name is required'),
          startTime: yup.string().required('start time is required'),
          endTime: yup.string().required('endTime is required'),
        }),
      )
      .required('restaurant Time is required'),
    specialService: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('name is required'),
          startTime: yup.string().required('start time is required'),
          endTime: yup.string().required('endTime is required'),
        }),
      )
      .required('special service is required'),
  }),
)
const HotelDetails = () => {
  const [hotelDetails, setHotelDetails] = useState<HotelDetailsType>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)

  const methods = useForm<HotelDetailsType>({
    defaultValues: useMemo(() => {
      console.log('hotelDetails_prefill', hotelDetails)
      return hotelDetails
    }, [hotelDetails]),
    resolver: hotelDetailsSchemaResolver,
  })
  const { reset } = methods

  const getDetails = async () => {
    setPageLoad(true)
    let res: Response = (await getHotelDetails()) as Response
    const hd = res?.data?.data
    hd.restaurantTime = hd.restaurantTime.map((time: any) => {
      time.idCopy = time.id
      return time
    })
    hd.specialService = hd.specialService.map((time: any) => {
      time.idCopy = time.id
      return time
    })
    setHotelDetails(hd)
    setPageLoad(false)
  }

  useEffect(() => {
    reset(hotelDetails)
  }, [hotelDetails, reset])

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <>
      {pageLoad && <Loader />}
      {hotelDetails && (
        <>
          <div className="d-flex gap-2 py-2 justify-content-between">
            <h3>{`Web App Setup > Hotel Details`}</h3>
          </div>
          {/* <div className="">
            <h3>Wi-fi</h3>
          </div> */}
          <Card className="mt-2">
            <Card.Body>
              <WifiAndPolicy hotelDetails={hotelDetails} />
              <hr />
              <RestaurantItems hotelDetails={hotelDetails} />
              <hr />

              <SpecialServices hotelDetails={hotelDetails} />
            </Card.Body>
          </Card>
        </>
      )}
    </>
  )
}

export default HotelDetails

import * as yup from 'yup'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormInput } from '../../components'
import { Hotelier } from '../../types/Hotelier'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const departmentList = [
  'Restaurant',
  'Housekeeping',
  'Laundary',
  'Extra supplies',
]

export const hotelierSchemaResolver = yupResolver(
  yup.object().shape({
    name: yup.string().required('Please enter name'),
    email: yup
      .string()
      .email('Please enter valid email address')
      .required('Please enter email address'),
    contactNo: yup
      .number()
      .typeError('Please enter valid Contact number')
      .min(1000000000, 'Please enter valid Contact number')
      .max(9999999999, 'Please enter valid Contact number')
      .required('Please Contact number'),
  }),
)

type propsType = {
  onSubmit: any
}

const AddHotelirs = (props: propsType) => {
  const { onSubmit } = props

  const methods = useForm<Hotelier>({
    defaultValues: {},
    resolver: hotelierSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = methods

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <FormInput
            label={'Select Department'}
            type="select"
            name="department"
            placeholder=""
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          >
            {departmentList?.map((dept) => (
              <option value={dept} key={dept}>
                {dept}
              </option>
            ))}
          </FormInput> */}
          <FormInput
            label={'Department'}
            type="string"
            name="department"
            containerClass={'mb-3'}
            key="namdepartmente"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Name'}
            type="string"
            name="name"
            containerClass={'mb-3'}
            key="name"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Email'}
            type="email"
            name="email"
            containerClass={'mb-3'}
            key="email"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'Password'}
            type="password"
            name="password"
            containerClass={'mb-3'}
            key="password"
            register={register}
            errors={errors}
            control={control}
          />
          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Controller
              name="contactNo"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ background: '#dedede' }}
                  >
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Enter contact number"
                    aria-label="mobile"
                    aria-describedby="basic-addon1"
                    name="contactNo"
                    value={value}
                    onChange={onChange}
                    type="string"
                  />
                </InputGroup>
              )}
            />
            {errors && errors['contactNo'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors['contactNo']['message']}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Add Staff
            </Button>
          </div>
        </form>
      </Col>
    </Row>
  )
}

export default AddHotelirs

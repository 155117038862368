import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import DateRange from '../../components/DateRange'
import Loader from '../../components/Loader'
import { Response } from '../../constants/types'
import { ProfileContext } from '../../Context'
import { getHotelAnalytics, getHotelDetails } from '../../helpers/api/hotels'
import Widget from '../widgets/Widget'
import Table from '../../components/Table'
import { APICore } from '../../helpers/api/apiCore'
import { getInvoiceList } from '../../helpers/api/invoice'
import NoDataFound from '../../components/NoDataFound'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getProfile } from '../../helpers/api/profile'
import { getDateTime } from '../../utils/functions'

const Dashboard = () => {
  const { user, getProfileData } = useContext(ProfileContext)
  const history = useHistory()

  const currency = localStorage.getItem('CURRENCY')
  const plan: any = localStorage.getItem('plan')

  const [invoice, setInvoice] = useState<any[]>([])

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [stats, setStats] = useState({
    availableRooms: '0/0',
    guestServed: 0,
    activeGuest: 0,
    paidRevenue: 0,
    pendingRevenue: 0,
    orderConfirmed: 0,
    orderPending: 0,
    orderRejected: 0,
    orderAccepted: 0,
    orderCompleted: 0,
    hoteliers: 0,
    revenuePercent: 0,
    completedRes: 0,
    pendingRes: 0,
    completedHouse: 0,
    pendingHouse: 0,
  })

  const { timeZone } = useContext(ProfileContext)

  // console.log(timeZone, 'in')

  const setAppropriateStats = useCallback((data: any) => {
    // console.log(stats)
    // console.log(data)
    let totalGuest = 0
    data?.guests?.forEach((itm: any) => {
      const count: number = +itm?.guests
      if (itm?.isActive) {
        setStats((prev: any) => ({
          ...prev,
          activeGuest: count,
        }))
        totalGuest += count
      } else {
        totalGuest += count
      }
    })

    setStats((prev: any) => ({
      ...prev,
      guestServed: totalGuest,
    }))

    data?.orders?.forEach((itm: any) => {
      const order = +itm?.order
      switch (itm?.status) {
        case 'Completed':
          setStats((prev: any) => ({
            ...prev,
            orderCompleted: order,
          }))
          break
        case 'Confirmed':
          setStats((prev: any) => ({
            ...prev,
            orderConfirmed: order,
          }))
          break
        case 'Rejected':
          setStats((prev: any) => ({
            ...prev,
            orderRejected: order,
          }))
          break
        case 'Pending':
          setStats((prev: any) => ({
            ...prev,
            orderPending: order,
          }))
          break
        case 'Accepted':
          setStats((prev: any) => ({
            ...prev,
            orderAccepted: order,
          }))
          break
      }
    })

    let totalRooms = 0
    let availableRooms = 0
    data?.room?.forEach((itm: any) => {
      const count: number = +itm?.count
      if (itm?.isAvailable) {
        availableRooms = count
        totalRooms += count
      } else {
        totalRooms += count
      }
    })
    setStats((prev: any) => ({
      ...prev,
      availableRooms: `${availableRooms}/${totalRooms}`,
    }))

    let pendingRevenue = 0
    let paidRevenue = 0
    let completedRes = 0
    let pendingRes = 0
    let completedHouse = 0
    let pendingHouse = 0

    // data?.revenueForHotelierDashboard.forEach((itm: any) => {
    //   const amount = +itm?.amount
    //   switch (itm?.status) {
    //     case 'Pending':
    //       pendingRevenue = amount
    //       break
    //     case 'Paid':
    //       paidRevenue = amount
    //       break
    //   }
    // })
    // const totalRevenue = paidRevenue + pendingRevenue
    // const revenuePercent =
    //   totalRevenue === 0 ? 0 : Math.round((paidRevenue / totalRevenue) * 100)

    data?.restaurantOrders?.forEach((itm: any) => {
      const order = +itm?.order
      switch (itm?.status) {
        case 'Completed':
          completedRes = order
          break
        case 'Pending':
          pendingRes = order
          break
      }
    })

    data?.houseKeepingOrders?.forEach((itm: any) => {
      const order = +itm?.order
      switch (itm?.status) {
        case 'Completed':
          completedHouse = order
          break
        case 'Pending':
          pendingHouse = order
          break
      }
    })

    setStats((prev: any) => ({
      ...prev,
      hoteliers: data?.hoteliers?.count || 0,
      pendingRevenue,
      paidRevenue,
      // revenuePercent,
      completedRes,
      pendingRes,
      completedHouse,
      pendingHouse,
    }))
  }, [])

  // const getDetails = useCallback(
  //   async (params?: any) => {
  //     setPageLoad(true)
  //     if (user.id) {
  //       let res: Response = (await getHotelAnalytics(
  //         user.id,
  //         params,
  //       )) as Response
  //       setAppropriateStats(res?.data?.data)
  //     }
  //     setPageLoad(false)
  //   },
  //   [setAppropriateStats, user.id],
  // )

  const getInvoiceData = async () => {
    setPageLoad((prev: any) => !prev)
    let res: Response = (await getInvoiceList()) as Response
    // console.log('response of get invoice list ---->', res)

    setInvoice(res?.data?.data?.orders)
    setPageLoad((prev: any) => !prev)
  }

  const columns = [
    // {
    //   Header: 'Room ID',
    //   accessor: 'id',
    // },
    // {
    //   Header: 'Invoice number',
    //   accessor: 'invoiceNo',
    // },
    {
      Header: 'Room number',
      accessor: 'roomNo',
    },
    //  {
    //    Header: 'Occupied/Unoccupied',
    //    accessor: 'isAvailable',
    //    Cell: ({ row }: any) => {
    //      const isAvailable: boolean = row.values?.isAvailable
    //      return <>{isAvailable ? 'Unoccupied' : 'Occupied'}</>
    //    },
    //  },
    {
      Header: 'Total Amount',
      accessor: 'amount',
      Cell: ({ value }: { value: any }) => {
        return `${currency} ${value?.toFixed(2)}`
      },
    },
    {
      Header: 'Checkout Time',
      accessor: '',
      Cell: ({ row }: any) => {
        // console.log(row.original?.items[0]?.roomCheckOutDate)
        // const roomCheckOutDate =
        //   (row.original?.items[0]?.roomCheckOutDate &&
        //     moment(row.original?.items[0]?.roomCheckOutDate).format(
        //       'DD, MMM YYYY hh:mm A',
        //     )) ||
        //   '-'
        const roomCheckOutDate =
          (row.original?.items[0]?.roomCheckOutDate &&
            getDateTime(
              row.original?.items[0]?.roomCheckOutDate,
              timeZone,
              'DD, MMM YYYY hh:mm A',
            )) ||
          '-'
        return roomCheckOutDate
      },
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={
                () => history.push(`/invoice/${id}`, { invoice: row.original })
                // console.log(row)
              }
            >
              View
            </Button>
          </div>
        )
      },
    },
    // {
    //   Header: '',
    //   accessor: 'floor',
    //   Cell: <></>,
    // },
  ]

  const roomInvoice: any = []

  // console.log(invoice)

  const restauratnOrders: any = []
  const serviceOrders: any = []
  // console.log(Object.values(invoice))
  // Object.values(invoice)?.forEach((ele) => {
  //   ele?.forEach((data: any) => {
  //     if (data?.restaurantItemId) {
  //       restauratnOrders.push(data)
  //     } else if (data?.houseKeepingItemId) {
  //       serviceOrders?.push(data)
  //     }
  //   })
  // })
  Object.values(invoice)?.forEach((ele) => {
    // roomInvoice?.push(ele)
    let amount = 0
    let roomNo = null
    let invoiceNo = null
    let id = null
    let createdAt = null
    const arr: any = []
    ele?.forEach((data: any) => {
      amount = amount + +data?.itemAmount + +data?.taxAmount

      roomNo = data?.room?.roomNo
      invoiceNo = data?.invoiceNo
      id = data?.parentId
      createdAt = data?.createdAt

      arr?.push(data)

      return {
        roomNo,
        invoiceNo,
        amount,
        id,
        createdAt,
      }
    })

    // arr?.push(ele)
    // console.log(arr, 'arr')
    const checkoutDetails: any = ele[0]?.room?.guestDetail[0]?.checkOutAt

    roomInvoice.push({
      roomNo,
      invoiceNo,
      amount,
      id,
      createdAt,
      items: arr,
      checkoutDetails,
    })
  })
  // console.log(serviceOrders, 'serv')
  // console.log(roomInvoice)

  const data: any = []

  // useEffect(() => {
  //   getDetails()
  //   getProfileData()
  // }, [getDetails, getProfileData])

  useEffect(() => {
    if (plan !== '3' && plan !== '5') {
      getInvoiceData()
    }
  }, [])

  return (
    <>
      {pageLoad && <Loader />}
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="">Invoice</h3>
          </div>
        </Col>
      </Row>
      {/* <DateRange onApply={getDetails} /> */}
      {plan === '3' || plan === '5' ? (
        <div className="fw-bolder">
          For Invoice - Upgrade to Standard Plan with Invoice or Premium Plan
          with Invoice:&nbsp;
          <a href="mailto:office@hotelcom.in">office@hotelcom.in</a>
        </div>
      ) : (
        <div>
          <Card>
            <Card.Body>
              {roomInvoice?.length > 0 ? (
                <Table columns={columns} data={roomInvoice} />
              ) : (
                <NoDataFound />
              )}
              {/* <Table columns={columns} data={roomInvoice} /> */}
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  )
}

export default Dashboard

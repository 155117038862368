import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllPayment(params?: {}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/transactions/hotels/pending/count`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllTransactions(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/transactions/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllSettlements(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/settlements/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllInvoices(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/invoices/guest/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllSubscriptions(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      // let url = `${baseUrl}/invoices/guest/hotels/${id}`
      let url = `${baseUrl}/subscriptions/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllPlans(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/plans`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}



async function getInvoiceById(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/invoices/guest/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getSubscriptionById(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/subscriptions/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function subscribePlanById(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/razorpay/subscribe/plan`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function cancleSubscriptionById(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/razorpay/subscriptions/cancel/${id}`
      let result: Promise<any> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getAllPayment,
  getAllTransactions,
  getAllSettlements,
  getAllInvoices,
  getInvoiceById,
  getAllSubscriptions,
  getAllPlans,
  subscribePlanById,
  getSubscriptionById,
  cancleSubscriptionById,
}

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL
const hotelId = localStorage.getItem('HOTELID')

async function getRestarantList() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS}?hotelId=${hotelId}`

      let result: Promise<Response> = api.get(url, null)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function CreateNewRestarant(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.RESTAURANTS}`
      console.log(body)

      let result: Promise<Response> = api.createWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateRestarant(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurents/id/${id}`
      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteRestarant(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurents/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getRestarantList,
  CreateNewRestarant,
  updateRestarant,
  deleteRestarant,
}

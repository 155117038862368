import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllHoteliers(id: number, params?: {}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addHotelier(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/hotels/add/hotelier`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateHotelier(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/id/${id}`
      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteHotelier(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function acceptRejectHotelier(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/requests`
      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getAllHoteliers,
  addHotelier,
  updateHotelier,
  deleteHotelier,
  acceptRejectHotelier,
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Card, Offcanvas } from 'react-bootstrap'
import Select from 'react-select'
import Table from '../../components/Table'
import AddRoom from './AddRoom'
import EditFloors from './EditFloors'
import { Room } from '../../types/Room'
import {
  addRoom,
  deleteRoom,
  getMyAllRooms,
  getRoomToken,
  updateRoom,
} from '../../helpers/api/rooms'
import { Response } from '../../constants/types'
import Loader from '../../components/Loader'
import DeleteConfirmation from '../../components/DeleteConfirmation'
import { toast } from 'react-toastify'
import { ProfileContext } from '../../Context'
import EditRoom from './EditRoom'
import { updateProfile } from '../../helpers/api/profile'
import NoDataFound from '../../components/NoDataFound'
import { capitalize } from '../../utils/functions'
import { useMediaPredicate } from 'react-media-hook'
import HotelcomLogoLong from '../../assets/images/HotelcomLogoLong.png'
import HotelcomWhiteLogo from '../../assets/images/HotelcomWhiteLogo.png'
import logo from '../../assets/images/logo.svg'
import lense from '../../assets/svgs/lense.svg'

// import QRCode from 'react-qr-code'

import { QRCode } from 'react-qrcode-logo'
import html2canvas from 'html2canvas'

interface FormType {
  [key: string]: string
}

const FORM_TYPE: FormType = {
  EDIT_FLOORS: 'Edit Floors',
  ADD_ROOM: 'Add Room',
  EDIT_ROOM: 'Edit Room',
}

const Rooms = () => {
  const { user, getProfileData } = useContext(ProfileContext)

  const [floors, setFloors] = useState(user.floors)
  const [floorList, setFloorList] =
    useState<{ value: number | string; label: number | string }[]>()
  const [visible, setVisible] = useState(false)
  const [formType, setFormType] = useState<string>('')
  const [roomList, setRoomList] = useState<Room[]>([])
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false)
  const [currentRoom, setCurrentRoom] = useState<Room>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [selectedFloor, setSelectedFloor] = useState<any>(0)
  const [selectedRoom, setSelectedRoom] = useState<any>(0)
  const [selectedQRCode, setSelectedQRCode] = useState<any>(0)

  const [qrCodeVisible, setQrCodeVisible] = useState<boolean>(false)
  const [qrToken, setQrToken] = useState<string>('')
  const canvasRef = useRef<any>()
  const mediQuery = useMediaPredicate(
    '(max-width: 1440px)' && '(max-height: 670px)',
  )
  // console.log(mediQuery)

  console.log(selectedFloor, 'floor')

  const getRoomList = async (floor?: number) => {
    try {
      setPageLoad(true)
      const payload = floor ? { floor } : {}
      let res: Response = (await getMyAllRooms(payload)) as Response
      setRoomList(res?.data?.data)
      getProfileData()
    } catch (error: any) {
      toast.error(capitalize(error.errorMessage))
    } finally {
      setPageLoad(false)
    }
  }

  const toggle = () => {
    setVisible((prev) => !prev)
  }

  const toggleQrCode = () => {
    setQrCodeVisible((prev) => !prev)
  }

  const handleDeleteRoom = async (id: any) => {
    let res: Response = (await deleteRoom(id)) as Response
    if (res.status === 200) {
      toast.success('Delete item successfully')
      setRoomList((prev) => prev.filter((item) => item.id !== id))
      getProfileData()
    }
  }

  const handleEditRoomSubmit = async (formData: Room) => {
    try {
      if (currentRoom) {
        let res: Response = (await updateRoom(
          currentRoom.id,
          formData,
        )) as Response
        if (res.status === 200) {
          toast.success('Edit item successfully')
        } else {
          // console.log(res)
        }
        toggle()
        setCurrentRoom(undefined)
        // setSelectedFloor(0)
        getRoomList(selectedFloor?.value)
        getProfileData()
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const handleAddRoomSubmit = async (formData: Room) => {
    try {
      let res: Response = (await addRoom(formData)) as Response
      if (res.status === 200) {
        toast.success('Room added successfully')
        toggle()
        getRoomList(selectedFloor?.value)
        getProfileData()
        // setSelectedFloor(0)
      } else {
        // console.log(res)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const handleEditFloorSubmit = async (floors: number) => {
    toggle()
    if (user.floors !== floors) {
      let res: Response = (await updateProfile({
        floors,
      })) as Response
      if (res.status === 200) {
        toast.success('Edit floors successfully')
      } else {
        // console.log(res)
      }
      getRoomList(selectedFloor.value)
      getProfileData()
      setFloors(floors)
      // setSelectedFloor(0)
    }
  }

  const handleAddRoomClick = () => {
    toggle()
    setFormType('ADD_ROOM')
  }

  const handleEditFloorsClick = () => {
    toggle()
    setFormType('EDIT_FLOORS')
  }

  const handleEditRoomClick = (curCont: Room) => {
    setCurrentRoom(curCont)
    toggle()
    setFormType('EDIT_ROOM')
  }

  const getRoomTokenApiCall = async (room: Room) => {
    const payload = {
      roomId: room.id,
      hotelId: room.hotelId,
    }
    let res: Response = (await getRoomToken(payload)) as Response
    // console.log('res', res)
    if (res.status === 200) {
      console.log(res?.data?.token)
      setSelectedQRCode(room)
      setQrToken(res?.data?.token)
      setQrCodeVisible(true)
    } else {
      // console.log(res)
    }
  }

  const handleViewQRCode = (curCont: Room) => {
    console.log('data', curCont)
    setSelectedRoom(curCont?.roomNo)
    getRoomTokenApiCall(curCont)
  }

  const downloadImage = (blob: any) => {
    const fakeLink: any = window.document.createElement('a')
    fakeLink.style = 'display:none;'
    fakeLink.download = selectedRoom

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }

  const exportAsImage = async (el: any) => {
    const canvas = await html2canvas(el, { backgroundColor: null })
    const image = canvas.toDataURL('image/png', 1.0)
    downloadImage(image)
  }

  const columns = [
    // {
    //   Header: 'Room ID',
    //   accessor: 'id',
    // },
    {
      Header: 'Room number',
      accessor: 'roomNo',
    },
    {
      Header: 'Occupied/Unoccupied',
      accessor: 'isAvailable',
      Cell: ({ row }: any) => {
        const isAvailable: boolean = row.values?.isAvailable
        return <>{isAvailable ? 'Unoccupied' : 'Occupied'}</>
      },
    },
    {
      Header: 'Number of guests',
      accessor: 'guests',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button onClick={() => handleEditRoomClick(row.original)}>
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
              }}
            >
              Delete
            </Button>

            <Button onClick={() => handleViewQRCode(row.original)}>
              View QR
            </Button>
          </div>
        )
      },
    },
    // {
    //   Header: '',
    //   accessor: 'floor',
    //   Cell: <></>,
    // },
  ]

  useEffect(() => {
    // if (selectedFloor? )
    getRoomList(selectedFloor?.value)
  }, [selectedFloor])

  useEffect(() => {
    const floorArr = []
    floorArr.push({ value: '', label: 'All' })
    for (let i = 0; i < floors; i++) {
      floorArr[i + 1] = { value: i + 1, label: i + 1 }
    }
    setFloorList(floorArr)
    // setFloorList(
    //   Array.from({ length: floors }, (_, i) => ({
    //     value: i + 1,
    //     label: i + 1,
    //   })),
    // )
  }, [floors])

  useEffect(() => {
    setFloors(user.floors)
  }, [user.floors])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>Rooms</h3>
          <div className="d-inline-flex gap-2">
            <Button onClick={handleEditFloorsClick}>Edit floors</Button>
            <Button onClick={handleAddRoomClick}>+ Add room</Button>
          </div>
        </div>
        <div>
          <Select
            placeholder="Select Floor"
            className="react-select react-select-container w-25"
            classNamePrefix="react-select"
            value={selectedFloor}
            onChange={(option) => setSelectedFloor(option)}
            options={floorList}
          ></Select>
        </div>
        <div>
          <Card>
            <Card.Body>
              {roomList?.length > 0 ? (
                <Table columns={columns} data={roomList} />
              ) : (
                <NoDataFound />
              )}
            </Card.Body>
          </Card>
        </div>

        <Offcanvas
          show={visible}
          onHide={toggle}
          placement={'end'}
          style={{ width: '33%' }}
          backdrop="static"
        >
          <Offcanvas.Header closeButton>
            <h5 id="offcanvasTopLabel">{FORM_TYPE[formType]}</h5>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {formType === 'ADD_ROOM' && (
              <AddRoom
                floors={floors}
                onSubmit={handleAddRoomSubmit}
                selectedFloor={selectedFloor?.value}
              />
            )}
            {formType === 'EDIT_FLOORS' && (
              <EditFloors
                currentFloors={floors}
                onSubmit={handleEditFloorSubmit}
              />
            )}
            {formType === 'EDIT_ROOM' && currentRoom && (
              <EditRoom
                currentRoom={currentRoom}
                floors={floors}
                onSubmit={handleEditRoomSubmit}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={qrCodeVisible}
          onHide={toggleQrCode}
          placement={'end'}
          style={{ width: '100%' }}
          backdrop="static"
        >
          <Offcanvas.Header closeButton>
            <h5 id="offcanvasTopLabel">QR CODE</h5>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <button
              className="waves-effect waves-light btn btn-primary"
              style={{
                position: 'absolute',
                right: '45px',
                top: '15px',
              }}
              type="button"
              onClick={() => exportAsImage(canvasRef.current)}
            >
              <i className="bi bi-printer-fill"></i> Print
            </button>

            <div
              style={{
                background: 'black',
                // minHeight: '800px',
                height: '790px',
                width: '180px',
                borderRadius: '200px',
                // display: 'flex',
                gap: '100px',
                // flexDirection: 'column',
                margin: '0px auto',
              }}
              ref={canvasRef}
              id="printableQR"
            >
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={HotelcomWhiteLogo}
                  alt="HotelcomWhiteLogo"
                  width={mediQuery ? 110 : 120}
                  style={{
                    marginTop: '30px',
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '30px',
                    color: 'white',
                    marginTop: '6rem',
                    fontFamily: 'ArialRoundedMTBold',
                  }}
                >
                  Hello
                </div>

                <QRCode
                  value={
                    `${process.env.REACT_APP_QR_CODE_URL}/${qrToken}` || ' '
                  }
                  // value={`https://stag-guest.hotelcom.live/signin-with-url/${qrToken}`}
                  bgColor="transparent"
                  fgColor="#fff"
                  size={150}
                  qrStyle="dots"
                  eyeRadius={100}
                  logoImage={logo}
                  removeQrCodeBehindLogo={true}
                  logoHeight={30}
                  logoWidth={30}
                  logoPadding={10}
                  quietZone={15}
                />

                <div
                  style={{
                    textAlign: 'center',
                    fontSize: mediQuery ? '13px' : '14px',
                    color: 'white',
                    fontFamily: 'ArialRoundedMTBold',
                    marginTop: '-15px',
                    marginBottom: '3rem',
                  }}
                >
                  For Room Service scan
                  <div className="mt-1">
                    <img src={lense} width={150} height={25} />
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    width: '100%',
                    marginTop: mediQuery ? '40px' : '50px',
                    color: 'black',
                    background: '#fff',
                    textAlign: 'center',
                    fontSize: mediQuery ? '15px' : '17px',
                    fontFamily: 'ArialRoundedMTBold',
                    padding: '4px 0px ',
                  }}
                >
                  {user?.name}
                </div>

                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '15px',
                    fontFamily: 'ArialRoundedMTBold',
                    padding: mediQuery ? '5px 0px' : '5px 0px ',
                    marginBottom: '15px',
                    color: 'white',
                  }}
                >
                  {selectedRoom}
                </div>
              </div>

              <div
                style={{
                  marginBottom: '0px',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <img
                  src={HotelcomLogoLong}
                  alt="LogoSVG"
                  width={65}
                  style={{
                    marginRight: '15px',
                    marginTop: '21px',
                    width: '100px',
                  }}
                />
              </div>
            </div>
            {/* <div
              style={{
                border: '2px solid red',

                height: '100vh',
                width: '30vw',
                margin: '0px auto',
              }}
            >
              <div
                style={{
                  background: 'black',
                  height: '100vh',
                  borderRadius: '150px',
                }}
              >
                <div>
                  <img
                    src={HotelcomWhiteLogo}
                    alt="HotelcomWhiteLogo"
                    // width={mediQuery ? 110 : 120}
                    width={175}
                    style={{
                      marginTop: '50px',
                      marginLeft: '34px',
                    }}
                  />
                </div>
              </div>
            </div> */}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            handleDeleteRoom(deleteItemId)
          }
        }}
      />
    </>
  )
}

export default Rooms

import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateCategory } from '../../helpers/api/category'
import { Response } from '../../constants/types'
import { toast } from 'react-toastify'
import Spinners from '../../components/Spinner'
import { Category } from '../../types/Category'

type propsType = {
  onEditCategorySuccess: any
  editCategory: Category
}

interface FormValues {
  name: string
  image?: string | null
}

const EditCategoryNew = (props: propsType) => {
  const { onEditCategorySuccess, editCategory } = props
  const [loading, setLoading] = useState<boolean>(false)

  console.log(editCategory)

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter category name'),
    }),
  )

  const methods = useForm<FormValues>({
    defaultValues: { name: editCategory.name },
    resolver: schemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods

  const handlesubmit = async (values: FormValues) => {
    const image = values.image?.[0]
    if (image) {
      values.image = image
    } else {
      delete values.image
    }
    try {
      setLoading(true)
      let res: Response = (await updateCategory(
        editCategory.id,
        values,
      )) as Response
      reset()
      if (res.status === 200) {
        onEditCategorySuccess(res.data?.data)
        toast.success('Category edited successfully')
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(handlesubmit)}>
      <Form.Group className="mb-2">
        <Form.Label column htmlFor="mobile" className="mt-2">
          Category Name
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="name"
            className="form-control chat-input"
            placeholder="Enter category name"
            register={register}
            key="name"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Category Image
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="file"
            name="image"
            className="form-control chat-input"
            placeholder=""
            register={register}
            key="image"
            errors={errors}
            control={control}
          />
        </Col>
        <div
          style={{
            marginTop: '4px',
            marginLeft: '4px',
            fontWeight: '700',
            fontSize: '12px',
            // marginBottom: '8px',
          }}
        >
          (Image ratio must be 1:1)
        </div>
      </Form.Group>
      {loading ? (
        <Spinners classname="px-3" />
      ) : (
        <Button variant="primary" type="submit" className="mt-3 px-2">
          Edit Category
        </Button>
      )}
    </Form>
  )
}

export default EditCategoryNew

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL
const hotelId = localStorage.getItem('HOTELID')

async function getServiceProvider() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.SERVICE_PROVIDER}?hotelId=${hotelId}`

      let result: Promise<Response> = api.get(url, null)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function CreateServiceProvider(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.SERVICE_PROVIDER}`
      console.log(body)

      let result: Promise<Response> = api.createWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateServiceProvider(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/service-providers/id/${id}`
      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteServiceProvider(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/service-providers/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getServiceProvider,
  CreateServiceProvider,
  updateServiceProvider,
  deleteServiceProvider,
}

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getMyAllHotels(params?: { floor?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/dashboard/get/all`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getHotelDetails(params?: {}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/details`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addHotel(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateHotel(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteHotel(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteHotelProfile() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels/profile/soft`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getHotelById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HOTELS}/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getHotelAnalytics(id: number, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels/analytics/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getMyAllHotels,
  getHotelDetails,
  addHotel,
  updateHotel,
  deleteHotel,
  getHotelById,
  getHotelAnalytics,
  deleteHotelProfile,
}

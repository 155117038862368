import { useEffect, useState } from 'react'
import { Response } from '../../constants/types'
import {
  addHMSConfig,
  getAllHMS,
  getHMSConfig,
  updateHMSConfig,
} from '../../helpers/api/hsm'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { FormInput } from '../../components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Spinners from '../../components/Spinner'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'

type Config = {
  userName: string
  password: string
  secreteKey: string
  secreteAns: string
  hmsId: number
  isActive: any
}

const HmsConfig = () => {
  const [HmsConfig, setHmsConfig] = useState<any>(null)
  const [hmsList, setHmsList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [activeValue, setActiveValue] = useState(true)

  //   console.log(activeValue, 'active value')

  const itemSchemaResolver = yupResolver(
    yup.object().shape({
      hmsId: yup.number().required('Please enter HMS'),
      userName: yup.string().required('Please enter User Name'),
      password: yup.string().required('Please enter Password'),
      secreteKey: yup.string().required('Please enter Secrete Key'),
      secreteAns: yup.string().required('Please enter Secrete Answer'),
      isActive: yup.boolean(),
    }),
  )

  const getConfigData = async () => {
    setLoading(true)
    let res: Response = (await getHMSConfig()) as Response
    setHmsConfig(res?.data?.hmsConfig)
    setActiveValue(res?.data?.hmsConfig?.isActive)
    setLoading(false)
  }

  const getHMS = async () => {
    let res: Response = (await getAllHMS()) as Response
    setHmsList(res?.data?.data)
  }

  const onSubmit = async (formData: Config) => {
    // formData.contactNo = `91${formData.contactNo}`
    // console.log(formData)
    const data = {
      hmsId: formData?.hmsId,
      userName: formData?.userName,
      password: formData?.password,
      secreteKey: formData?.secreteKey,
      secreteAns: formData?.secreteAns,
      isActive: formData?.isActive,
    }
    // console.log(data, 'data')
    try {
      setLoading(true)
      if (HmsConfig) {
        let res: Response = (await updateHMSConfig(
          HmsConfig?.id,
          data,
        )) as Response
        if (res.status === 200) {
          toast.success('HMS Config Edited successfully')
          setRefresh((prev) => !prev)
          getConfigData()
        } else {
          //   console.log(res)
        }
      } else {
        let res: Response = (await addHMSConfig(data)) as Response
        if (res.status === 200) {
          toast.success('HMS Config Added successfully')
          setRefresh((prev) => !prev)
          getConfigData()
        } else {
          //   console.log(res)
        }
      }
    } catch (error) {
      console.log('error updating profile -----', error)
    } finally {
      setLoading(false)
    }
  }
  const methods = useForm<any>({
    defaultValues: {
      userName: HmsConfig?.userName,
      password: HmsConfig?.password,
      secreteKey: HmsConfig?.secreteKey,
      secreteAns: HmsConfig?.secreteAns,
      isActive: HmsConfig?.isActive,
      hmsId: HmsConfig?.hmsId,
    },
    resolver: itemSchemaResolver,
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods

  useEffect(() => {
    const fetchData = async () => {
      await getHMS()
      await getConfigData()
      // Set default values after fetching data
      methods.reset({ ...HmsConfig })
    }
    fetchData()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Card className="p-3 w-100 mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm={6}>
              <FormInput
                label="HMS"
                type="select"
                name="hmsId"
                key="hmsId"
                containerClass={'mb-3'}
                // defaultValue={user?.currency || currency}
                // value={defaultCurrency[0]?.value}
                // readOnly={true}
                {...{ register, errors, control }}
              >
                {/* <option value={-1}>Select Currency</option> */}
                {hmsList?.map((hmes) => (
                  <option
                    id={hmes?.id}
                    value={hmes?.id}
                    key={hmes?.id}
                    selected={HmsConfig?.hmsId === hmes?.id ? true : false}
                  >
                    {hmes?.hms}
                  </option>
                ))}
              </FormInput>

              <FormInput
                label="Password"
                type="password"
                name="password"
                register={register}
                key="password"
                defaultValue={HmsConfig?.password}
                errors={errors}
                control={control}
                containerClass={'mb-3'}
              />
              <FormInput
                label={'Secret Answer'}
                type="text"
                placeholder="Enter Secret Answer"
                containerClass={'mb-3'}
                defaultValue={HmsConfig?.secreteAns}
                {...register('secreteAns')}
                errors={errors}
                {...control}
              />
            </Col>
            <Col sm={6}>
              <FormInput
                label={'User Name'}
                type="text"
                placeholder="Enter User Name"
                containerClass={'mb-3'}
                defaultValue={HmsConfig?.userName}
                errors={errors}
                {...register('userName')}
                {...control}
              />
              <FormInput
                label={'Secret Key'}
                type="text"
                placeholder="Enter Secret Key"
                containerClass={'mb-3'}
                defaultValue={HmsConfig?.secreteKey}
                {...register('secreteKey')}
                errors={errors}
                {...control}
              />
              <FormInput
                label={'Active'}
                type="switch"
                placeholder="Enter IsActive"
                containerClass={'mb-3'}
                checked={activeValue}
                // defaultValue={HmsConfig?.secreteKey}
                onChangeCapture={(e: any) => {
                  // console.log(e.target.checked)
                  setActiveValue(e.target.checked)
                }}
                {...register('isActive')}
                errors={errors}
                {...control}
              />
            </Col>
          </Row>

          <div className="text-md-end mb-0">
            {loading ? (
              <Spinners classname="px-3" />
            ) : (
              <Button variant="primary" className="me-1" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </Card>
    </>
  )
}

export default HmsConfig

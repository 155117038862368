import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Logo from '../../assets/images/logo.png'

interface AccountLayoutProps {
  bottomLinks?: any
  children?: any
}

const AuthLayout = ({ bottomLinks, children }: AccountLayoutProps) => {
  useEffect(() => {
    if (document.body) document.body.classList.add('authentication-bg')

    return () => {
      if (document.body) document.body.classList.remove('authentication-bg')
    }
  }, [])

  return (
    <>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Card>
                <Card.Body className="p-0">
                  <Row className="g-0 h-100">
                    <Col lg={7} className="p-4" style={{ minHeight: '80vh' }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={Logo}
                          alt="hotelcom logo"
                          style={{ width: '33%' }}
                        />
                      </div>
                      {children}
                    </Col>
                    <Col lg={5} className="d-none d-md-inline-block">
                      <div className="auth-page-sidebar">
                        <div className="overlay">
                          <div
                            className="auth-user-testimonial"
                            style={{
                              padding: 0,
                              top: '25px',
                              right: '0',
                              left: '150px',
                              textAlign: 'right',
                            }}
                          >
                            {/* <a href="https://hotelcom.live" target="_blank" rel="noreferrer">
                              <img src={Logo} alt="" className="w-50" />
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* bottom links */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AuthLayout

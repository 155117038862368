import { useEffect, useState } from 'react'
// import { useNavigate } from "react-router-dom";

import { Button, Col, Form, Row, Card } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { toast } from 'react-toastify'

import EmailIcon from '../../../assets/images/email.svg'
import PasswordIcon from '../../../assets/images/password.svg'

import { FormInput } from '../../../components'

import { signup } from '../../../helpers'
import { Link } from 'react-router-dom'

import { Response } from '../../../constants/types'
import PaymentDetails, { GSTRegex } from './PaymentDetails'
import AuthLayout from '../AuthLayout'
import Loader from '../../../components/Loader'
import Select from 'react-select'
import {
  countryList,
  currencyList,
  languageList,
  stateList,
} from '../../../constants/select-options'
import { APICore } from '../../../helpers/api/apiCore'
import config from '../../../config'
import { Key, ReactChild, ReactFragment, ReactPortal } from 'react'
import { ReactI18NextChild } from 'react-i18next'
import { TIME_ZONE_DATA } from '../../../utils/storage'

export interface RegisterData {
  name: string
  email: string
  password: string
  address: string
  city: string
  contactNo: string
  timezone: string | ''
  currency: string
}

interface CurrencyType {
  currency: string
  value: string
  label: string
  symbol: string
  // Include other properties of the currency object
}

const Register = () => {
  const [formType, setFormType] = useState('Sign up')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [termAccepted, setTermAccepted] = useState(false)
  const [status, setStatus] = useState<any>()
  const [allCurrencyList, setAllCurrencyList] = useState<CurrencyType[]>([])

  const api = new APICore()
  const label = ''
  const value = ''
  let param: { label?: string; value?: string } = {}
  let baseUrl = config.API_URL

  async function getAllCurrncyList(params?: {
    label?: string
    value?: string
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${baseUrl}/currencies`
        console.log(params)

        let result: Promise<Response> = api.get(url, params)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    })
  }

  const getCunnencyData = async () => {
    let res: Response = (await getAllCurrncyList({
      label,
      value,
    })) as Response
    if (res?.data?.data?.length > 0) {
      const modifiedCurrencyList = res.data.data.map(
        (currency: CurrencyType) => ({
          ...currency,
          label: currency.currency, // Assign the label field value
          value: currency.currency, // Assign the value field value
        }),
      )
      setAllCurrencyList(modifiedCurrencyList)
      // setAllCurrencyList(res?.data?.data)
    }
  }

  const times: any = [
    {
      label: 'Select a Time Zone',
      value: '',
    },
  ]

  TIME_ZONE_DATA.map((ele: any) => {
    if (
      ele?.description !== 'Australian Eastern Time' &&
      ele?.description !== 'Central Time' &&
      ele?.description !== 'Eastern Time (North America)'
    ) {
      if (ele.offset === 'UTC±00') {
        return times.push({
          label: `${ele?.description} (UTC-00)`,
          value: `${ele?.abbr}-UTC-00`,
        })
      }
      return times.push({
        label: `${ele?.description} (${ele?.offset})`,
        value: `${ele?.abbr}-UTC${ele?.offset?.split('UTC')[1]}`,
      })
    }

    // return times.push(`${ele?.description} (${ele?.offset})`)
  })

  const registerSchemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter hotel name'),
      email: yup.string().required('Please enter Email address').email(),
      password: yup
        .string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.'),
      address: yup.string().required('Please enter address'),
      contactNo: yup
        .string()
        .required('Please enter Contact number')
        .test(
          // 'Mobile Validation',
          'Please enter valid Contact Number',
          (value) => (value ? isValidPhoneNumber(value) : false),
        ),
      currency: yup.string().required('Please select currency.'),
      // language: yup.string().required('Please select language.'),
      country: yup.string().required('Please select country.'),
      state: yup.string().required('Please select state.'),
      city: yup.string().required('Please enter city name'),
      // rooms: yup
      //   .number()
      //   .min(1, 'Room must be atleast 1.')
      //   .required('Please enter No. of rooms.'),
    }),
  )

  const methods = useForm<RegisterData>({
    defaultValues: {},
    resolver: registerSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    trigger,
    formState: { errors },
  } = methods

  const onSubmit = async (formData: RegisterData) => {
    try {
      setIsLoading(true)
      let res: Response = (await signup(formData)) as Response
      console.log(res)
      if (res.status === 200) {
        localStorage.setItem('AUTH_TOKEN', res.data.token)
        localStorage.setItem('HOTELID', res?.data?.hotel?.id)
        localStorage.setItem('CURRENCY', res?.data?.currency?.symbol)
        // localStorage.setItem('CURRENCY', formData?.currency)

        // window.location.replace('/select-plan')
        window.location.replace('/')
      } else {
        console.log(res)
      }
    } catch (error: any) {
      console.log(error)
      // toast.error(capitalize(error?.message || error.errorMessage))
    } finally {
      setIsLoading(false)
    }
  }

  const gotoPropertyPage = async () => {
    if (!termAccepted) {
      toast.error('Please accept term and condition')
      return
    }
    const valid = await trigger(['email', 'password'])
    if (valid) return setFormType('Property details')
  }

  useEffect(() => {
    getCunnencyData()
  }, [])

  return (
    <AuthLayout>
      <div>
        {/* <Navbar bg="light" className="d-flex align-items-center">
          <img src={logo} width={30} height={30} alt="" />
          <div className="m-auto">
            <p className="m-auto fs-3">Get connection</p>
          </div>
        </Navbar> */}
        <Col className="m-auto">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="h3 my-3">{formType}</h3>
          </div>
          {(formType === 'Sign up' || formType === 'Property details') && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {formType === 'Sign up' && (
                <>
                  <div
                    className="authentication-form"
                    style={{ height: 'auto' }}
                  >
                    <FormInput
                      type="email"
                      name="email"
                      label="Email Address"
                      startIcon={<img src={EmailIcon} alt="email" />}
                      key="email"
                      placeholder="testmail@check.com"
                      {...{ register, errors, control }}
                    />
                    <FormInput
                      type="password"
                      name="password"
                      label="Password"
                      startIcon={<img src={PasswordIcon} alt="password" />}
                      placeholder="Enter your Password"
                      {...{ register, errors, control }}
                    ></FormInput>
                    <div>
                      <div className="d-flex gap-2 pb-2 align-items-center">
                        <input
                          type="checkbox"
                          id="terms"
                          checked={termAccepted}
                          onChange={(e) => {
                            setTermAccepted(e.target.checked)
                          }}
                        />
                        <div>
                          Accept
                          <a
                            href="https://hotelcom.live/terms-conditions.html"
                            target="_blank"
                            rel="noreferrer"
                          >
                            terms and condition
                          </a>
                        </div>
                      </div>
                      <div
                        className="mb-0 text-center d-flex flex-column justify-content-end"
                        // style={{ height: "102px" }}
                      >
                        <Button
                          disabled={isLoading}
                          style={{
                            background: 'black',
                            color: 'white',
                            bottom: 20,
                          }}
                          onClick={gotoPropertyPage}
                        >
                          {isLoading ? <Loader /> : 'Sign up'}
                        </Button>
                        <div className="mt-2 d-flex justify-content-center">
                          <p>
                            Already have an account?{' '}
                            <Link to="/auth/Login">Login</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formType === 'Property details' && (
                <Col>
                  <Card>
                    <Card.Body>
                      <h4 className="">Hotel details</h4>
                      <Row>
                        <Form.Group as={Row} className="mb-3">
                          <Col>
                            <FormInput
                              label="Hotel name"
                              type="text"
                              name="name"
                              register={register}
                              key="name"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Col>
                            <FormInput
                              label="Hotel address"
                              type="text"
                              name="address"
                              register={register}
                              key="address"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Col>
                            <FormInput
                              label="Timezone"
                              type="select"
                              name="timezone"
                              key="timezone"
                              containerClass={'mb-3'}
                              // defaultValue={user?.currency || currency}
                              // value={defaultCurrency[0]?.value}
                              // readOnly={true}
                              {...{ register, errors, control }}
                            >
                              {/* <option value={-1}>Select Currency</option> */}
                              {times?.map((time: any) => (
                                <option
                                  id={time?.value}
                                  value={time?.value}
                                  key={time?.value}
                                >
                                  {time?.label}
                                </option>
                              ))}
                            </FormInput>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Col md={6}>
                            <Form.Label htmlFor="contactNo">
                              Hotel contact number
                            </Form.Label>
                            <Controller
                              name="contactNo"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                // @ts-ignore
                                <PhoneInput
                                  international
                                  placeholder="Enter Contact Number"
                                  value={value}
                                  onChange={onChange}
                                  defaultCountry="IN"
                                  id="contactNo"
                                  inputComponent={Form.Control}
                                />
                              )}
                            />
                            {errors && errors['contactNo'] && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {errors['contactNo']['message']}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                          {/* <Col md={6}>
                            <FormInput
                              label="Rooms"
                              type="number"
                              min={1}
                              step={1}
                              name="rooms"
                              placeholder="Enter No. of Room"
                              containerClass="mb-3"
                              {...{ register, errors, control }}
                            />
                          </Col> */}

                          <Col md={6}>
                            <FormInput
                              label="GST No."
                              type="text"
                              name="GST"
                              register={register}
                              key="GST"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                      </Row>
                      <h4 className="">Region</h4>
                      <Row>
                        <Form.Group as={Row} className="mb-3">
                          <Col md={6}>
                            <FormInput
                              label="Country"
                              type="text"
                              name="country"
                              key="country"
                              {...{ register, errors, control }}
                            ></FormInput>
                          </Col>
                          <Col md={6}>
                            <FormInput
                              label="State"
                              type="text"
                              name="state"
                              key="state"
                              {...{ register, errors, control }}
                            ></FormInput>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Col md={6}>
                            <FormInput
                              label="Currency"
                              type="select"
                              name="currency"
                              key="currency"
                              {...{ register, errors, control }}
                            >
                              {allCurrencyList?.map((currency: any) => (
                                <option
                                  value={currency?.value}
                                  key={currency?.value}
                                >
                                  {currency?.label} ({currency?.symbol})
                                </option>
                              ))}
                            </FormInput>
                          </Col>
                          <Col md={6}>
                            <FormInput
                              label="City"
                              type="text"
                              name="city"
                              register={register}
                              key="city"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              <div className="d-flex justify-content-between">
                <a href="https://hotelcom.live/support.html" target="_target">
                  Need support ?
                </a>
                <div className="d-flex gap-2">
                  {formType === 'Property details' && (
                    <>
                      <Button onClick={() => setFormType('Sign up')}>
                        Back
                      </Button>
                      <Button type="submit">
                        {isLoading ? <Loader /> : 'Next'}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}

          {formType === 'Payment details' && (
            <PaymentDetails setFormType={setFormType} />
          )}
        </Col>
      </div>
    </AuthLayout>
  )
}

export default Register

import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { FormInput } from '../../../components'
import { toast } from 'react-toastify'
import { Response } from '../../../constants/types'
import { updateBankDetails } from '../../../helpers/api/auth'
import { capitalize } from '../../../utils/functions'
import { useState } from 'react'
import Loader from '../../../components/Loader'

export interface PaymentDetailsType {
  bancAccNo: string
  confBancAccNo?: string
  bancAccHolderName: string
  bancBranchName: string
  IFSC: string
  GST: string
  PAN: string
}

export const bancAccNoRegex = /^\d{9,18}$/g
export const GSTRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g
export const IFSCRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/g
export const PANRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/g

const PaymentDetails = ({ setFormType }: any) => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const registerSchemaResolver = yupResolver(
    yup.object().shape({
      bancAccNo: yup
        .string()
        .required('please enter bank account number')
        .matches(bancAccNoRegex, 'Enter valid bank account number'),
      confBancAccNo: yup
        .string()
        .required('please confirm bank account number')
        .matches(bancAccNoRegex, 'Enter valid bank account number')
        .oneOf([yup.ref('bancAccNo'), null], 'Account number must match'),
      bancAccHolderName: yup
        .string()
        .required('Please enter bank account holder name'),
      bancBranchName: yup.string().required('Please enter bank branch name'),
      IFSC: yup
        .string()
        .required('Please enter IFSC code')
        .matches(IFSCRegex, 'Enter valid IFSC'),

      GST: yup
        .string()
        .required('Please enter GST')
        .matches(GSTRegex, 'Enter valid GST number'),
      PAN: yup
        .string()
        .required('Please enter pan number')
        .matches(PANRegex, 'Enter valid PAN number'),
    }),
  )

  const methods = useForm<PaymentDetailsType>({
    defaultValues: {},
    resolver: registerSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods

  const onSubmit = async (formData: PaymentDetailsType) => {
    try {
      setIsLoading(true)
      let res: Response = (await updateBankDetails(formData)) as Response
      console.log(res)
      if (res.status === 200) {
        localStorage.setItem('AUTH_TOKEN', res.data.token)
        history.replace('/dashboard')
      } else {
        console.log(res)
      }
    } catch (error: any) {
      toast.error(capitalize(error?.message || error.errorMessage))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body>
            <h4 className="">Bank details</h4>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="simpleinput">Account number</Form.Label>
                  <FormInput
                    type="text"
                    name="bancAccNo"
                    register={register}
                    key="bancAccNo"
                    errors={errors}
                    control={control}
                    onChange={(e) =>
                      setValue(
                        'bancAccNo',
                        e.target.value.replace(/[^0-9\\.]+/g, ''),
                      )
                    }
                  // onChange={(e) => setValue("bancAccNo", e.target.value.replace(bancAccNoRegex, ''))}
                  />
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="simpleinput">
                    Confirm account number
                  </Form.Label>
                  <FormInput
                    type="text"
                    name="confBancAccNo"
                    register={register}
                    key="confBancAccNo"
                    errors={errors}
                    control={control}
                    onChange={(e) =>
                      setValue(
                        'confBancAccNo',
                        e.target.value.replace(/[^0-9\\.]+/g, ''),
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="simpleinput">Branch name</Form.Label>
                  <FormInput
                    type="text"
                    name="bancBranchName"
                    register={register}
                    key="bancBranchName"
                    errors={errors}
                    control={control}
                    onChange={(e) => {
                      const string = e.target.value
                      setValue(
                        'bancBranchName',
                        string.charAt(0).toUpperCase() + string.slice(1),
                      )
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="simpleinput">
                    Account holder name
                  </Form.Label>
                  <FormInput
                    type="text"
                    name="bancAccHolderName"
                    register={register}
                    key="bancAccHolderName"
                    errors={errors}
                    control={control}
                    onChange={(e) => {
                      const string = e.target.value
                      setValue(
                        'bancAccHolderName',
                        string.charAt(0).toUpperCase() + string.slice(1),
                      )
                    }}
                  />
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="simpleinput">IFSC</Form.Label>
                  <FormInput
                    type="text"
                    name="IFSC"
                    register={register}
                    key="IFSC"
                    errors={errors}
                    control={control}
                    onChange={(e) =>
                      setValue('IFSC', e.target.value.toUpperCase())
                    }
                    maxLength={11}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Card>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label htmlFor="simpleinput">GST number</Form.Label>
                      <FormInput
                        type="text"
                        name="GST"
                        register={register}
                        key="GST"
                        errors={errors}
                        control={control}
                        onChange={(e) =>
                          setValue('GST', e.target.value.toUpperCase())
                        }
                        maxLength={15}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label htmlFor="simpleinput">PAN number</Form.Label>
                      <FormInput
                        type="text"
                        name="PAN"
                        register={register}
                        key="PAN"
                        errors={errors}
                        control={control}
                        onChange={(e) =>
                          setValue('PAN', e.target.value.toUpperCase())
                        }
                        maxLength={10}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-between">
          <a href="tel:8128220770">Need support?</a>
          <div className="d-flex gap-2">
            <Button onClick={() => setFormType('Property details')}>
              Back
            </Button>
            <Button type="submit">  {isLoading ? <Loader /> : 'Get connection'}</Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default PaymentDetails

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'

// images
// import SmallOffice from "../../assets/images/smallOfficeDesc.png";
import { FormInput } from '../../../components'
import { Contact } from '../../../types/Item'
import { Controller, useForm } from 'react-hook-form'
import Input from 'react-select/src/components/Input'

type propsType = {
  onSubmit: any
}

export const contactSchemaResolver = yupResolver(
  yup.object().shape({
    department: yup.string().required('Please enter department name'),
    name: yup.string().required('Please enter user name'),
    mobile: yup
      .number()
      .typeError('Please enter valid Contact number')
      .min(1000000000, 'Please enter valid Contact number')
      .max(999999999999999, 'Please enter valid Contact number')
      .required('Please Contact number'),
  }),
)

const AddContact = (props: propsType) => {
  const { onSubmit } = props

  const methods = useForm<Contact>({
    defaultValues: {},
    resolver: contactSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = methods

  console.log('add contact values -----', getValues())

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label={'Department Name'}
            type="string"
            name="department"
            placeholder="Enter Department Name"
            containerClass={'mb-3'}
            key="department"
            register={register}
            errors={errors}
            control={control}
          />
          <FormInput
            label={'User Name'}
            type="string"
            name="name"
            placeholder="Enter User Name"
            containerClass={'mb-3'}
            key="name"
            register={register}
            errors={errors}
            control={control}
          />
          {/* <Form.Group className="mb-3">
            <Form.Label>Contact number</Form.Label>
            <Controller
              name="mobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                // <PhoneInput
                //   international
                //   placeholder="Enter Contact Number"
                //   value={value}
                //   onChange={onChange}
                //   defaultCountry="IN"
                //   id="mobile"
                //   inputComponent={Form.Control}
                // />

                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ background: '#dedede' }}
                  >
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Enter contact number"
                    aria-label="mobile"
                    aria-describedby="basic-addon1"
                    value={value}
                    onChange={onChange}
                    type="string"
                  />
                </InputGroup>
              )}
            />
            {errors && errors['mobile'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors['mobile']['message']}
              </Form.Control.Feedback>
            )}
          </Form.Group> */}
          {/* <FormInput
            label={'Contact number'}
            type="number"
            min={1000000000}
            name="mobile"
            placeholder="Enter Contact Number"
            containerClass={'mb-3'}
            key="mobile"
            register={register}
            errors={errors}
            control={control}
          /> */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="contactNo">Contact Number</Form.Label>
            <Controller
              name="mobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore

                <PhoneInput
                  international
                  placeholder="Enter Contact Number"
                  value={value}
                  onChange={onChange}
                  defaultCountry="IN"
                  id="contactNo"
                  inputComponent={Form.Control}
                />
              )}
            />
            {errors && errors['mobile'] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors['mobile']['message']}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Add Contact
            </Button>
          </div>
        </form>
      </Col>
      {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
    </Row>
  )
}

export default AddContact

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getMyAllRooms(params?: { floor?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ROOMS}/dashboard/get/all`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addRoom(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ROOMS}`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateRoom(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ROOMS}/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteRoom(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ROOMS}/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getRoomById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ROOMS}/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getRoomToken(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.GET_TOKEN_BY_ROOM}`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getMyAllRooms,
  addRoom,
  updateRoom,
  deleteRoom,
  getRoomById,
  getRoomToken,
}

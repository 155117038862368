// import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormInput } from '../../../components'
import Spinners from '../../../components/Spinner'
import { Category } from '../../../types/Category'
import { Item } from '../../../types/Item'

type propsType = {
  categoryList: Category[]
  editItem: Item
  onSubmit: any
  setRefresh: any
  loading: boolean
}

const houseKeepingItemSchemaResolver = yupResolver(
  yup.object().shape({
    categoryId: yup.number().required('Please select category'),
    name: yup.string().required('Please enter name'),
    startTime: yup.string().required('Please enter start time'),
    endTime: yup.string().required('Please enter end time'),
    // price: yup
    //   .number()
    //   .typeError('Price must be a number')
    //   .transform((value) => (isNaN(value) ? undefined : value))
    //   .nullable(),
    time: yup.string().required('Please enter time'),
    image: yup.mixed(),
  }),
)
const currency: string | null = localStorage.getItem('CURRENCY')
const EditHousekeepingItem = (props: propsType) => {
  const { categoryList, editItem, onSubmit, loading, setRefresh } = props
  const {
    categoryId,
    name,
    price,
    time,
    startTime,
    endTime,
    // SGST,
    // CGST,
    // IGST,
    // Vat,
    actualPrice,
    description,
    tax,
    // serviceTax,
    // GSTWithoutITC,
    // compensationCess,
  } = editItem

  // const [showPrice, setShowPrice] = useState(true)
  // console.log(tax)
  const allTaxes = tax?.map((ele) => {
    return {
      name: ele?.tax,
      percentage: ele?.percentage,
      id: ele?.id,
    }
  })

  // console.log(allTaxes, 'all')

  const methods = useForm<Item>({
    defaultValues: {
      categoryId,
      name,
      description,
      actualPrice,
      time,
      startTime,
      endTime,
    },
    resolver: houseKeepingItemSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    // watch,
  } = methods

  // console.log('error houseKeeping edit-----', errors)

  // const setShowPricing = useCallback(
  //   (selectedCategoryId: number | undefined) => {
  //     const selectedCategory = categoryList.find(
  //       // eslint-disable-next-line eqeqeq
  //       (cat) => cat.id == selectedCategoryId,
  //     )
  //     const categoryName =
  //       selectedCategory && selectedCategory.name.toLowerCase()
  //     setShowPrice(
  //       !(categoryName === 'extra supplies' || categoryName === 'laundry'),
  //     )
  //   },
  //   [categoryList],
  // )

  // useEffect(() => {
  //   const subscription = watch(({ categoryId }, { name, type }) => {
  //     if (name === 'categoryId') {
  //       setShowPricing(categoryId)
  //     }
  //   })
  //   return () => subscription.unsubscribe()
  // }, [categoryList, setShowPricing, watch])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Select Category</Form.Label>
          <Form.Select
            {...register('categoryId')}
            name="categoryId"
            id="categoryId"
            isInvalid={errors && errors['categoryId'] ? true : false}
          >
            {categoryList?.map((cat) => (
              <option value={cat?.id} key={cat?.id}>
                {cat?.name}
              </option>
            ))}
          </Form.Select>

          {errors && errors['categoryId'] ? (
            <Form.Control.Feedback type="invalid">
              {errors['categoryId']['message']}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        {/* <FormInput
          label={'Select Category'}
          type="select"
          name="category"
          placeholder=""
          containerClass={'mb-3'}
        >
          {categoryList?.map((cat) => (
            <option value={cat?.name} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </FormInput> */}
        <FormInput
          label={'Item name'}
          type="string"
          name="name"
          placeholder="Enter Item Name"
          containerClass={'mb-3'}
          key="name"
          register={register}
          errors={errors}
          control={control}
        />
        <FormInput
          label={'Description'}
          type="text"
          name="description"
          placeholder="Enter Description"
          key="description"
          containerClass={'mb-3'}
          {...{ register, errors, control }}
        />
        {/* <FormInput
          label="Available"
          type="switch"
          name="isAvailable"
          placeholder="isAvailable"
          key="isAvailable"
          containerClass={'mb-3'}
          register={register}
          errors={errors}
          control={control}
        /> */}
        <FormInput
          label={'Start Time'}
          type="time"
          name="startTime"
          placeholder="AM 09:00"
          containerClass={'mb-3'}
          key="startTime"
          register={register}
          errors={errors}
          control={control}
        />
        <FormInput
          label={'End Time'}
          type="time"
          name="endTime"
          placeholder="PM 08:30"
          containerClass={'mb-3'}
          key="endTime"
          register={register}
          errors={errors}
          control={control}
        />
        {/* {showPrice && ( */}
        <>
          <FormInput
            label={`Price (${currency || 'no data found '})`}
            type="number"
            min={0}
            // step={0.01}
            name="actualPrice"
            placeholder="price"
            containerClass={'mb-3'}
            key="price"
            register={register}
            errors={errors}
            control={control}
          />
        </>
        {/* )} */}
        <FormInput
          label={'Expected Delivery Time'}
          type="text"
          // min={1}
          name="time"
          placeholder="time"
          containerClass={'mb-3'}
          key="time"
          register={register}
          errors={errors}
          control={control}
        />
        <FormInput
          label={'Image'}
          type="file"
          name="image"
          containerClass={'mb-3'}
          accept="image/*"
          key="image"
          register={register}
          errors={errors}
          control={control}
        />
        <div
          style={{
            marginTop: '-20px',
            marginLeft: '4px',
            fontWeight: '700',
            fontSize: '12px',
            marginBottom: '8px',
          }}
        >
          (Image ratio must be 1:1)
        </div>
        <FormInput
          label={'Tax'}
          type="taxes"
          name="taxes"
          editTime={true}
          containerClass={'mb-3'}
          key="taxes"
          allTaxes={allTaxes}
          setRefresh={setRefresh}
          {...{ register, errors, control }}
        />
        <div className="text-md-end mb-0">
          {loading ? (
            <Spinners classname="px-3" />
          ) : (
            <Button variant="primary" className="me-1" type="submit">
              Save
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default EditHousekeepingItem

import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card, Offcanvas, Form } from 'react-bootstrap'
import FeatherIcon from 'feather-icons-react'

import Table from '../../components/Table'
import {
  acceptRejectHotelier,
  addHotelier,
  deleteHotelier,
  getAllHoteliers,
  updateHotelier,
} from '../../helpers/api/hoteliears'
import { Response } from '../../constants/types'
import Loader from '../../components/Loader'
import DeleteConfirmation from '../../components/DeleteConfirmation'
import { toast } from 'react-toastify'
import { ProfileContext } from '../../Context'
import NoDataFound from '../../components/NoDataFound'
import AddHotelirs from './AddHoteliers'

import { Hotelier } from '../../types/Hotelier'
import EditHotelirs from './EditHotelier'

const departmentList = [
  'Restaurant',
  'Housekeeping',
  'Laundary',
  'Extra supplies',
]

const Hoteliers = () => {
  const { user } = useContext(ProfileContext)

  const [visible, setVisible] = useState(false)
  const [hotelierList, setHotelierList] = useState<Hotelier[]>([])
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false)
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [formType, setFormType] = useState('Add Staff')
  const [currentHotelier, setCurrentHotelier] = useState<Hotelier>()
  // const [selectedDepartment, setSelectedDepartment] = useState<string>()

  const getHoteliersList = useCallback(
    async (params?: { department: string }) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllHoteliers(user.id, params)) as Response
        setHotelierList(res?.data?.data)
      } catch (error) {
        console.log('get all hoteliers error-----', error)
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )

  const toggle = () => {
    setVisible((prev) => !prev)
    setFormType('Add Staff')
  }

  const handleDeleteHotelier = async (id: any) => {
    let res: Response = (await deleteHotelier(id)) as Response
    if (res.status === 200) {
      toast.success('Staff member deleted successfully')
      setHotelierList((prev) => prev.filter((item) => item.id !== id))
      console.log('delete api success --- ', id, res)
    }
  }

  const handleAddHotelierSubmit = async (formData: Hotelier) => {
    formData.contactNo = `91${formData.contactNo}`
    let res: Response = (await addHotelier(formData)) as Response
    if (res.status === 200) {
      toast.success('Staff member added successfully')
      toggle()
      getHoteliersList()
    } else {
      console.log(res)
    }
  }

  const handleEditHotelierSubmit = async (formData: Hotelier) => {
    formData.contactNo = `91${formData.contactNo}`
    if (!currentHotelier) return
    let res: Response = (await updateHotelier(
      currentHotelier?.id,
      formData,
    )) as Response
    if (res.status === 200) {
      toast.success('Staff member edited successfully')
      toggle()
      getHoteliersList()
    } else {
      console.log(res)
    }
  }

  const handleAcceptRejectHotelier = async (payload: {
    hotelierId: number
    status: number
  }) => {
    try {
      let res: Response = (await acceptRejectHotelier(payload)) as Response
      if (res.status === 200) {
        // toast.success('Hotelier accepted successfully')
        // toast.success('Hotelier rejected successfully')
        getHoteliersList()
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    }
  }

  const handleEditHotelierClick = (curHot: Hotelier) => {
    setCurrentHotelier(curHot)
    toggle()
    setFormType('Edit Staff')
  }

  const columns = [
    // {
    //   Header: 'ID',
    //   accessor: 'id',
    // },
    {
      Header: 'Deparment',
      accessor: 'department',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Mobile Number',
      accessor: 'contactNo',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.original.id
        const status = row.original?.status
        const hotelierId = row.original?.id
        if (status && status.toLowerCase() === 'pending') {
          return (
            <div className="d-flex justify-content-center gap-2">
              <Button
                onClick={() => {
                  handleAcceptRejectHotelier({ hotelierId, status: 1 })
                }}
              >
                <FeatherIcon icon="check" />
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleAcceptRejectHotelier({ hotelierId, status: 0 })
                }}
              >
                <FeatherIcon icon="x" />
              </Button>
            </div>
          )
        }
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={() => {
                handleEditHotelierClick(row.original)
              }}
            >
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(id)
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (user.id) getHoteliersList()
  }, [getHoteliersList, user.id])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>Staff Details</h3>
          <div className="d-inline-flex gap-2">
            <Button onClick={() => toggle()}>+ Add Staff</Button>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body>
              {hotelierList?.length > 0 ? (
                <Table columns={columns} data={hotelierList} />
              ) : (
                <NoDataFound />
              )}
            </Card.Body>
          </Card>
        </div>

        <Offcanvas
          show={visible}
          onHide={toggle}
          placement={'end'}
          style={{ width: '33%' }}
          backdrop="static"
        >
          <Offcanvas.Header closeButton>
            <h5 id="offcanvasTopLabel">
              {formType}
              {/* {formType === 'Add Hotelier' && 'Add Hotelier'}
              {formType === 'Edit Hotelier' && 'Edit Hotelier'} */}
            </h5>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {formType === 'Add Staff' && (
              <AddHotelirs onSubmit={handleAddHotelierSubmit} />
            )}
            {formType === 'Edit Staff' && currentHotelier && (
              <EditHotelirs
                currentHotelier={currentHotelier}
                onSubmit={handleEditHotelierSubmit}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            handleDeleteHotelier(deleteItemId)
          }
        }}
      />
    </>
  )
}

export default Hoteliers

import { useEffect, useState } from 'react'
import { Button, Card, Form, Offcanvas } from 'react-bootstrap'
import { toast } from 'react-toastify'
import FeatherIcon from 'feather-icons-react'

import Table from '../../components/Table'

import {
  getAllItems,
  deleteItem,
  addItem,
  updateItem,
} from '../../helpers/api/housekeepingItems'

import { deleteCategory, getCategoryList } from '../../helpers/api/category'
import { Category } from '../../types/Category'
import { Response } from '../../constants/types'
import AddCategory from './AddCategory'
import { Item } from '../../types/Item'
import DeleteConfirmation from '../../components/DeleteConfirmation'
import Loader from '../../components/Loader'
import AddHousekeepingItem from './CleaningAndSupplies/AddHousekeepingItem'
import EditHousekeepingItem from './CleaningAndSupplies/EditHousekeepingItem'
import NoDataFound from '../../components/NoDataFound'

import placeholderImage from '../../assets/images/placeholderImage.png'
import EditCategory from './EditCategory'
import EditCategoryNew from './EditCategory'
import AddServiceProvider from './AddServiceProvider'
import { Restarant } from '../../types/Restaurant'
import {
  deleteServiceProvider,
  getServiceProvider,
} from '../../helpers/api/serviceProvider'
import EditServiceProvider from './editServiceProvider'
import AddCleaningCategory from './AddCleaningCategory'

const section = 'houseKeeping'

const FORM_TYPE = {
  ADD_SERVICE_PROVIDER: 'ADD_SERVICE_PROVIDER',
  EDIT_SERVICE_PROVIDER: 'EDIT_SERVICE_PROVIDER',
  ADD_ITEM: 'Add Item',
  EDIT_ITEM: 'Edit Item',
  CATEGORY: 'Category',
}

const CleaningAndSupplies = () => {
  const [visible, setVisible] = useState(false)
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [serviceProviderList, setServiceProviderList] = useState<Restarant[]>(
    [],
  )
  const [itemList, setItemList] = useState<Item[]>([])
  const [editItem, setEditItem] = useState<Item>()
  const [showDeleteConf, setShowDeleteConf] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState<number>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
  const [selectedProviderId, setSelectedProviderId] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteCatId, setDeleteCatId] = useState<number>()
  const [deleteProviderId, setDeleteProviderId] = useState<number>()
  const [deleteMessage, setDeleteMessage] = useState<string>()
  const [editCategory, setEditCategory] = useState<Category>()
  const [editProvider, setEditProvider] = useState<Restarant>()
  const [refresh, setRefresh] = useState<boolean>(false)

  const [formType, setFormType] = useState(FORM_TYPE.ADD_ITEM)

  // console.log(serviceProviderList)

  const toggle = () => {
    setVisible((prev) => !prev)
  }
  const handleAddServiceProviderClick = () => {
    toggle()
    setFormType('Add Service Provider')
  }
  const handleAddServiceClick = () => {
    toggle()
    setFormType(FORM_TYPE.ADD_ITEM)
  }

  const handleAddCategoryClick = () => {
    toggle()
    setFormType(FORM_TYPE.CATEGORY)
  }

  const handleEditProviderClick = () => {
    const editCat = serviceProviderList.find((c) => c.id === selectedProviderId)
    if (editCat) {
      setEditProvider(editCat)
      toggle()
      setFormType('Edit Service Provider')
    }
  }
  const handleEditCategoryClick = () => {
    const editCat = categoryList.find((c) => c.id === selectedCategoryId)
    if (editCat) {
      setEditCategory(editCat)
      toggle()
      setFormType('Edit Category')
    }
  }

  const handleEditItemClick = (values: any) => {
    setEditItem(values)
    toggle()
    setFormType(FORM_TYPE.EDIT_ITEM)
  }

  const handleDeleteItem = async (id: any) => {
    let res: Response = (await deleteItem(id)) as Response
    if (res.status === 200) {
      toast.success('Service deleted successfully')
      setItemList((prev) => prev.filter((item) => item.id !== id))
    }
  }

  const getServiceProviderData = async () => {
    let res: Response = (await getServiceProvider()) as Response
    // console.log('response of get restaurant list ---->', res)

    setServiceProviderList(res?.data?.data?.serviceProviders)
  }
  const getCategoryData = async () => {
    let res: Response = (await getCategoryList({
      section: `${section}&serviceProviderId=${selectedProviderId}`,
    })) as Response
    setCategoryList(res.data.data)
  }

  const hadleProviderSelect = (e: any) => {
    setSelectedProviderId(parseInt(e.target.value))
  }
  const hadleCategorySelect = (e: any) => {
    setSelectedCategoryId(parseInt(e.target.value))
  }

  const getHousekeepingItemList = async (
    categoryId?: number,
    serviceProviderId?: number,
  ) => {
    setPageLoad(true)
    let parameter: any = {}

    if (categoryId !== 0) {
      parameter.categoryId = categoryId
    }
    if (serviceProviderId !== 0) {
      parameter.serviceProviderId = serviceProviderId
    }
    let res: Response = (await getAllItems(parameter)) as Response
    setItemList(res?.data?.data)
    setPageLoad(false)
  }
  const isItemAvailabel = async (checked: any, id: any) => {
    // console.log(e)
    // console.log(id)
    const value: any = checked ? 1 : 0

    console.log(value)
    // payload.append('isAvailable', value)
    const payload = {
      isAvailable: value,
    }

    let res: Response = (await updateItem(id, payload)) as Response
    if (res.status === 200) {
      // toast.success('Edit item successfully')
      setRefresh((prev) => !prev)
    } else {
      console.log(res)
    }
  }

  const columns = [
    // {
    //   Header: 'Item ID',
    //   accessor: 'id',
    // },
    // {
    //   Header: '',
    //   accessor: 'categoryId',
    //   Cell: <></>,
    // },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ({ row }: any) => {
        return (
          <div>
            <img
              src={row.original?.image || placeholderImage}
              alt={row.original.name}
              height={70}
              width={70}
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
        )
      },
    },
    {
      Header: 'Item name',
      accessor: 'name',
    },
    {
      Header: 'Item price',
      accessor: 'actualPrice',
    },
    // {
    //   Header: 'Hotelcom Charge',
    //   accessor: 'actualPrices',
    //   Cell: ({ row }: any) => {
    //     const { price, actualPrice } = row?.original
    //     return price - actualPrice
    //   },
    // },
    {
      Header: 'Time',
      accessor: 'time',
    },
    // {
    //     Header: 'Stock',
    //     accessor: 'stock',
    // },
    {
      Header: 'Available',
      accessor: 'isAvailable',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={row?.original?.isAvailable}
              onChange={(e) => {
                isItemAvailabel(e.target.checked, row.original.id)
                // console.log(row)
              }}
            />
          </div>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: any) => {
        const id = row.values.id
        return (
          <div className="d-flex justify-content-center gap-2">
            <Button onClick={() => handleEditItemClick(row.original)}>
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteItemId(row.original.id)
                setDeleteMessage(
                  `Are you sure to delete ${row.original?.name} ?`,
                )
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const handleAddHousekeepingItem = async (formData: Item, e: any) => {
    formData = {
      ...formData,
      taxes: [],
    }

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= 10000; i += 1) {
      if (
        e.target[`newTax_${i}`]?.value &&
        e.target[`newPercentage_${i}`]?.value
      ) {
        formData.taxes.push({
          tax: e.target[`newTax_${i}`]?.value,
          percentage: e.target[`newPercentage_${i}`]?.value,
        })
        // console.log(e.target[`percentage_${i}`]?.value, 'per')
        // console.log(e.target[`tax_${i}`]?.value, 'tax_')
      } else break
    }
    try {
      setLoading(true)
      const data = {
        ...formData,
        price: formData.actualPrice,
        // isAvailable: formData?.isAvailable ? 1 : 0,
        taxes: JSON.stringify(formData.taxes),
      }
      const image = formData.image?.[0]
      if (image) {
        data.image = image
      } else {
        delete data.image
      }
      let res: Response = (await addItem(data)) as Response
      if (res.status === 200) {
        toggle()
        toast.success('Service added successfully')
        setEditItem(undefined)
        getHousekeepingItemList(selectedCategoryId)
        // setSelectedCategoryId(formData?.categoryId || 0)
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }

  const handleEditHousekeepingItem = async (formData: Item, e: any) => {
    formData = {
      ...formData,
      taxes: [],
      newTaxes: [],
    }

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= 10000; i += 1) {
      if (
        e.target[`newTax_${i}`]?.value &&
        e.target[`newPercentage_${i}`]?.value
      ) {
        formData.newTaxes.push({
          tax: e.target[`newTax_${i}`]?.value,
          percentage: e.target[`newPercentage_${i}`]?.value,
        })
        // console.log(e.target[`percentage_${i}`]?.value, 'per')
        // console.log(e.target[`tax_${i}`]?.value, 'tax_')
      } else if (
        e.target[`tax_${i}`]?.value &&
        e.target[`percentage_${i}`]?.value
      ) {
        formData.taxes.push({
          id: e.target[`taxValue_${i}`]?.value,
          percentage: e.target[`percentage_${i}`]?.value,
        })
        // console.log(e.target[`taxValue_${i}`].value, 'idvale')
      } else break
    }
    try {
      if (editItem && editItem.id) {
        setLoading(true)
        const data = {
          ...formData,
          price: formData.actualPrice,
          // isAvailable: formData?.isAvailable ? 1 : 0,
          taxes: JSON.stringify(formData.taxes),
          newTaxes: JSON.stringify(formData.newTaxes),
        }
        const image = formData.image?.[0]
        if (image) {
          data.image = image
        } else {
          delete data.image
        }
        // console.log(data, 'payloadadjfs')
        let res: Response = (await updateItem(editItem.id, data)) as Response
        if (res.status === 200) {
          toast.success('Service edited successfully')
          toggle()
          getHousekeepingItemList(selectedCategoryId)
          // setSelectedCategoryId(formData?.categoryId || 0)
        } else {
          console.log(res)
        }
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteCategory = async (id: number) => {
    let res: Response = (await deleteCategory(id)) as Response
    if (res.status === 200) {
      toast.success('Category deleted successfully')
      // setSelectedCategoryId(0)
      setCategoryList((prev) => prev.filter((cat) => cat.id !== id))
    }
  }
  const handleDeleteProvider = async (id: number) => {
    let res: Response = (await deleteServiceProvider(id)) as Response
    if (res.status === 200) {
      toast.success('Service Provider deleted successfully')
      // setSelectedCategoryId(0)
      setServiceProviderList((prev) => prev.filter((cat) => cat.id !== id))
    }
  }

  useEffect(() => {
    getServiceProviderData()
  }, [])

  useEffect(() => {
    if (selectedProviderId !== undefined) {
      if (selectedCategoryId !== undefined) {
        getHousekeepingItemList(selectedCategoryId, selectedProviderId)
      } else {
        getHousekeepingItemList(0, selectedProviderId)
      }
    }
  }, [selectedCategoryId, refresh])
  useEffect(() => {
    setSelectedCategoryId(0)
    getCategoryData()
    getHousekeepingItemList(0, selectedProviderId)
  }, [selectedProviderId, refresh])

  // console.log('itemlist ----- ', itemList)
  return (
    <div className="d-flex flex-column gap-2">
      {pageLoad && <Loader />}
      <div className="d-flex gap-2 py-2 justify-content-between">
        <h3>{`Web App Setup > Cleaning & Supplies`}</h3>
        <div className="d-inline-flex gap-2">
          <Button onClick={handleAddServiceProviderClick}>
            + Add Service Provider
          </Button>
          <Button onClick={handleAddCategoryClick}>+ Add Category</Button>
          <Button onClick={handleAddServiceClick}>+ Add Service</Button>
        </div>
      </div>
      {/* <div>
        <h5>
          Hotelcom's commision will be added with all saleable products -
          <a
            href="https://hotelcom.live/pricing.html"
            target="_blank"
            rel="noreferrer"
          >
            For more details click
          </a>
        </h5>
      </div> */}
      <div className="d-flex align-items-center mb-2 gap-2">
        <Form.Select
          className="w-25"
          size="lg"
          value={selectedProviderId}
          onChange={hadleProviderSelect}
          style={{ minWidth: '185px' }}
        >
          <option value={0}>Select Service Provider</option>
          {serviceProviderList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </Form.Select>
        {selectedProviderId ? (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteProviderId(selectedProviderId)
                const deleteServiceProvider = serviceProviderList.find(
                  (c) => c.id === selectedProviderId,
                )
                if (deleteServiceProvider) {
                  setDeleteMessage(
                    `Are you sure to delete the category ${deleteServiceProvider?.name} ?`,
                  )
                }
              }}
            >
              <FeatherIcon icon="trash-2" />
            </Button>
            <Button
              onClick={() => {
                handleEditProviderClick()
              }}
            >
              <FeatherIcon icon="edit" />
            </Button>
          </>
        ) : null}
        <Form.Select
          className="w-25"
          size="lg"
          value={selectedCategoryId}
          onChange={hadleCategorySelect}
          style={{ minWidth: '185px' }}
        >
          <option value={0}>Select Category</option>
          {categoryList?.map((cat) => (
            <option value={cat?.id} key={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </Form.Select>
        {selectedCategoryId ? (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConf(true)
                setDeleteCatId(selectedCategoryId)
                const deleteCategory = categoryList.find(
                  (c) => c.id === selectedCategoryId,
                )
                if (deleteCategory) {
                  setDeleteMessage(
                    `Are you sure to delete the category ${deleteCategory?.name} ?`,
                  )
                }
              }}
            >
              <FeatherIcon icon="trash-2" />
            </Button>
            <Button
              onClick={() => {
                handleEditCategoryClick()
              }}
            >
              <FeatherIcon icon="edit" />
            </Button>
          </>
        ) : null}
      </div>
      <div>
        <Card>
          <Card.Body>
            {itemList?.length > 0 ? (
              <Table columns={columns} data={itemList} pageSize={5} />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
      {/* <div className="d-flex justify-content-end gap-2">
        <Button>Back</Button>
        <Button>{`Save & next`}</Button>
      </div> */}

      <Offcanvas
        show={visible}
        onHide={toggle}
        placement={'end'}
        // style={{ width: "75%" }}
        // style={{width: '50%'}}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">{formType}</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {formType && formType === 'Add Service Provider' && (
            <AddServiceProvider
              section={section}
              onAddServiceProviderSuccess={(newServiceProvider: Restarant) => {
                toggle()
                // getCategoryData()
                getServiceProviderData()
              }}
            />
          )}
          {formType && formType === 'Edit Service Provider' && editProvider && (
            <EditServiceProvider
              editServiceProvider={editProvider}
              onEditServiceProviderSuccess={() => {
                toggle()
                // getRestaurantItemList(selectedRestaurantId)
                getServiceProviderData()
                // setSelectedCategoryId(0)
              }}
            />
          )}
          {formType && formType === FORM_TYPE.ADD_ITEM && (
            <AddHousekeepingItem
              selectedCategoryId={selectedCategoryId}
              selectedProviderId={selectedProviderId}
              setSelectedProviderId={setSelectedProviderId}
              categoryList={categoryList}
              serviceProviderList={serviceProviderList}
              onSubmit={handleAddHousekeepingItem}
              loading={loading}
            />
          )}
          {formType && formType === FORM_TYPE.EDIT_ITEM && editItem && (
            <EditHousekeepingItem
              categoryList={categoryList}
              editItem={editItem}
              onSubmit={handleEditHousekeepingItem}
              loading={loading}
              setRefresh={setRefresh}
            />
          )}

          {formType && formType === FORM_TYPE.CATEGORY && (
            <AddCleaningCategory
              section={section}
              dataList={serviceProviderList}
              selectedId={selectedProviderId}
              onAddCategorySuccess={(newCategory: Category) => {
                toggle()
                getCategoryData()
              }}
            />
          )}

          {formType && formType === 'Edit Category' && editCategory && (
            <EditCategoryNew
              editCategory={editCategory}
              onEditCategorySuccess={() => {
                toggle()
                getHousekeepingItemList(selectedCategoryId)
                getCategoryData()
                // setSelectedCategoryId(0)
              }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        bodyText={deleteMessage}
        onClose={(success) => {
          if (success) {
            if (deleteItemId) {
              handleDeleteItem(deleteItemId)
            } else if (deleteCatId) {
              handleDeleteCategory(selectedCategoryId)
            } else if (deleteProviderId) {
              handleDeleteProvider(deleteProviderId)
            }
          }
          setDeleteCatId(undefined)
          setDeleteProviderId(undefined)
          setDeleteItemId(undefined)
          setDeleteMessage(undefined)
        }}
      />
    </div>
  )
}

export default CleaningAndSupplies

import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import FeatherIcon from 'feather-icons-react'
import moment from 'moment'

import Table from '../../components/Table'
import { Response } from '../../constants/types'
import Loader from '../../components/Loader'
import { ProfileContext } from '../../Context'
import NoDataFound from '../../components/NoDataFound'

import { SettlementType } from '../../types/Settlement'
import {
  cancleSubscriptionById,
  getAllInvoices,
  getAllPlans,
  getAllSubscriptions,
  subscribePlanById,
} from '../../helpers/api/payment'
import DateRange from '../../components/DateRange'
import { capitalize } from '../../utils/functions'
import { log } from 'console'
import plans from '../plans'
import classes from './plans.module.css'
import { APICore } from '../../helpers/api/apiCore'
import config from '../../config'
import classNames from 'classnames'

declare global {
  interface Window {
    Razorpay: any
  }
  interface plan {
    id: any
  }
}

const NewPlans = () => {
  const history = useHistory()
  const { user } = useContext<any>(ProfileContext)

  const [subscriptions, setSubscriptions] = useState<any[]>([])
  const [plans, setPlans] = useState<any[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<any>({})

  const arrangeSubscriptions = (subscriptions_: any) => {
    console.log('initial', subscriptions_)

    const avtivePlan = subscriptions_.filter(
      (plan: any) => plan?.status === 'active',
    )
    const indexOfActivePlan = subscriptions_.findIndex(
      (sub: any) => sub?.status === 'active',
    )
    const nonAvtivePlans = [...subscriptions_]
    nonAvtivePlans.splice(indexOfActivePlan, 1)

    console.log(avtivePlan, indexOfActivePlan, nonAvtivePlans)

    return [...avtivePlan, ...nonAvtivePlans]
  }

  const getSubscriptions = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllSubscriptions(
          user.id,
          params,
        )) as Response
        setSubscriptions(arrangeSubscriptions(res?.data?.data))
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )

  const getPlans = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllPlans(user.id, params)) as Response
        setPlans(res?.data?.data)
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )

  const subscribePlan = useCallback(
    async (id?: any, params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await subscribePlanById({
          plan_id: id,
        })) as Response
        if (res?.data?.status == 200) {
          toast.success(capitalize(res?.data?.message))
        }
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
        getSubscriptions()
        getPlans()
      }
    },
    [user.id],
  )

  const cancleSubscription = useCallback(
    async (id?: any, params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await cancleSubscriptionById(id, {})) as Response
        if (res?.data?.status == 200) {
          toast.success(capitalize(res?.data?.message))
        }
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
        getSubscriptions()
        getPlans()
      }
    },
    [user.id],
  )

  // const columns = [
  //   { Header: 'ID', accessor: 'id' },
  //   // { Header: 'Parent Id', accessor: 'parentId' },
  //   { Header: 'Room No', accessor: 'roomNo' },
  //   // {
  //   //   Header: 'Total Taxes',
  //   //   accessor: 'CGSTTotal',
  //   //   Cell: ({ row }: any) => {
  //   //     const { taxTotal } = row.original
  //   //     // const { CGSTTotal, GSTWithoutITCTotal, IGSTTotal, SGSTTotal, compensationCessTotal, serviceTaxTotal } = row.original
  //   //     // return CGSTTotal + GSTWithoutITCTotal + IGSTTotal + SGSTTotal + compensationCessTotal + serviceTaxTotal
  //   //     return taxTotal
  //   //   },
  //   // },
  //   { Header: 'Total Amount', accessor: 'itemTotal' },
  //   {
  //     Header: 'Date and Time',
  //     accessor: 'createdAt',
  //     Cell: ({ value }: { value: any }) => {
  //       return moment(value).local().format('DD/MM/YYYY hh:mm:ss A')
  //     },
  //   },

  //   {
  //     Header: 'Actions',
  //     accessor: 'view',
  //     Cell: ({ row }: any) => {
  //       const id = row.values.id
  //       return (
  //         <div className="d-flex justify-content-center gap-2">
  //           <Button
  //             onClick={() =>
  //               history.push(`/invoice/${id}`, { invoice: row.original })
  //             }
  //           >
  //             View
  //           </Button>
  //         </div>
  //       )
  //     },
  //   },
  // ]

  const columns = [
    {
      Header: 'Plan Name',
      accessor: 'planName',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Charge/Room',
      accessor: 'chargePrRoom',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }: any) => {
        const createdAt = row.original?.createdAt
        return <div>{moment(createdAt).local().format('MMM DD, yyyy')}</div>
      },
    },
    {
      Header: 'Action',
      accessor: 'edit',
      Cell: ({ row, value }: any) => {
        console.log('value', row?.original)

        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={() => {
                const subscription = row?.original
                history.push(`/plan/${subscription.subscriptionId}`, {
                  subscription,
                })
              }}
            >
              View
            </Button>
            {/* {(row?.original?.status == 'active' ||
              row?.original?.status == 'authenticated') && (
                <Button
                  className="btn-danger"
                  onClick={() => {
                    const subscription = row?.original
                    cancleSubscription(subscription.subscriptionId)
                  }}
                >
                  Cancle
                </Button>
              )} */}
          </div>
        )
      },
    },
  ]

  function loadScript(src: any) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  async function displayRazorpay(planId: number) {
    const res = (await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    )) as any

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const api = new APICore()

    let url = `${config.API_URL}/razorpay/order/subscription`
    let result = await api.create(url, { newPlanId: planId })

    let data = result?.data?.data

    const options = {
      // key: __DEV__ ? 'rzp_test_uGoq5ABJztRAhk' : 'PRODUCTION_KEY',
      // key: "rzp_test_OX9EATEc8ErSIM",
      key: data.RAZORPAY_KEY_ID,
      currency: data.order.currency,
      amount: data.order.amount,
      order_id: data.order.id,
      name: 'Hotelcom',
      description: 'Thank you for nothing. Please give us some money',
      // image: 'http://localhost:1337/logo.svg',
      notes: data.order.notes,

      handler: function (response: any) {
        toast.success('Plan subscribed successfully')
        getSubscriptions()
        console.log('res from handler\n', response)
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: '',
        email: '',
        phone_number: '',
      },
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  useEffect(() => {
    getSubscriptions()
    getPlans()
  }, [getSubscriptions])

  // let activePlans = plans?.filter((plan) => plan?.item?.active)

  // const avtivePlan = subscriptions.filter(plan => plan?.status === 'active');
  // const indexOfActivePlan = subscriptions.findIndex(sub => sub?.status === 'active');

  // console.log('selected plan', selectedPlan)
  // console.log('user', user)

  console.log('activePlans_12', plans)

  return (
    <>
      {pageLoad && <Loader />}
      <Card.Title>
        <div className="d-flex justify-content-between">
          <h3>Plans</h3>

          {/* <Button variant="dark">Export CSV</Button> */}
        </div>
      </Card.Title>
      <Card>
        <Card.Body>
          <div
            className="d-flex flex-row gap-3"
            style={{ overflowX: 'scroll' }}
          >
            {plans?.map((plan: any) => (
              <div
                key={plan?.id}
                className={classes.planCard}
                style={{
                  border:
                    plan?.id == selectedPlan?.id ? '1.5px solid black' : 'none',
                }}
                onClick={() => setSelectedPlan(plan)}
              >
                {/* <p>Get a free trial for 30 days then just!</p> */}
                <h1 className="">₹{(+plan?.priceWithoutTax).toFixed(2)}</h1>
                <p className=" ">{/* Per month */} Per room</p>
                <p className=" ">
                  Billed
                  {/* <span style={{ textTransform: 'capitalize' }}> */}
                  {' ' + plan?.period}
                  {/* </span> */}
                </p>
                {/* <Button onClick={() => subscribePlan(plan?.id)} variant="dark">
                  Subscribe
                </Button> */}
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>

      {+user?.totalRooms > 0 ? (
        <Card className="p-3">
          <Card.Title className="">
            <div className="">
              <h6 className="">
                Number of rooms in {user?.name || 'Your hotel.'}
              </h6>
            </div>
          </Card.Title>
          <Card.Body
            className="border border-secondary rounded-3 p-1"
            style={{
              width: 'fit-content',
              // marginLeft: 'auto'
            }}
          >
            {user?.totalRooms}
          </Card.Body>
          {selectedPlan.id && (
            <>
              <Card.Title className="mt-4">
                <div className="">
                  <h6 className="">Total Amount</h6>
                </div>
              </Card.Title>
              <div className="f-flex">
                <div
                  className="border border-secondary rounded-3 p-1 d-inline"
                  style={{
                    width: 'fit-content',
                    marginRight: '8px',
                  }}
                >
                  {(+user?.totalRooms * +selectedPlan?.pricePrRoom).toFixed(2)}
                </div>
                <span>
                  {`${selectedPlan?.currency}`}{' '}
                  <span>
                    {' '}
                    {`(Inclusive ${(+selectedPlan?.GST).toFixed(2)}% GST)`}
                  </span>
                </span>
              </div>
              <div className=" mt-4">
                <Button
                  // onClick={() => subscribePlan(selectedPlan?.planId)}
                  onClick={() => displayRazorpay(selectedPlan?.id)}

                  variant="dark"
                >
                  Subscribe
                </Button>
              </div>
            </>
          )}
        </Card>
      ) : (
        <>
          <Card className="p-3">
            <Card.Title className="">
              <div className="">
                <h4 className="text-danger text-center">
                  Please add rooms to subscribe a plan.
                </h4>
              </div>
            </Card.Title>
          </Card>
        </>
      )}
    </>
  )
}

export default NewPlans

import * as yup from 'yup'
import { Button, Col, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormInput, VerticalForm } from '../../components'
import { Room } from '../../types/Room'

export const roomSchemaResolver = yupResolver(
  yup.object().shape({
    floor: yup.number().required('Please select floor'),
    roomNo: yup.string().required('Please enter room number'),
  }),
)

type propsType = {
  onSubmit: any
  floors: number
  selectedFloor?: number
}

const AddRoom = (props: propsType) => {
  const { floors, onSubmit, selectedFloor } = props

  return (
    <Row>
      <Col>
        <VerticalForm<Room>
          onSubmit={onSubmit}
          resolver={roomSchemaResolver}
          defaultValues={{ floor: selectedFloor }}
        >
          <FormInput
            label="Select floor"
            type="select"
            name="floor"
            placeholder="select floor"
            containerClass={'mb-3'}
          >
            {Array.from({ length: floors }, (_, i) => (
              <option value={i + 1} key={i + 1}>
                {i + 1}
              </option>
            ))}
            {/* {floorList?.map((floor: {value: number; label: number}) => (
              <option value={floor?.value} key={floor?.value}>
                {floor?.label}
              </option>
            ))} */}
          </FormInput>
          <FormInput
            label={'Room number'}
            type="string"
            name="roomNo"
            placeholder=""
            containerClass={'mb-3'}
          />

          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Add Room
            </Button>
          </div>
        </VerticalForm>
      </Col>
    </Row>
  )
}

export default AddRoom

import {APICore} from './apiCore'
import config from '../../config'
import {Response} from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllItems(params: {categoryId?: number}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.DEVICE_GUIDE}`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addDevice(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.DEVICE_GUIDE}`
      console.log(body)

      let result: Promise<Response> = api.createWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateDevice(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.DEVICE_GUIDE}/id/${id}`

      let result: Promise<Response> = api.updateWithFile(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteItem(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.DEVICE_GUIDE}/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getItemById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.DEVICE_GUIDE}/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {getAllItems, addDevice, updateDevice, deleteItem, getItemById}

import { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import DeleteConfirmation from '../DeleteConfirmation'

type PropsType = {
  update: any
  remove: any
  index: any
  value: any
  control?: any
  name?: string
  onUpdate?: any
  onDelete: any
}

const ServiceItem = (props: PropsType) => {
  const { update, remove, index, value, onUpdate, onDelete } = props

  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false)

  const { register, handleSubmit } = useForm({
    defaultValues: value,
  })

  const handleBlur = () => {
    handleSubmit((data) => update(index, data))
  }

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(async (data) => {
            try {
              setIsLoading(true)
              if (!value?.idCopy) {
                await onUpdate(
                  {
                    endTime: data.endTime,
                    name: data.name,
                    startTime: data.startTime,
                  },
                  undefined,
                )
                return
              }
              await onUpdate(data, value?.idCopy)
            } catch (error) {
              console.log('error-----', error)
            } finally {
              setIsLoading(false)
            }
          })}
        >
          <Row className="pt-2">
            <Col md={3} className="">
              <Form.Control
                type="string"
                placeholder="name"
                {...register(`name`, { required: true })}
                onBlur={handleBlur}
              />
            </Col>
            <div style={{ width: '14rem' }}>
              <Form.Control
                type="time"
                placeholder="start time"
                {...register(`startTime`, { required: true })}
                onBlur={handleBlur}
              />
            </div>
            <div style={{ width: '14rem' }}>
              <Form.Control
                type="time"
                placeholder="end time"
                {...register(`endTime`, { required: true })}
                onBlur={handleBlur}
              />
            </div>

            <Col className="d-flex gap-2">
              <Button type="submit">
                {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setShowDeleteConf(true)
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            remove(index)

            console.log(value?.id)
            console.log('value', value)

            if (typeof value.id == 'string') {
              onDelete(value?.timeId)
              // return toast.success('Time deleted successfully')
            }
          }
        }}
      />
    </>
  )
}

export default ServiceItem

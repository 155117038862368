import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import Register from '../pages/auth/Register'
import Dashboard from '../pages/dashboard'
import Feedback from '../pages/dashboard/Feedback'
import Payment from '../pages/dashboard/Payment'
import Error404 from '../pages/error/Error404'
import Error500 from '../pages/error/Error500'
import CleaningAndSupplies from '../pages/guest/CleaningAndSupplies'
import DeviceGuide from '../pages/guest/DeviceGuide'
import HelpDesk from '../pages/guest/HelpDesk'
import HotelDetails from '../pages/guest/HotelDetails/index'
import Restaurant from '../pages/guest/restaurant/Restaurant'
import Hoteliers from '../pages/Hoteliers'
import ViewInvoice from '../pages/invoices/ViewInvoice'
import ViewPlan from '../pages/NewPlan'
import ViewSelectPlan from '../pages/auth/Register/SelectPlan'
import ViewPublicInvoice from '../pages/invoices/ViewPublicInvoice'
import NewPlans from '../pages/NewPlans'
import Orders from '../pages/Orders'
import Maintenance from '../pages/other/Maintenance'
import plans from '../pages/plans'
import Plans from '../pages/plans/Plans'
import QRCode from '../pages/QRCode'
import Rooms from '../pages/rooms'
import Settlements from '../pages/Settlements'
import ViewSettlement from '../pages/Settlements/ViewSettlement'
import Transactions from '../pages/Transactions'

// components
import PrivateRoute from './PrivateRoute'
import Root from './Root'
import MySubscriptions from '../pages/MySubscriptions'
import NewInvoice from '../pages/NewPlan/NewInvoice'
import InvoiceDetail from '../pages/dashboard/InvoiceDetail'
import HmsConfig from '../pages/Hms'

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
// const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'))
//const Register = React.lazy(() => import("../pages/auth/Register"));
// const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// apps
const Profile = React.lazy(() => import('../pages/profile'))
const Notifications = React.lazy(() => import('../pages/notifications'))
const Report = React.lazy(() => import('../pages/report'))
const Community = React.lazy(() => import('../pages/community'))
const Invoices = React.lazy(() => import('../pages/invoices'))
const User = React.lazy(() => import('../pages/user/User'))
const UserView = React.lazy(() => import('../pages/user/Profile'))
const Team = React.lazy(() => import('../pages/team/Team'))
// - chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat/'))

// -other
const Starter = React.lazy(() => import('../pages/other/Starter'))
//const Profile = React.lazy(() => import('../pages/other/Profile'));
const Activity = React.lazy(() => import('../pages/other/Activity'))
const Invoice = React.lazy(() => import('../pages/other/Invoice'))
const Pricing = React.lazy(() => import('../pages/other/Pricing'))

// uikit
const UIElements = React.lazy(() => import('../pages/uikit'))

// widgets
const Widgets = React.lazy(() => import('../pages/widgets/'))

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'))
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'))
const FormValidation = React.lazy(() => import('../pages/forms/Validation'))
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'))
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'))
const Editors = React.lazy(() => import('../pages/forms/Editors'))

// charts
const Charts = React.lazy(() => import('../pages/charts/'))

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'))
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'))

export interface RoutesProps {
  path: RouteProps['path']
  name?: string
  component?: RouteProps['component']
  route?: any
  exact?: RouteProps['exact']
  icon?: string
  header?: string
  roles?: string[]
  children?: RoutesProps[]
}

// root routes
const rootRoute: RoutesProps = {
  path: '/',
  exact: true,
  component: () => <Root />,
  route: Route,
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: '/dashboard',
  name: 'Dashboard',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: Dashboard,
}

const userRoutes: RoutesProps = {
  path: '/user',
  name: 'User',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'user',
  children: [
    {
      path: '/user',
      name: 'User',
      component: User,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/user/:userId',
      name: 'User View',
      component: UserView,
      route: PrivateRoute,
    },
  ],
}

const teamRoutes: RoutesProps = {
  path: '/team',
  name: 'Team',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'user',
  component: Team,
}

const communityAppRoutes: RoutesProps = {
  path: '/community',
  name: 'Community',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'share-2',
  component: Community,
}

const profileAppRoutes: RoutesProps = {
  path: '/profile',
  name: 'Profile',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'share-2',
  component: Profile,
}

// const invoicesRoutes: RoutesProps = {
//   path: '/invoices',
//   name: 'Invoices',
//   route: PrivateRoute,
//   icon: 'file-text',
//   component: Invoices,
// }

const feedbackRoutes: RoutesProps = {
  path: '/feedback',
  name: 'feedback',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: Feedback,
}
const paymentRoutes: RoutesProps = {
  path: '/dashboard/payment',
  name: 'payment',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: Payment,
}

const hotelDetailsRoutes: RoutesProps = {
  path: '/guest/hotel-details',
  name: 'Hotel details',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: HotelDetails,
}
const restaurantRoutes: RoutesProps = {
  path: '/guest/restaurant',
  name: 'Restaurant',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: Restaurant,
}
const cleaningAndSuppliesRoutes: RoutesProps = {
  path: '/guest/cleaning-and-supplies',
  name: 'Cleaning and Supplies',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: CleaningAndSupplies,
}
const deviceGuideRoutes: RoutesProps = {
  path: '/guest/device-guide',
  name: 'Device guide',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: DeviceGuide,
}
const helpDeskRoutes: RoutesProps = {
  path: '/guest/help-desk',
  name: 'Help Desk',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'package',
  component: HelpDesk,
}

const roomsRoutes: RoutesProps = {
  path: '/rooms',
  name: 'Rooms',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'briefcase',
  component: Rooms,
}
const hmsRoutes: RoutesProps = {
  path: '/hms-configuration',
  name: 'HMS Configuration',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'box',
  component: HmsConfig,
}

const hoteliersRoutes: RoutesProps = {
  path: '/hoteliers',
  name: 'Hoteliers',
  route: PrivateRoute,
  icon: 'user',
  component: Hoteliers,
}

const ordersRoutes: RoutesProps = {
  path: '/orders',
  name: 'Orders',
  route: PrivateRoute,
  icon: 'list',
  component: Orders,
}

// const transactionsRoutes: RoutesProps = {
//   path: '/transactions',
//   name: 'Transactions',
//   route: PrivateRoute,
//   icon: 'dollar-sign',
//   component: Transactions,
// }

// const settlementsRoutes: RoutesProps = {
//   path: '/settlements',
//   name: 'Settlements',
//   route: PrivateRoute,
//   icon: 'sliders',
//   component: Settlements,
// }

const qrCodeRoutes: RoutesProps = {
  path: '/qr-code',
  name: 'QRCode',
  route: PrivateRoute,
  icon: 'printer',
  component: QRCode,
}

const plansRoutes: RoutesProps = {
  path: '/plans',
  name: 'Plan',
  route: PrivateRoute,
  icon: 'credit-card',
  component: NewPlans,
}

const mySubscriptionsRoutes: RoutesProps = {
  path: '/subscriptions',
  name: 'My Subscriptions',
  route: PrivateRoute,
  icon: 'credit-card',
  component: MySubscriptions,
}

const chatAppRoutes: RoutesProps = {
  path: '/apps/chat',
  name: 'Chat',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'message-square',
  component: ChatApp,
}

const notificationRoute: RoutesProps = {
  path: '/notification',
  name: 'Send notification',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'bell',
  component: Notifications,
}

const reportRoute: RoutesProps = {
  path: '/reports',
  name: 'Reports',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'alert-octagon',
  component: Report,
}

const appRoutes = [chatAppRoutes]

// pages
const extrapagesRoutes: RoutesProps = {
  path: '/pages',
  name: 'Pages',
  icon: 'package',
  header: 'Custom',
  children: [
    {
      path: '/pages/starter',
      name: 'Starter',
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: '/pages/profile',
      name: 'Profile',
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: '/pages/activity',
      name: 'Activity',
      component: Activity,
      route: PrivateRoute,
    },
    {
      path: '/pages/invoice',
      name: 'Invoice',
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: '/pages/pricing',
      name: 'Pricing',
      component: Pricing,
      route: PrivateRoute,
    },
  ],
}

// ui
const uiRoutes: RoutesProps = {
  path: '/components',
  name: 'Components',
  icon: 'package',
  header: 'UI Elements',
  children: [
    {
      path: '/components/ui-elements',
      name: 'UIElements',
      component: UIElements,
      route: PrivateRoute,
    },
    {
      path: '/components/widgets',
      name: 'Widgets',
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: '/forms',
      name: 'Forms',
      children: [
        {
          path: '/forms/basic',
          name: 'Basic Elements',
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: '/forms/advanced',
          name: 'Form Advanced',
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: '/forms/validation',
          name: 'Form Validation',
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: '/forms/wizard',
          name: 'Form Wizard',
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: '/forms/upload',
          name: 'File Upload',
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: '/forms/editors',
          name: 'Editors',
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/components/charts',
      name: 'Charts',
      component: Charts,
      route: PrivateRoute,
    },
    {
      path: '/tables',
      name: 'Tables',
      children: [
        {
          path: '/tables/basic',
          name: 'Basic',
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: '/tables/advanced',
          name: 'Advanced',
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

// auth
const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
    route: Route,
  },
  // {
  //   path: "/auth/confirm",
  //   name: "Confirm",
  //   component: Confirm,
  //   route: Route,
  // },
  {
    path: '/auth/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
  },
  // {
  //   path: "/auth/lock-screen",
  //   name: "Lock Screen",
  //   component: LockScreen,
  //   route: Route,
  // },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
]

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    route: Route,
  },
  {
    path: '/error-404',
    name: 'Error - 404',
    component: Error404,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: Error500,
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = []

  routes = routes || []
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

const viewInvoicesRoute: RoutesProps = {
  path: '/invoice/:invoiceId',
  name: 'View Invoice',
  route: PrivateRoute,
  component: ViewInvoice,
}

const viewInvoiceNewRoute: RoutesProps = {
  path: '/print-invoice/subscriptions/:subId',
  name: 'View Subscription',
  route: PrivateRoute,
  component: NewInvoice,
}

const viewSubscriptionDetailsRoute: RoutesProps = {
  path: '/plan/:subId',
  name: 'View Subscription',
  route: PrivateRoute,
  component: ViewPlan,
}

const viewPublicInvoicesRoute: RoutesProps = {
  path: '/invoice/:invoiceId',
  name: 'Invoice Details',
  route: Route,
  component: InvoiceDetail,
}
const viewSettlementRoute: RoutesProps = {
  path: '/settlement/:settlementId',
  name: 'View Settlement',
  route: PrivateRoute,
  component: ViewSettlement,
}

// const viewSelectPlan: RoutesProps = {
//   path: '/select-plan',
//   name: 'View Select Plan',
//   route: PrivateRoute,
//   component: ViewSelectPlan,
// }

// All routes
const authProtectedRoutes = [
  feedbackRoutes,
  paymentRoutes,
  rootRoute,
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
  communityAppRoutes,
  roomsRoutes,
  hoteliersRoutes,
  ordersRoutes,
  hmsRoutes,
  // settlementsRoutes,
  // transactionsRoutes,
  qrCodeRoutes,
  // invoicesRoutes,
  viewInvoicesRoute,
  viewSettlementRoute,
  userRoutes,
  teamRoutes,
  profileAppRoutes,
  notificationRoute,
  reportRoute,
  hotelDetailsRoutes,
  restaurantRoutes,
  cleaningAndSuppliesRoutes,
  deviceGuideRoutes,
  helpDeskRoutes,
  viewInvoiceNewRoute,
  viewSubscriptionDetailsRoute,
  plansRoutes,
  mySubscriptionsRoutes,
  // viewSelectPlan
]
const publicRoutes = [
  ...authRoutes,
  ...otherPublicRoutes,
  viewPublicInvoicesRoute,
]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
}

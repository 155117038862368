import { Button, Col, Row } from 'react-bootstrap'

import { FormInput, VerticalForm } from '../../components'
import { Room } from '../../types/Room'
import { roomSchemaResolver } from './AddRoom'

type propsType = {
  onSubmit: any
  floors: number
  currentRoom: Room
}

const EditRoom = (props: propsType) => {
  const { floors, onSubmit, currentRoom } = props

  const { floor, roomNo } = currentRoom

  return (
    <Row>
      <Col>
        <VerticalForm<Room>
          onSubmit={onSubmit}
          resolver={roomSchemaResolver}
          defaultValues={{ floor, roomNo }}
        >
          <FormInput
            label="Select floor"
            type="select"
            name="floor"
            placeholder="select floor"
            containerClass={'mb-3'}
          >
            {Array.from({ length: floors }, (_, i) => (
              <option value={i + 1} key={i + 1}>
                {i + 1}
              </option>
            ))}
          </FormInput>
          <FormInput
            label={'Room number'}
            type="string"
            name="roomNo"
            placeholder=""
            containerClass={'mb-3'}
          />

          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              Update Room
            </Button>
          </div>
        </VerticalForm>
      </Col>
    </Row>
  )
}

export default EditRoom

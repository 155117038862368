import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Response } from '../../constants/types'
import { toast } from 'react-toastify'
import Spinners from '../../components/Spinner'
import { CreateNewRestarant } from '../../helpers/api/restaurant'
import { CreateServiceProvider } from '../../helpers/api/serviceProvider'
import moment from 'moment'

type propsType = {
  section: string
  onAddServiceProviderSuccess: any
}

interface FormValues {
  name: string
  desciption: string
  address: string
  openTime: string
  closeTime: string
  image?: string | null
}

const AddServiceProvider = (props: propsType) => {
  const { section, onAddServiceProviderSuccess } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<object>({})

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter Service Provider name'),
      desciption: yup.string().required('Please enter desciption name'),
      address: yup.string().required('Please enter address name'),
      image: yup.mixed(),
    }),
  )

  const methods = useForm<FormValues>({ resolver: schemaResolver })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods

  const handlesubmit = async (formData: FormValues) => {
    try {
      setLoading(true)
      const data = {
        ...formData,
        openTime: moment(formData.openTime, 'HH:mm').utc().format('HH:mm:ss'),
        closeTime: moment(formData.closeTime, 'HH:mm').utc().format('HH:mm:ss'),
      }
      const image = formData.image?.[0]
      if (image) {
        data.image = image
      } else {
        delete data.image
      }
      let res: Response = (await CreateServiceProvider(data)) as Response
      reset()
      if (res.status === 200) {
        onAddServiceProviderSuccess(res.data?.data)
        toast.success('Service Provider added successfully')
      }
      // console.log('response of add Service Provider ---->', res)
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(handlesubmit)}>
      <Form.Group className="mb-2">
        <Form.Label column htmlFor="mobile" className="mt-2">
          Name
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="name"
            className="form-control chat-input"
            placeholder="Enter Service Provider name"
            register={register}
            key="name"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Desciption
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="desciption"
            className="form-control chat-input"
            placeholder="Enter Service Provider desciption"
            register={register}
            key="desciption"
            errors={errors}
            control={control}
          />
        </Col>

        <Form.Label column htmlFor="mobile" className="mt-2">
          Address
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="address"
            className="form-control chat-input"
            placeholder="Enter Service Provider address"
            register={register}
            key="address"
            errors={errors}
            control={control}
          />
        </Col>
        <Row>
          <Col>
            <Form.Label column htmlFor="mobile" className="mt-2">
              Open Time
            </Form.Label>
            <FormInput
              type="time"
              name="openTime"
              className="form-control chat-input"
              placeholder="Enter Service Provider openTime"
              register={register}
              key="openTime"
              errors={errors}
              control={control}
            />
          </Col>
          <Col>
            <Form.Label column htmlFor="mobile" className="mt-2">
              Close Time
            </Form.Label>
            <FormInput
              type="time"
              name="closeTime"
              className="form-control chat-input"
              placeholder="Enter Service Provider closeTime"
              register={register}
              key="closeTime"
              errors={errors}
              control={control}
            />
          </Col>
        </Row>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Invoice Details
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="text"
            name="invoiceDetails"
            className="form-control chat-input"
            placeholder="Enter Service Provider details"
            register={register}
            key="invoiceDetails"
            errors={errors}
            control={control}
          />
        </Col>
        <Form.Label column htmlFor="mobile" className="mt-2">
          Image
        </Form.Label>
        <Col lg={10}>
          <FormInput
            type="file"
            name="image"
            className="form-control chat-input"
            placeholder="Enter Service Provider image"
            register={register}
            key="image"
            errors={errors}
            control={control}
          />
          <div
            style={{
              marginTop: '4px',
              marginLeft: '4px',
              fontWeight: '700',
              fontSize: '12px',
              // marginBottom: '8px',
            }}
          >
            (Image ratio must be 3:1)
          </div>
        </Col>
      </Form.Group>
      {loading ? (
        <Spinners classname="px-3" />
      ) : (
        <Button variant="primary" type="submit" className="mt-3 px-2">
          Add Service Provider
        </Button>
      )}
    </Form>
  )
}

export default AddServiceProvider

import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

const hotelId = localStorage.getItem('HOTELID')

// get invoice list
async function getInvoiceList() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/orders/invoices?hotelId=${hotelId}`
      let result: Promise<Response> = api.get(url, '')
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getInvoicesOfSubscription(id: string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/razorpay/subscriptions/invoices/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteInvoice(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.GET_INVOICE}/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getInvoice(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.GET_INVOICE}/${id}`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getInvoiceList, deleteInvoice, getInvoice, getInvoicesOfSubscription }

import { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'

const DateRange = (props: any) => {
  const { onApply } = props
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleClear = () => {
    setStartDate('')
    setEndDate('')
    onApply()
  }

  const handleApply = () => {
    onApply({ startDate, endDate })
  }

  return (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title>Date Range</Card.Title>
        <div className="d-flex gap-4">
          <div className="w-100">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="startDate">Start Date</Form.Label>
              <Form.Control
                id="startDate"
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
              />
            </Form.Group>
          </div>
          <div className="w-100">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="endDate">End Date</Form.Label>
              <Form.Control
                id="endDate"
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value)
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end">
          <Button onClick={handleApply}>Apply</Button>
          <Button onClick={handleClear}>Clear</Button>
        </div>
      </Card.Body>
    </Card>
  )
}

export default DateRange

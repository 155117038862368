import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import FeatherIcon from 'feather-icons-react'
import moment from 'moment'

import Table from '../../components/Table'
import { Response } from '../../constants/types'
import Loader from '../../components/Loader'
import { ProfileContext } from '../../Context'
import NoDataFound from '../../components/NoDataFound'

import { SettlementType } from '../../types/Settlement'
import {
  cancleSubscriptionById,
  getAllInvoices,
  getAllPlans,
  getAllSubscriptions,
  subscribePlanById,
} from '../../helpers/api/payment'
import DateRange from '../../components/DateRange'
import { capitalize } from '../../utils/functions'
import { log } from 'console'
import plans from '../plans'
import classes from './plans.module.css'
import { APICore } from '../../helpers/api/apiCore'
import config from '../../config'
import classNames from 'classnames'

declare global {
  interface Window {
    Razorpay: any
  }
}

const MySubscriptions = () => {
  const history = useHistory()
  const { user } = useContext(ProfileContext)

  const [subscriptions, setSubscriptions] = useState<any[]>([])
  const [plans, setPlans] = useState<any[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<object>({})

  // const arrangeSubscriptions = (subscriptions_: any) => {
  //   console.log('initial', subscriptions_)

  //   const avtivePlan = subscriptions_.filter(
  //     (plan: any) => plan?.status === 'active',
  //   )
  //   const indexOfActivePlan = subscriptions_.findIndex(
  //     (sub: any) => sub?.status === 'active',
  //   )
  //   const nonAvtivePlans = [...subscriptions_]
  //   if (indexOfActivePlan != -1) {
  //     nonAvtivePlans.splice(indexOfActivePlan, 1)
  //   }

  //   console.log(avtivePlan, indexOfActivePlan, nonAvtivePlans)

  //   return [...avtivePlan, ...nonAvtivePlans]
  // }

  const getSubscriptions = useCallback(
    async (params?: any) => {
      try {
        setPageLoad(true)
        let res: Response = (await getAllSubscriptions(
          user.id,
          params,
        )) as Response
        // setSubscriptions(arrangeSubscriptions(res?.data?.data))
        setSubscriptions(res?.data?.data)
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [user.id],
  )

  const columns = [
    {
      Header: 'Plan Id',
      accessor: 'newPlanId',
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status',
    // },
    // {
    //   Header: 'Charge/Room',
    //   accessor: 'chargePrRoom',
    // },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Subscribed At',
      accessor: 'createdAt',
      Cell: ({ row }: any) => {
        const createdAt = row.original?.createdAt
        return <div>{moment(createdAt).local().format('MMM DD, yyyy')}</div>
      },
    },
    {
      Header: 'Expires At',
      accessor: 'expiryDate',
      Cell: ({ row }: any) => {
        const expiryDate = row.original?.expiryDate
        return <div>{moment(expiryDate).local().format('MMM DD, yyyy')}</div>
      },
    },
    {
      Header: 'Action',
      accessor: 'edit',
      Cell: ({ row, value }: any) => {
        console.log('value', row?.original)

        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={() => {
                const subscription = row?.original
                history.push(`/print-invoice/subscriptions/${subscription.id}`, {
                  subscription,
                })
              }}
            >
              View
            </Button>
            {/* {(row?.original?.status == 'active' ||
              row?.original?.status == 'authenticated') && (
                <Button
                  className="btn-danger"
                  onClick={() => {
                    const subscription = row?.original
                    cancleSubscription(subscription.subscriptionId)
                  }}
                >
                  Cancle
                </Button>
              )} */}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    getSubscriptions()
  }, [getSubscriptions])

  return (
    <>
      {pageLoad && <Loader />}

      <DateRange onApply={getSubscriptions} />

      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>My Subscriptions</h3>
        </div>
        <div>
          <Card>
            <Card.Body>
              {subscriptions.length > 0 ? (
                <Table
                  columns={columns}
                  data={subscriptions}
                  pagination={true}
                />
              ) : (
                <NoDataFound />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default MySubscriptions

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Row } from 'react-bootstrap'
import * as yup from 'yup'

// images
// import SmallOffice from "../../assets/images/smallOfficeDesc.png";

import { FormInput, VerticalForm } from '../../../components'
import Loader from '../../../components/Loader'

// types
import { Category } from '../../../types/Category'
import { DeviceItem } from '../../../types/Item'

export const deviceItemSchemaResolver = yupResolver(
  yup.object().shape({
    video: yup
      .mixed()
      .required('video is required')
      .test('required', 'You need to provide a video', (file) => {
        if (file) return file.length
        return false
      }),
    description: yup.string().required('Please enter device description'),
    categoryId: yup.number().required('Please select device'),
  }),
)

type propsType = {
  categoryList: Category[]
  onSubmit: any
  isLoading: Boolean
}

const AddDevice = (props: propsType) => {
  const { categoryList, onSubmit, isLoading } = props

  return (
    <Row>
      <Col>
        <VerticalForm<DeviceItem>
          onSubmit={onSubmit}
          resolver={deviceItemSchemaResolver}
          // defaultValues={{ username: 'test' }}
        >
          <FormInput
            label={'Select Device'}
            type="select"
            name="categoryId"
            placeholder=""
            containerClass={'mb-3'}
          >
            {categoryList?.map((cat) => (
              <option value={cat?.id} key={cat?.id}>
                {cat?.name}
              </option>
            ))}
          </FormInput>
          <FormInput
            label={'Guide Description'}
            type="string"
            name="description"
            placeholder="Enter guide description"
            containerClass={'mb-3'}
          />
          <FormInput
            label={'Video'}
            type="file"
            name="video"
            containerClass={'mb-3'}
            accept="video/*"
          />
          {/* <div>
            <p>
              <b>Upload video</b>
            </p>
            <p>video should be less than 350MB</p>
            <div>
              <div className="">
                <input
                  type="file"
                  className="form-control d-none"
                  id="video"
                  name="video"
                />
                <label htmlFor="video">
                  <div
                    style={{
                      border: '1px solid gray',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    <i className="bi bi-upload" style={{fontSize: '20px'}}></i>
                  </div>
                </label>
              </div>
            </div>
          </div> */}

          <div className="text-md-end mb-0">
            <Button variant="primary" className="me-1" type="submit">
              {isLoading ? <Loader /> : 'Add Guide'}
            </Button>
          </div>
        </VerticalForm>
      </Col>
      {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
    </Row>
  )
}

export default AddDevice
